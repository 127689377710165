import React, { Component } from 'react';
import { Card, Accordion, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import linea from "../images/linea.png";
import Walletmodal from "./Walletmodal";
import { getAccount, getChainId } from '../hooks/useAccount'
import isEmpty from 'is-empty';
import { getProxyOwner } from '../hooks/useContract';
import { UseProvider } from '../hooks/useWeb3'
import homeicon from "../images/home.png"
import kycicon from "../images/kyc.png"
import launchpadicon from "../images/launchpad_sidebar.png"
import lockedicon from "../images/locked.png"
import privateicon from "../images/private.png"
import docsicon from "../images/docs.png"
import twittericon from "../images/twitter.png"
import supporticon from "../images/support.png"
import telegramicon from "../images/telegram.png"
import bar_img_white from "../images/bar_img_white.png"
import exchange from "../images/exchange.png"
import { NavLink } from "react-router-dom";
import { getFrontsettinghook } from '../hooks/usebackend';

class Sidebar extends Component {

    darkTheme() {
        document.body.classList.remove('light_theme');
        document.body.classList.add('dark_theme');
        document.getElementById("sun_icon").classList.remove('active');
        document.getElementById("moon_icon").classList.add('active');
        localStorage.setItem("theme", 'dark_theme');
    }


    lightTheme() {
        document.body.classList.remove('dark_theme');
        document.body.classList.add('light_theme');
        document.getElementById("moon_icon").classList.remove('active');
        document.getElementById("sun_icon").classList.add('active');
        localStorage.setItem("theme", 'light_theme');
    }


    constructor(props) {
        super(props);
        console.log("props", this.props)
        this.state = {
            location: false,
            walletModal: false,
            accountInfo: "",
            walletConnect: "",
            proxyOwner: "",
            currentChain: 0,
            settingsModal: false,
            sidebarShrink: false,
            setActive: 0,
            setting: [],
            telegram: '',
            Skype: '',
            twitter: '',
            discord: '',
            Docs: '',
            dex: '',
            support: '',
            medium: ""

        }
    }

    setSidebarWidth() {
        this.setState({ sidebarShrink: !this.state.sidebarShrink });
        if (this.state.sidebarShrink) {
            document.body.classList.remove('sidebar_shr');
        }
        else {
            document.body.classList.add('sidebar_shr');

        }
    }

    componentDidMount() {
        document.body.classList.remove('sidebar_shr');
        console.log("history", this.props)
        this.setState({ accountInfo: getAccount() });
        this.setState({ currentChain: getChainId() });
        this.getOwner();
        this.getsetting();
    }

    async getsetting() {
        let data = await getFrontsettinghook();
        console.log("data setting", data, data?.data?.data);
        console.log("Array", typeof data?.data?.data == 'Array', typeof data?.data?.data);
        this.setState({ setting: data?.data?.data })
        typeof data?.data?.data == 'object' && data?.data?.data?.length > 0 && data?.data?.data?.map((val, i) => {
            console.log("val", val)
            if (val.settingname == "Docs" || val.settingname == "docs") {
                this.setState({ Docs: val.settingvalue });
            }
            if (val.settingname == "Twitter" || val.settingname == "twitter") {
                this.setState({ twitter: val.settingvalue });

            }
            if (val.settingname == "Telegram" || val.settingname == "telegram") {
                this.setState({ telegram: val.settingvalue });
            }
            if (val.settingname == "Support" || val.settingname == "support") {
                this.setState({ support: val.settingvalue });
            }
            if (val.settingname == "Dex" || val.settingname == "dex") {
                this.setState({ dex: val.settingvalue });
            }
            if (val.settingname == "Medium" || val.settingname == "medium") {
                this.setState({ medium: val.settingvalue });
            }

        })
    }

    onDismiss() {
        this.setState({ walletModal: false });
    }

    getOwner = async () => {
        const owner = await getProxyOwner();
        this.setState({ proxyOwner: owner });

    }

    logOut = async () => {
        this.setState({ accountInfo: "" })
        localStorage.removeItem("accountInfo")
        if (localStorage.getItem("walletconnect") != null) {
            const provider = await UseProvider();
            await provider.disconnect()
        }
        window.location.reload()
        console.log("logout")
    }

    kycWallet = async () => {
        if (!isEmpty(sessionStorage.getItem("accountInfo"))) {
            this.props.history.push("/kyc")
        }
        else {
            this.setState({ walletModal: true });
        }
    }


    render() {
        const { walletModal, sidebarShrink } = this.state

        return (

            <>
                <button className="get-started-btn rounded_btn_wal shrink_side_btn d-none d-lg-block" onClick={() => this.setSidebarWidth()}>
                    <img src={bar_img_white} className='img-fluid' />
                </button>
                <div id="sidebar" className={sidebarShrink ? "side_shrink" : ""}>

                    <ul className='sidebar_ul'>
                        <NavLink to="/home" className="parent_1_grad" id="home_grad_aprent">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={homeicon} className="icon_sidebar" />
                                <span>
                                    Dashboard
                                </span>

                            </li>
                        </NavLink>
                        <li className='px-0 py-0 li_bot_less'>
                            <Accordion>

                                <Card>
                                    <Card.Header className='lis_paren_side_accordion px-0 py-3'>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0" className='w-100 pl-0'>
                                            <div className='d-flex align-items-center justify-content-between pl-0'>
                                                <div className='icon_text_div pl-2' id="launchpad_parent">
                                                    <img src={launchpadicon} className="icon_sidebar" />
                                                    <span>
                                                        Launchpad
                                                    </span>
                                                </div>
                                                <button className='btn_white_outline_round'>
                                                    <i class="fa fa fa-angle-down" aria-hidden="true"></i>
                                                </button>
                                            </div>

                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='py-0 inner_card_body_acc'>
                                            <ul className='sidebar_inner_ul pl-3'>
                                                <NavLink to="/create" onClick={() => { this.setState({ setActive: 1 }) }}>
                                                    <li className='li_bot_less'>
                                                        Create Launchpad
                                                    </li>
                                                </NavLink>
                                                <NavLink to="/createfairlaunch">
                                                    <li className='li_bot_less'>

                                                        Create Fair Launch

                                                    </li>
                                                </NavLink>
                                                <NavLink to="/launchpadlist">
                                                    <li className='li_bot_less'>

                                                        Launchpad List

                                                    </li>
                                                </NavLink>
                                                <NavLink to="/createtoken">
                                                    <li className='li_bot_less'>

                                                        Token Creation

                                                    </li>
                                                </NavLink>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>


                                <Card>
                                    <Card.Header className='lis_paren_side_accordion px-0 py-3'>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1" className='w-100 pl-0'>
                                            <div className='d-flex align-items-center justify-content-between pl-0'>
                                                <div className='icon_text_div pl-2' id="privatesale_parent">
                                                    <img src={privateicon} className="icon_sidebar" />
                                                    <span>
                                                        Private Sale
                                                    </span>
                                                </div>
                                                <button className='btn_white_outline_round'>
                                                    <i class="fa fa fa-angle-down" aria-hidden="true"></i>
                                                </button>
                                            </div>

                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='py-0 inner_card_body_acc'>
                                            <ul className='sidebar_inner_ul pl-3'>
                                                <NavLink to="/createprivatesale">
                                                    <li className='li_bot_less'>
                                                        Create Private Sale
                                                    </li>
                                                </NavLink>
                                                <NavLink to="/privatesalelist">
                                                    <li className='li_bot_less'>

                                                        Private Sale List

                                                    </li>
                                                </NavLink>

                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header className='lis_paren_side_accordion px-0 py-3'>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2" className='w-100 pl-0'>
                                            <div className='d-flex align-items-center justify-content-between pl-0'>
                                                <div className='icon_text_div pl-2' id="lock_parent">
                                                    <img src={lockedicon} className="icon_sidebar" />
                                                    <span>
                                                        Token Lock
                                                    </span>
                                                </div>
                                                <button className='btn_white_outline_round'>
                                                    <i class="fa fa fa-angle-down" aria-hidden="true"></i>
                                                </button>
                                            </div>

                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className='py-0 inner_card_body_acc'>
                                            <ul className='sidebar_inner_ul pl-3'>
                                                <NavLink to="/createlock">
                                                    <li className='li_bot_less'>
                                                        Create Lock
                                                    </li>
                                                </NavLink>
                                                <NavLink to="/createlplock">
                                                    <li className='li_bot_less'>
                                                        LP Token Lock
                                                    </li>
                                                </NavLink>
                                                <NavLink to="/tokenlock">
                                                    <li className='li_bot_less'>
                                                        Token Lock
                                                    </li>
                                                </NavLink>




                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>

                                </Card>

                            </Accordion>
                        </li>

                        <hr />

                        <button onClick={() => { this.kycWallet() }} className="parent_1_grad kyclist">
                            <li className='lis_paren_side  mb-2'>

                                <img src={kycicon} className="icon_sidebar" />
                                <span>
                                    KYC
                                </span>

                            </li>
                        </button>
                        <a href={this.state.dex} target="_blank" className="parent_1_grad">
                            <li className='lis_paren_side'>
                                <img src={exchange} className="icon_sidebar" />
                                <span>
                                    Flashpad DEX
                                </span>

                            </li>
                        </a>




                        <a href={this.state.Docs} target="_blank" className="parent_1_grad">
                            <li className='lis_paren_side'>
                                <img src={docsicon} className="icon_sidebar" />
                                <span>
                                    Docs
                                </span>

                            </li>
                        </a>

                        <a href={this.state.telegram} target="_blank" className="parent_1_grad">
                            <li className='lis_paren_side'>

                                <img src={telegramicon} className="icon_sidebar" />
                                <span>
                                    Telegram
                                </span>

                            </li>
                        </a>

                        <a href={this.state.twitter} target="_blank" className="parent_1_grad">
                            <li className='lis_paren_side'>

                                <img src={twittericon} className="icon_sidebar" />
                                <span>
                                    Twitter
                                </span>

                            </li>
                        </a>

                        <a
                            href={this.state.support}
                            target="_blank" className="parent_1_grad">
                            <li className='lis_paren_side'>

                                <img src={supporticon} className="icon_sidebar" />
                                <span>
                                    Support
                                </span>

                            </li>
                        </a>
                    </ul>


                </div>

                <div id="footer_side">
                    <div className='footer_menu_flex_side'>
                        <a className="mr-3 link_wallet_new"

                        >
                            <img src={linea} className='img-fluid img_linea_mob' />
                        </a>


                        <button className="btn_bar_mobl d-lg-none" onClick={() => this.setSidebarWidth()}>
                            <img src={bar_img_white} className='img-fluid img_bar' />
                        </button>
                    </div>
                    {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}
                </div>

            </>
        );
    }
}



export default withRouter(Sidebar)