import React, { Component } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Trendingslider from '../trendingslider';
import { Container, Tab, Nav, Col, Row } from 'react-bootstrap';
import '../../css/styles.css';
import logo_curve from "../../images/Bitdeal/logo_curve.png"
import favicon from "../../images/Bitdeal/logos.png"
import { Getalltokenlock, Getlockercount, Getmytokenlock, UseTokenInfo, ViewMylock } from '../../hooks/useContract';
import { searchdata } from '../../hooks/filter';
import PremiumLaunch from "../premiumLaunch"

class TokenLock extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    document.getElementById("lock_parent").classList.add("active");
    this.setState({ eventKey: 'first' })
    this.hideLoader();
    this.getmylock();
    this.getalllock();
  }

  getmylock = async () => {
    this.showLoader()
    const mylockaddress = await Getmytokenlock();
    console.log("mylock", mylockaddress);
    this.setState({ "mylocks": mylockaddress });
    let mylockdata = []
    console.log("IIIII", i);
    for (var i = 0; i < mylockaddress?.length; i++) {
      console.log("IIIII", i, mylockaddress?.length);

      if (i < mylockaddress?.length) {
        let singlelockdata = await ViewMylock(mylockaddress[i]);
        console.log("datass", singlelockdata, await UseTokenInfo(mylockaddress[i]).name);
        const tokendata = await UseTokenInfo(mylockaddress[i])
        var newData = { ...singlelockdata, ...tokendata }
        mylockdata.push(newData);
        console.log("mylck", mylockdata, i, mylockaddress?.length, mylockdata?.length);
        if (mylockdata.length == 3) {
          console.log("if", mylockdata, i, mylockaddress?.length, mylockdata?.length);
          this.setState({ mylocksdata: mylockdata })
          this.setState({ mylockindex: i + 1 })
          return;
        }
        else if (i == mylockaddress?.length - 1) {
          console.log("ifelse", mylockdata, i, mylockaddress?.length, mylockdata?.length);
          this.setState({ mylocksdata: mylockdata })
          this.setState({ mylockindex: i + 1 })
          return;
        }
      }
      this.hideLoader();
    }
  }

  loadmylock = async () => {
    console.log("insideload")
    this.setState({ isLoading: true })
    console.log("insideload", this.state.mylockindex, this?.state?.mylocks.length, this.state.mylocksdata.length);
    for (var i = this.state.mylocksdata.length; i < this?.state?.mylocks.length; i++) {
      let lockarray = []
      if (i < this.state.mylocks.length) {
        console.log("insidefor", this.state.mylocks, this.state.mylocks[i], i);
        let singlelockdata = await ViewMylock(this.state.mylocks[i]);
        const tokendata = await UseTokenInfo(this.state.mylocks[i])
        var newData = { ...singlelockdata, ...tokendata }
        lockarray.push(newData);
        console.log(tokendata, singlelockdata, "tokendata,singlelockdata", lockarray)
        if (lockarray.length == 3) {
          var total = this.state.mylocksdata.concat(lockarray)
          console.log("tokendata,singlelockdata", total)
          this.setState({ mylocksdata: total })
          this.setState({ mylockindex: i - 1 })
          this.setState({ isLoading: false })
          return 0;
        } else if (i == (this.state.mylocks.length - 1)) {
          var total = this.state.mylocksdata.concat(lockarray)
          this.setState({ mylocksdata: total })
          this.setState({ mylockindex: i - 1 })
          this.setState({ mylockbutton: false });
          this.setState({ isLoading: false })
          return 0;
        } else {
          var total = this.state.mylocksdata.concat(lockarray)
          this.setState({ mylocksdata: total })
        }
      }
    }
    this.setState({ isLoading: false })
  }

  getalllock = async () => {
    this.showLoader()
    const lockercount = await Getlockercount();
    this.setState({ lockercount: lockercount });
    const locker = await Getalltokenlock(lockercount, 0, this.state.interval);
    console.log("locker", locker);
    this.setState({ alllocksdata: locker?.lock });
    this.setState({ alllocksindex: locker?.index })
    if (locker.index >= lockercount)
      this.setState({ alllockbutton: false })
    this.hideLoader()
  }

  loadalllock = async () => {
    this.setState({ allisLoading: true })
    const lockercount = this.state.lockercount;
    const locker = await Getalltokenlock(lockercount, this.state.alllocksindex);
    console.log("locker", locker);
    if (locker?.index >= lockercount)
      this.setState({ alllockbutton: false })
    var total = this.state.alllocksdata.concat(locker?.lock);
    this.setState({ alllocksdata: total });
    this.setState({ alllocksindex: locker?.index })
    this.setState({ allisLoading: false });
  }

  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: '',
      isLoading: false,

      mylocks: [],
      mylocksdata: [],
      mylockindex: "",
      mylockbutton: true,
      alllocks: [],
      alllocksdata: [],
      alllocksindex: "",
      alllockbutton: true,
      lockercount: "",
      interval: 3,
      isuser: false,
      singlemylocksdata: {},
      singlealllocksdata: {},
      allisLoading: false
    };
  }

  render() {




    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={logo_curve} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec pb-5'>
                  <Trendingslider />
                  <div className="right_side_spacing">
                    <div className='row my-5 row_ppos'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='bg_layer'>
                            <div className='card-body'>
                              <p className='overlay_card_head mb-0'>Token Lock</p>
                              <div className="tab_par px-0 py-3">
                                <Tab.Container defaultActiveKey="first">
                                  <Row className="container-fluid mt-4 w-100 mx-0 px-0">
                                    <Col lg={12} className="px-0">
                                      <div className="d-flex align-items-center justify-content-between flex_col_res_mob">

                                        <div>
                                          <Nav variant="" className="table_nav justify-content-end" onSelect={(e) => { this.setState({ eventKey: e }) }}>
                                            <Nav.Item>
                                              <Nav.Link eventKey="first" className="nav_link" id="first">
                                                <p className='mb-0'>All</p>

                                              </Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                              <Nav.Link eventKey="second" className="nav_link" id="second">
                                                <p className='mb-0' >My Lock</p>

                                              </Nav.Link>

                                            </Nav.Item>


                                          </Nav>
                                        </div>
                                        <div className='d-flex'>
                                          <div className="contract position-relative">
                                            <span className="contractbg position-absolute">
                                            </span>
                                            <div className="name position-relative d-flex align-items-center justify-content-center text-center">Search Token</div>
                                          </div>
                                          <div className="borderGradient d-flex align-items-center">
                                            <input className='form-control' id='myInput' onChange={(e) => { searchdata(e, this.state.eventKey) }} />


                                          </div>
                                        </div>
                                      </div>
                                    </Col>

                                    <Col lg={12} className="img_center_lg px-0 mt-4">
                                      <div className='container container_custom px-0'>
                                        <Tab.Content>
                                          <Tab.Pane eventKey="first">
                                            <div class="table-responsive">
                                              {console.log("this.state.alllocksdata", this.state.alllocksdata)}
                                              {this.state.alllocksdata.length > 0 ?
                                                <table className="table recepients_table" id="myTable">
                                                  <thead>
                                                    <tr>
                                                      <th>Token</th>
                                                      <th>Amount</th>
                                                    </tr>
                                                  </thead>

                                                  {this.state.alllocksdata.map((data, i) => <>
                                                    <tr>
                                                      <td>
                                                        <div className="d-flex pr-2">
                                                          <div className="d-flex justify-content-center align-items-center">
                                                            <div className="relative_token_div  mr-2">
                                                              <img className="token_image_sm " src={favicon} />
                                                            </div>

                                                          </div>
                                                          <div className="p-0 table_det">
                                                            <span className="table_text">{data?.name}</span>
                                                            <span className="sub_tabletext">LINKS</span>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>{(data?.amount / 10 ** data?.decimal).toFixed(0) + " " + data?.symbol}</td>
                                                      <td className="d-flex justify-content-end"><a
                                                        href={`/lockdetail/${data.LPAddress}`} className="link_text" to='/lockdetail'>View</a></td>

                                                    </tr>
                                                  </>)}
                                                </table> : <p className='text-center mt-3'>No data found</p>}
                                            </div>
                                            <div className="mt-3 d-flex justify-content-center align-items-center btn_pagin_wrap">
                                              {this.state.alllockbutton && <button className="get-started-btn orbitron_font text-white" onClick={this.loadalllock.bind(this)} id="load_more_btn">{this.state.allisLoading ? 'Loading...' : 'View More Locks'}</button>}
                                            </div>

                                          </Tab.Pane>
                                          <Tab.Pane eventKey="second">
                                            <div class="table-responsive">
                                              {this.state.mylocksdata.length > 0 ?
                                                <table className="table recepients_table" id="myTable1">
                                                  <thead>
                                                    <tr>
                                                      <th>Token</th>
                                                      <th>Amount</th>
                                                    </tr>
                                                  </thead>
                                                  {this.state.mylocksdata.map((data, i) => <>
                                                    {console.log("mylocksdata", data)}
                                                    <tr>
                                                      <td>
                                                        <div className="d-flex pr-2">
                                                          <div className="d-flex justify-content-center align-items-center">
                                                            <div className="relative_token_div  mr-2">
                                                              <img className="token_image_sm " src={favicon} />
                                                            </div>

                                                          </div>
                                                          <div className="p-0 table_det">
                                                            <span className="table_text">{data.name}</span>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>{(data.amount / 10 ** data?.decimals).toFixed(0) + " " + data?.symbol} </td>
                                                      <td className="d-flex justify-content-end"><a className="link_text" onClick={
                                                        (e) => {
                                                          this.setState({ singlemylocksdata: data })
                                                          console.log("data", data);
                                                          this.props.history.push({
                                                            pathname: "/view-lockinfo",
                                                            state: { lockdata: data, address: this.state.mylocks[i], index: i }
                                                          })
                                                        }
                                                      }
                                                      >View</a></td>
                                                    </tr>
                                                  </>)}
                                                </table> : <p className='text-center mt-3'>No data found</p>}
                                            </div>
                                            <div className="mt-3 d-flex justify-content-center align-items-center btn_pagin_wrap">
                                              {this.state.mylocks?.length > this.state.mylocksdata?.length && <button className="get-started-btn orbitron_font text-white" onClick={this.loadmylock.bind(this)} id="load_more_btn" disabled={this.state.isLoading}>{this.state.isLoading ? "Loading..." : "View More Locks"}</button>}
                                            </div>
                                            <Container>
                                            </Container>
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </div>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <PremiumLaunch/>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

export default TokenLock