import { getSaleInfoCard, UseProxySale, UseTokenInfo } from "./useContract";
import { getAccount,getChainId } from "./useAccount";
import { CHAINS } from "../config/env";
import isEmpty from "is-empty";

export const getTotalSalesInfo = async () => {
    try {
        const proxy = await UseProxySale();

        let data = await proxy.methods.getTotalSales().call();
        console.log("proxy inside getTotalSales info:", data);

        const reverseArray = [...data].reverse();
        return (reverseArray);
    }
    catch (err) {
        console.log(err, "getTotalSalesInfo")
    }

}

export const getSaleCards = async (cards) => {
    try {
        let SaleInfoCards = [];
        await cards.map(async (index) => {
            const saleInfo = await getSaleInfoCard(index._sale);
            SaleInfoCards.push(saleInfo);
        })
        return SaleInfoCards;

    }
    catch (err) {
        console.log(err, "getSaleCards")
    }
}

export const getSaleCardsLimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("Cards data : ", cards, start, end)
        if (cards.length > 0) {

            for (var i = start; i < cards.length; i++) {
                if (i < cards.length) {
                    const saleInfo = await getSaleInfoCard(cards[i]._sale);
                    SaleInfoCards.push(saleInfo);
                    if (SaleInfoCards.length == 3) {
                        return SaleInfoCards;
                    }
                }
                else {
                    return SaleInfoCards;
                }
            }

        }
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "getSaleCardsLimit")
    }
}

export const getsaledatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0) {
            for (var i = start; i < end; i++) {
                console.log("forlop");
                if (cards.length > i) {
                    console.log("card[i]", cards[i]);
                    const saleInfo = await getSaleInfoCard(cards[i].saleaddress);
                    if(!isEmpty(saleInfo)){
                        SaleInfoCards.push(saleInfo);
                    }
                    console.log("getsaledatalimit", saleInfo);
                    
                }
                else {
                    return SaleInfoCards;
                }
            }
        }
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "getsaledatalimit")
    }
}

export const getlaunchpaddatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0 && cards.length > start) {
            for (var i = start; i < end; i++) {
                console.log("forlop", i);
                if (cards.length > i) {
                    console.log("card[i]", cards[i]._sale, i);
                    if (cards[i].isdb == true || cards[i].isdb == "true") {
                        SaleInfoCards.push(cards[i]);
                    }
                    else {
                        var saleInfo = await getSaleInfoCard(cards[i]._sale ? cards[i]._sale : cards[i].saleaddress);
                        console.log("saleInfo",saleInfo)
                        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                            saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                            SaleInfoCards.push(saleInfo);
                            console.log("bnb", SaleInfoCards);
                        }
                        else {
                            const newdata = await UseTokenInfo(saleInfo?.useWithToken);
                            saleInfo = { ...saleInfo, ...{ coin: newdata?.symbol } };
                            SaleInfoCards.push(saleInfo);
                        }
                    }

                }
                else {
                    console.log("other", SaleInfoCards);
                    return SaleInfoCards;
                }
            }
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "getlaunchpaddatalimit")
    }
}

export const getlaunchpadPremiumdata = async (cards, start, end,premiumSale) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (premiumSale.length > 0 && premiumSale.length > start) {
            for (var i = start; i < end; i++) {
                let index =  premiumSale[i]
                console.log("forlop", i);
                if (premiumSale.length > i) {
                    console.log("card[i]", cards[index]._sale, i,cards[index]);
                    if (cards[index].isdb == true || cards[index].isdb == "true") {
                        SaleInfoCards.push(cards[index]);
                    }
                    else {
                        var saleInfo = await getSaleInfoCard(cards[index]._sale ? cards[index]._sale : cards[index].saleaddress);
                        console.log("saleInfo",saleInfo)
                        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                            saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                            SaleInfoCards.push(saleInfo);
                            console.log("bnb", SaleInfoCards);
                        }
                        else {
                            const newdata = await UseTokenInfo(saleInfo?.useWithToken);
                            saleInfo = { ...saleInfo, ...{ coin: newdata?.symbol } };
                            SaleInfoCards.push(saleInfo);
                        }
                    }

                }
                else {
                    console.log("other", SaleInfoCards);
                    return SaleInfoCards;
                }
            }
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "getlaunchpaddatalimit")
    }
}

export const getmycontributiondatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0) {
            for (var i = start; i < end; i++) {
                console.log("forlop");

                if (cards.length > i) {
                    console.log("card[i]", cards[i]);
                    if (cards[i]?.isdb == true || cards[i]?.isdb == "true") {
                        SaleInfoCards.push(cards[i]);
                    }
                    else {
                        const saleInfo = await getSaleInfoCard(cards[i]?.saleaddress ? cards[i]?.saleaddress : cards[i]?._sale);
                        SaleInfoCards.push(saleInfo);
                    }
                }
                else {
                    return SaleInfoCards;
                }
            }
        }
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "getmycontributiondatalimit")
    }
}

export const gethomelaunchpaddetail = async (cards) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards,);
        for (var i = 0; i < cards.length; i++) {
            var saleInfo = await getSaleInfoCard(cards[i].saleaddress);
            SaleInfoCards.push(saleInfo);
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "gethomelaunchpaddetail")
    }
}

export const getPrivatesaleFilter = async (cards, isuser) => {
    try {
        let SaleInfoCards = [];
        for (var i = 0; i < cards.length; i++) {
            let saleInfo = await getSaleInfoCard(cards[i]._sale);
            if (!isuser) {
                if (saleInfo.isWhitelisted) {
                    if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {                   
                        saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                        SaleInfoCards.push(saleInfo);
                    }
                    else {
                        const newdata = await UseTokenInfo(saleInfo.useWithToken);
                        saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                        SaleInfoCards.push(saleInfo);
                    }
                }
            }

            if (isuser) {
                const account = getAccount();
                if (saleInfo.isWhitelisted && saleInfo.owner == account) {
                    if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                        saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL} };
                        SaleInfoCards.push(saleInfo);

                    }
                    else {
                        const newdata = await UseTokenInfo(saleInfo.useWithToken);
                        saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                        SaleInfoCards.push(saleInfo);

                    }
                }
            }
        }
        return SaleInfoCards;
    }
    catch (err) {
        console.log(err, "getPrivatesaleFilter")
    }
}


export const getPrivatesaleCardLimit = async (cards, start, end, user) => {
    try {
        console.log("user", user);
        let SaleInfoCards = [];
        console.log("Cards data : ", cards, start, end)
        if (cards.length > 0) {
            for (var i = start; i < cards.length; i++) {
                if (i < cards.length) {
                    let saleInfo = await getSaleInfoCard(cards[i]._sale);
                    if (!user) {
                        if (saleInfo.isWhitelisted) {
                            if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                                saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i + 1, saleInfoCards: SaleInfoCards };
                                }
                            }
                            else {
                                const newdata = await UseTokenInfo(saleInfo.useWithToken);                       
                                saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i + 1, saleInfoCards: SaleInfoCards };
                                }
                            }

                            console.log("saleinfoooo", saleInfo);
                        }
                    }

                    if (user) {
                        const account = getAccount();
                        if (saleInfo.owner == account && saleInfo.isWhitelisted) {
                            if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {                           
                                saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i + 1, saleInfoCards: SaleInfoCards };
                                }
                            }
                            else {
                                const newdata = await UseTokenInfo(saleInfo.useWithToken);
                                saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i + 1, saleInfoCards: SaleInfoCards };
                                }
                            }
                        }
                    }
                }
                else {
                    return { index: i + 1, saleInfoCards: SaleInfoCards };
                }
            }

            return { index: cards.length, saleInfoCards: SaleInfoCards };

        }
    }
    catch (err) {
        console.log(err, "getPrivatesaleCardLimit")
    }
}

export const getLaunchpadsaleCardLimit = async (cards, start, end, user) => {
    try {
        console.log("user", user);
        let SaleInfoCards = [];
        console.log("Cards data : ", cards, start, end)
        if (cards.length > 0) {
            for (var i = start; true; i++) {
                if (i < cards.length) {
                    let saleInfo = await getSaleInfoCard(cards[i]._sale);
                    if (!user) {
                        if (!saleInfo.isWhitelisted) {
                            if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {                           
                                saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i, saleInfoCards: SaleInfoCards };
                                }
                            }
                            else {
                                const newdata = await UseTokenInfo(saleInfo.useWithToken);
                                saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i, saleInfoCards: SaleInfoCards };
                                }
                            }
                            console.log("saleinfoooo", saleInfo);
                        }
                    }

                    if (user) {
                        const account = getAccount();
                        if (saleInfo.owner == account && !saleInfo.isWhitelisted) {
                            if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                                saleInfo = { ...saleInfo, ...{ coin: CHAINS[getChainId()].SYMBOL } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i, saleInfoCards: SaleInfoCards };
                                }
                            }
                            else {
                                const newdata = await UseTokenInfo(saleInfo.useWithToken);                           
                                saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                                SaleInfoCards.push(saleInfo);
                                if (SaleInfoCards.length == 3) {
                                    return { index: i, saleInfoCards: SaleInfoCards };
                                }
                            }
                        }
                    }
                }
                else {
                    return { index: i, saleInfoCards: SaleInfoCards };
                }
            }

        }
    }
    catch (err) {
        console.log(err, "getLaunchpadsaleCardLimit")
    }
}

export const searchSale = async (SaleInfoCards, search) => {
    try {
        const filteredData = await SaleInfoCards.filter(value => ((value._name).toUpperCase()).includes(search.toUpperCase()));
        let filteredCard = [];
        await filteredData.map(async (index) => {
            const saleInfo = await getSaleInfoCard(index._sale);
            filteredCard.push(saleInfo);
        })
        console.log("Sale filteredCard : ", filteredCard)
        return filteredCard;
    }
    catch (err) {
        console.log(err, "searchSale")
    }
}

export const searchCards = async (SaleInfoCards, search) => {
    try {
        console.log("SaleInfoCards, search", SaleInfoCards, search);
        const filteredData = await SaleInfoCards.filter(value => ((value._name).toUpperCase()).includes(search.toUpperCase()));
        console.log("filtered data", filteredData);
        return filteredData;
    }
    catch (err) {
        console.log(err, "searchCards")
    }
}

export const isSaleLive = (start, end, isOpen) => {
    try {
        return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
    }
    catch (err) {
        console.log(err, "isSaleLive")
    }
}

export const isUpcoming = (start, end) => {
    try {
        return (Date.now() < (start * 1000));
    }
    catch (err) {
        console.log(err, "isUpcoming")
    }
}

export const isSaleEnded = (start, end, isOpen) => {
    try {
        return (Date.now() >= (end * 1000)) || !isOpen
    }
    catch (err) {
        console.log(err, "isSaleEnded")
    }
}

export const UpcomingDiffernce = (start) => {
    try {
        return ((start * 1000) - Date.now());
    }
    catch (err) {
        console.log(err, "UpcomingDiffernce")
    }
}

export const Salediffernce = (end) => {
    try {
        return ((end * 1000) - Date.now());
    }
    catch (err) {
        console.log(err, "Salediffernce")
    }
}


export const processCSV = (str, delim = ',') => {
    try {
        const headers = str.slice(0, str.indexOf('\n')).split(delim);
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');

        const newArray = rows.map(row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })

        return (newArray)
    }
    catch (err) {
        console.log(err, "processCSV")
    }
}


export const searchCardsadmin = async (SaleInfoCards, search) => {
    try {
        console.log("SaleInfoCards, search", SaleInfoCards, search);
        const filteredData = await SaleInfoCards.filter(value => ((value._sale).toUpperCase()).includes(search.toUpperCase()));
        console.log("filtered data", filteredData);
        return filteredData;
    }
    catch (err) {
        console.log(err, "searchCardsadmin")
    }
}

