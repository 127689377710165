import bsctestnet from "../images/bsctestnet.png"
import {getChainId } from '../hooks/useAccount'
 
export const ONEDAYINSECONDS = 86400;

export const frontendurl  =  window.location.origin;

export const userFee = 5

export const userFeeWithtoken = 4

export const CHAINS = [

    // {
    //     NAME: "BSC-TEST",
    //     RPC_URL:"https://bsc-testnet.publicnode.com",
    //     CHAIN_ID:97,
    //     IMAGE: bsctestnet,
    //     SYMBOL:"BNB",
    //     ROUTER: "0x097b0468D402B7a13969C3D8B145717a6ef928DD",
    //     LIQUIDITY_TOKEN_ADDRESS : "0xf361377AcE5aB0828e7e42afE528be2D5823d730",
    //     STANDARD_TOKEN_ADDRESS : "0x0fA57fA6489926C2Ad02f543657D3A195fbC8DaC",
    //     LOCK_ADDRESS : "0xEeE89241C8cc080241294988D0d06FFaeCEfC2C2",
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     USDT :"0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",
    //     USDC : "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",
    //     DAI:"0xeDDC82488ecf00aC8CD8aD942AB3632E41424820",
    //     TRANSACTION : "https://testnet.bscscan.com/tx/",
    //     TokenTracker : "https://testnet.bscscan.com/token/",
    //     Testnet : "https://testnet.bscscan.com/address/", 
    // },

    {
        NAME: "LINEAETH-TEST",
        RPC_URL:"https://linea-sepolia.blockpi.network/v1/rpc/public",
        CHAIN_ID:59141,
        IMAGE: bsctestnet,
        SYMBOL:"LineaETH",
        ROUTER: "0xaAf22Cefe576EbE10fF423d3f441ABCf964a6bB5",
        LIQUIDITY_TOKEN_ADDRESS : "0xA97E8E6eA22b74C7003adaC8319cBbf41fd521cD",
        STANDARD_TOKEN_ADDRESS : "0xF5EB21D475167b3057BDCaC1edA9b600334cd326",
        LOCK_ADDRESS : "0x75182b34Bddbb16Dcc0Ce3817D7E6F85D2c3f3C8", 
        LineaETH : "0x0000000000000000000000000000000000000000",
        USDT :"0x31f16cfb32Ab12abbfF87586bC728FF85F7e488D",
        USDC : "0x31f16cfb32Ab12abbfF87586bC728FF85F7e488D",
        DAI:"0x59C7deb41a7947704311ac9ec54158389e77C806",
        TRANSACTION : "https://sepolia.lineascan.build/tx/",
        TokenTracker : "https://sepolia.lineascan.build/token/",
        Testnet : "https://sepolia.lineascan.build/address/",
    },


];

//local
// export const API_URL = "http://localhost:7097"
// export const FRONT_URL ="http://localhost:3000/"


//demo 
export const API_URL = "https://flashpad-backend.maticz.in"
export const FRONT_URL ="https://flashpad.maticz.in"

//live
// export const API_URL = "https://api-backend.flashpad.io"
// export const FRONT_URL = "https://launchpad.flashpad.io/"



export const COINS = [CHAINS[getChainId()].SYMBOL , "DAI" , "USDT" , "USDC" ];

export const STATUS = ["Upcoming" , "Live" , "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"

export const ThreeMonthsInSeconds = 7884000.000000

export const SixMonthsInSeconds = 15770000.0000000

export const TwelveMonthsInSeconds = 31540000.0000000

export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"


