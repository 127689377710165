import React, { Component } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Trendingslider from '../trendingslider';
import TransfertokenModal from "./TransfertokenModal"
import '../../css/styles.css';
import Countdown, { zeroPad } from 'react-countdown';
import favicon from "../../images/Bitdeal/logo_curve.png";
import { Unlocktoken } from '../../hooks/useContract';
import { UpcomingDiffernce } from '../../hooks/useProjects';
import PremiumLaunch from "../premiumLaunch"


const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {

  if (completed) {
    return <div className='btn_timer_new'>
      <span>{zeroPad(days)}</span>
      <span>{zeroPad(hours)}</span>
      <span>{zeroPad(minutes)}</span>
      <span>{zeroPad(seconds)}</span>
    </div>
  } else {
    // Render a countdown
    return <div className='btn_timer_new'>

      <span>{zeroPad(days)}</span>
      <span>{zeroPad(hours)}</span>
      <span>{zeroPad(minutes)}</span>
      <span>{zeroPad(seconds)}</span>
    </div>;
  }
};

class LockInfo extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    document.getElementById("lock_parent").classList.add("active");
    this.hideLoader();
    this.getlocationdata();
  }

  getlocationdata = async () => {
    console.log("location_data", this.props.location, this.props.location.state.lockdata.to, localStorage.getItem("accountInfo"));
    if (this.props.location.state.tokeninfo) {
      this.setState({ tokeninfo: this.props.location.state.tokeninfo })
      this.setState({ lockerinfo: this.props.location.state.lockerinfo })
      this.setState({ owner: this.props.location.state.owner });
      this.setState({ tokenaddress: this.props.location.state.tokenaddress });
    }
  }


  unlock = async () => {
    if (this.props.location.state.lockdata.to !== this.props.location.state.lockdata.Createduser) {
      var type = "transeferOwner"
    }
    else {
      var type = "createduser"
    }
    const unlocktoken = await Unlocktoken(this.props.location.state.address, type, this.props.location.state.index)
    if (unlocktoken) {
      this.setState({ sucess: true })
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      transfertokenModal: false,
      tokeninfo: {},
      lockerinfo: {},
      tokenaddress: "",
      owner: "",
      sucess: false
    };
  }

  onDismiss() {
    this.setState({ transfertokenModal: false });
  }


  render() {

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const { transfertokenModal } = this.state

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">
          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Header />
            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec'>
                  <Trendingslider />

                  <div className="right_side_spacing">
                    <div className='row mt-3'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className='bg_layer mt-4'>
                          <div className="card-body pos_z_body_car">
                            <p className='overlay_card_head text-center'>Unlock in</p>
                            <div className='text-center mt-3 mb-2'>
                              <Countdown date={Date.now() + (UpcomingDiffernce(this.props.location.state.lockdata.unlockOn))} renderer={renderer} className="countdown_grey" zeroPadTime={2} />
                            </div>{console.log("date", Date.now() + new Date(this.props.location.state.lockdata.unlockOn * 1000).getTime())}
                          </div>
                        </div>
                        <div className='bg_layer mt-4'>
                          <div className="card-body pos_z_body_car">
                            <p class="overlay_card_head">Token Info</p>
                            <p className='white_txt_sm d-flex justify-content-between mt-4 mb-3'>
                              <span className='desc_grey_txt'>Token Address :</span>
                              <span className='desc_grey_txt font_12 brk_tetx_vla'>{this.props.location.state.address}
                              </span>
                            </p>

                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                              <span className='desc_grey_txt'>Token Name :</span>
                              <span className='desc_grey_txt font_12'>{this.props.location.state.lockdata.name}</span>
                            </p>

                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                              <span className='desc_grey_txt'>Token Symbol :</span>
                              <span className='desc_grey_txt font_12'>{this.props.location.state.lockdata.symbol}
                              </span>
                            </p>

                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                              <span className='desc_grey_txt'>Token Decimals :</span>
                              {console.log("decimals_decimalsdecimals",this.props.location.state.lockdata.decimals)}
                              <span className='desc_grey_txt font_12'>{this.props.location.state.lockdata.decimals}
                              </span>
                            </p>


                            <p class="overlay_card_head mt-5">Lock Info</p>


                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                              {console.log("this.state.lockerinfo.amount", this.state.lockerinfo.amount)}
                              <span className='desc_grey_txt'>Total Amount Locked :</span>
                              <span className='desc_grey_txt font_12'>{parseFloat(this.props.location.state.lockdata.amount / 10 ** 18).toFixed(3)}</span>
                            </p>

                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                              <span className='desc_grey_txt'>Owner :</span>
                              <span className='desc_grey_txt font_12 brk_tetx_vla'>{this.props.location.state.lockdata.to}</span>
                            </p>

                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                              <span className='desc_grey_txt'>Lock Date :</span>
                              <span className='desc_grey_txt font_12'>{new Date(this.props.location.state.lockdata.unlockOn * 1000).toLocaleDateString("en-US", options)} </span>
                            </p>

                            <div className={this.state.sucess ? 'd-none' : 'col-12 col-md-12 text-center mt-4 btn_lock_info_div mb-3'}>
                              <button className="get-started-btn orbitron_font text-white"
                                onClick={() => this.setState({ transfertokenModal: true })}
                                disabled={this.props.location.state.lockdata.to !== this.props.location.state.lockdata.Createduser}
                              >
                                Transfer Lock Ownership
                              </button>
                              {console.log("unlock", this.props.location.state.lockdata.to !== localStorage.getItem("accountInfo"))}
                              <button className="get-started-btn-border orbitron_font ml-3"
                                disabled={(this.props.location.state.lockdata.unlockOn * 1000) > Date.now() || this.props.location.state.lockdata.to !== localStorage.getItem("accountInfo")}
                                onClick={this.unlock}
                              >
                                Unlock
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PremiumLaunch/>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {transfertokenModal && <TransfertokenModal tokenaddress={this.props.location.state.address} TokenData = {this.props.location.state} onDismiss={() => this.onDismiss()} />}

      </div>
    )
  }
}

export default LockInfo