import React, { Component } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

import "react-datepicker/dist/react-datepicker.css";
import bgstyle2 from "../../images/icon_stop.png";
import { Container } from 'react-bootstrap';
import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

class Comingsoon extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    this.hideLoader();
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }



  render() {





    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec'>

                  <div className="right_side_spacing">


                    <Container className="container coming_sec">
                      <div className="bg_outline2">
                      </div>

                      <div className="bg_outline1">
                      </div>
                      <div className="coming_soon">
                        <div className="text-center mb-4">
                          <img src={bgstyle2} />

                        </div>
                        <h1 className="text-gradient-coming">Coming Soon..</h1>
                      </div>

                    </Container>

                    {/* end of Coming soon session */}


                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />

        </div>
      </div>
    )
  }
}

export default Comingsoon