import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './App.css';

import LaunchpadList from './components/Launchpad/LaunchpadList';

import PrivatesaleList from './components/Privatesale/PrivatesaleList';

import Home from './components/Projects/Home';

import CreateToken from './components/Launchpad/CreateToken';

import PrivatesaleDetail from './components/Privatesale/PrivatesaleDetail';
import PrivateSaleEdit from './components/Privatesale/PrivateSaleEdit';


import LaunchpadDetail from './components/Launchpad/LaunchpadDetail';
import LaunchpadEdit from './components/Launchpad/LaunchpadEdit';

import TokenLock from './components/Lock/TokenLock';
import CreateLock from './components/Lock/CreateLock';
import LockDetail from './components/Lock/LockDetail';


import Comingsoon from './components/Projects/Comingsoon';
import Kyc from './components/Projects/Kyc';

import Login from './components/Admin/Login';
import Forgotpassword from './components/Admin/Forgotpassword';
import Settings from './components/Admin/Settings';
import Resetpassword from './components/Admin/Resetpassword';
import AdminrestrictIp from './components/Admin/AdminIprestriction';

import Adminlaunchpad from './components/Admin/Adminlaunchpad';
import Adminkyc from './components/Admin/Adminkyc';
import Adminsettings from './components/Admin/Adminsettings';
import EmailVerification from './components/Admin/Emailverification';
import AdminFee from './components/Admin/AdminFee'

import Wallethome from './components/Projects/Wallethome';

import TokenSuccess from './components/Launchpad/TokenSuccess';

import LockInfo from "./components/Lock/LockInfo";

import ViewLockInfo from "./components/Lock/Viewlockinfo";

import Adminlaunchpadsettings from './components/Admin/Adminlaunchpadsettings';

import AdminTrending from './components/Admin/AdminTrending';
import Adminaudit from './components/Admin/Adminaudit';


import Admincreatelaunchpad from './components/Admin/Admincreatelaunchpad';
import Adminlaunchpaddetail from './components/Admin/adminlaunchpaddetail';
import Adminprivatesaledetail from './components/Admin/Adminprivatedetail';
import AdminLaunchEdit from './components/Admin/AdminLaunchEdit';
import AdminprivateEdit from './components/Admin/AdminPrivateEdit'

import CreateLPLock from './components/Lock/Lptokenlock';


import Createadmin from './components/Admin/Create';
import CreateFairlaunchadmin from './components/Admin/CreateFairlaunch';
import CreatePrivatesaleadmin from './components/Admin/CreatePrivatesale';


import Create from './components/Launchpad/Create';
import CreateFairlaunch from './components/Launchpad/CreateFairlaunch';
import CreatePrivatesale from './components/Privatesale/CreatePrivatesale';

import EditCmsModal from './components/Admin/EditCmsModal';
import CmsSettings from './components/Admin/cmsSettings'

function App() {

  return (
    <div>
      <div><Toaster /></div>
      <Router>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/home/:id' component={Home} />


        <Route exact path='/launchpadlist' component={LaunchpadList} />
        <Route exact path='/privatesaleList' component={PrivatesaleList} />

        <Route exact path='/create' component={Create} />
        <Route exact path='/createfairlaunch' component={CreateFairlaunch} />
        <Route exact path='/createprivatesale' component={CreatePrivatesale} />
        <Route exact path='/launchpadedit' component={LaunchpadEdit} />
        <Route exact path='/launchpadedit/:id' component={LaunchpadEdit} />
        <Route exact path='/privatesaleedit' component={PrivateSaleEdit} />
        <Route exact path='/privatesaleedit/:id' component={PrivateSaleEdit} />
        <Route exact path='/createtoken' component={CreateToken} />
        <Route exact path='/tokenlock' component={TokenLock} />
        <Route exact path='/createlock' component={CreateLock} />
        <Route exact path='/createlplock' component={CreateLPLock} />

        <Route exact path='/lockdetail/:id' component={LockDetail} />
        <Route exact path='/lockinfo' component={LockInfo} />
        <Route exact path='/view-lockinfo' component={ViewLockInfo} />
        <Route exact path='/wallet' component={Wallethome} />
        <Route exact path='/tokensuccess' component={TokenSuccess} />
        <Route exact path='/kyc' component={Kyc} />

        <Route exact path='/crypto' component={Comingsoon} />

        <Route exact path='/launchpaddetail' component={LaunchpadDetail} />
        <Route exact path='/launchpaddetail/:id' component={LaunchpadDetail} />
        <Route exact path='/launchpaddetail/:id/:id' component={LaunchpadDetail} />

        <Route exact path='/privatesaledetail/:id' component={PrivatesaleDetail} />
        <Route exact path='/privatesaledetail/:id/:id' component={PrivatesaleDetail} />

        <Route exact path='/privatesaledetail' component={PrivatesaleDetail} />

        <Route exact path='/login' component={Login} />
        <Route exact path='/forgotpassword' component={Forgotpassword} />
        <Route exact path='/resetpassword' component={Resetpassword} />
        <Route exact path='/resetpassword/:authToken' component={Resetpassword} />

        <Route exact path='/verify-old-email/:authToken' component={EmailVerification} />
        <Route exact path='/verify-new-email/:authToken' component={EmailVerification} />

        <Route exact path='/createadmin' component={Createadmin} />
        <Route exact path='/createfairlaunchadmin' component={CreateFairlaunchadmin} />
        <Route exact path='/createprivatesaleadmin' component={CreatePrivatesaleadmin} />

        <Route exact path='/adminlaunchpad' component={Adminlaunchpad} />
        <Route exact path='/adminkyc' component={Adminkyc} />
        <Route exact path='/adminsettings' component={Adminsettings} />
        <Route exact path='/feeSettings' component={AdminFee} />

        <Route exact path='/settings' component={Settings} />

        <Route exact path='/launchpadsettings' component={Adminlaunchpadsettings} />

        <Route exact path='/admintrending' component={AdminTrending} />

        <Route exact path='/adminaudit' component={Adminaudit} />

        <Route exact path='/admincreatelaunchpad' component={Admincreatelaunchpad} />

        <Route exact path='/adminlaunchpaddetail' component={Adminlaunchpaddetail} />
        <Route exact path='/adminlaunchpaddetail/:id' component={Adminlaunchpaddetail} />

        <Route exact path='/adminprivatesaledetail' component={Adminprivatesaledetail} />
        <Route exact path='/adminprivatesaledetail/:id' component={Adminprivatesaledetail} />

        <Route exact path='/adminlaunchedit' component={AdminLaunchEdit} />
        <Route exact path='/adminlaunchedit/:id' component={AdminLaunchEdit} />

        <Route exact path='/adminprivateedit' component={AdminprivateEdit} />
        <Route exact path='/adminprivateedit/:id' component={AdminprivateEdit} />


        <Route exact path='/restrictIp' component={AdminrestrictIp} />

        <Route exact path='/cms' component={CmsSettings} />
        <Route exact path='/editcms' component={EditCmsModal} />



      </Router>
    </div>
  );
}

export default App;
