import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import toast from 'react-hot-toast';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import AddrestrictIpmodal from './AddIpmodal';
import DeleteIpmodal from './deleteIpmodal'

import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import { Tab, Row, Col } from 'react-bootstrap';
import { getIP, deleteIpAddress } from '../../hooks/usebackend';
import PremiumLaunch from "../premiumLaunch"


const cookies = new Cookies();
class AdminrestrictIp extends Component {

    showLoader() {
        document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
        document.getElementById("logo_overlay").style.opacity = 0.05;
        document.getElementById("loader_div").classList.remove("d-none");
        document.getElementById("loader_div").classList.add("d-block");
    }

    hideLoader() {
        document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
        document.getElementById("logo_overlay").style.opacity = 1;
        document.getElementById("loader_div").classList.remove("d-block");
        document.getElementById("loader_div").classList.add("d-none");
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.restrict()
        this.getIpaddress();
        this.hideLoader();

    }

    restrict = async () => {
        var email = cookies.get('cryp-launch-admin-email');
        console.log("email", email);
        if (!email) {
            this.props.history.push("/login")
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            addIpModal: false,
            deleteIpModal: false,
            settings: [],
            singlesetting: {},
            getIp: [],
            skip: 0,
            limit: 10,
            pagecount: 0,
            button: 0,
            currentpage: 1,
            deleteId: "",
            stepcount: 5
        };
    }

    getIpaddress = async () => {

        let getIpadd = await getIP();
        console.log("getIpadd", getIpadd);
        if (getIpadd?.data?.data?.status == true) {
            this.setState({ "getIp": getIpadd?.data?.data?.data });
            console.log("getIp", getIpadd?.data?.data?.data)
        }
    }

    deleteIpaddress = async (Id) => {
        console.log("id", Id)
        let deleteIp = await deleteIpAddress(Id);
        console.log("deleteIp", deleteIp);
        if (deleteIp?.data?.data?.status == true) {
            toast.success(deleteIp?.data?.data?.message)
            this.setState({ deleteIpModal: false });
            this.getIpaddress()
        }
    }


    onDismissAdd() {
        this.setState({ addIpModal: false });
    }

    onDismissDelete() {
        this.setState({ deleteIpModal: false });
    }

    render() {


        const location = this.props.location.pathname.split('/')[1];

        const { addIpModal, deleteIpModal } = this.state

        return (
            <div id="loader_main">
                <div id="loader_div">
                    <span className="spin_round">

                    </span>
                    <img src={favicon} className="logo_load" />
                </div>
                <div className='logo_overlay' id="logo_overlay">

                    <div className="whole_bg">
                        <Adminheader />

                        <div className="whole_sec pb-5">
                            <div className='flex_side_right'>
                                <Adminsidebar />
                                <div className='right_side_sec pb-5'>

                                    <div className="right_side_spacing">
                                        <div className='row my-5 row_ppos'>
                                            <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                                                <div className="tab_img">
                                                    <div className='bg_layer'>
                                                        <div className='card-body'>

                                                            <div className="row">
                                                                <div className="col-6 col-md-6 flex_cen_col">
                                                                    <p className='overlay_card_head mb-0'>IP List</p>
                                                                </div>
                                                                <div className="col-6 col-md-6 flex_cen_col">
                                                                    <div className='text-right'>
                                                                        <button className="get-started-btn font_20 btn_icon_grad" onClick={() => this.setState({ addIpModal: true })}>+</button>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                            <div className="tab_par px-0 py-3">



                                                                <Tab.Container defaultActiveKey="second">
                                                                    <Row className="container-fluid w-100 mx-0 px-0">


                                                                        <Col lg={12} className="img_center_lg px-0">
                                                                            <div className='container container_custom px-0'>
                                                                                <Tab.Content className='min_heighttab_cont'>

                                                                                    <Tab.Pane eventKey="second">

                                                                                        <div class="table-responsive">
                                                                                            {console.log("this.state.getIp", this.state.getIp && this.state.getIp.length > 0, this.state.getIp)}
                                                                                            {this.state.getIp && this.state.getIp.length > 0 ?
                                                                                                <table className="table recepients_table">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>IP Address</th>

                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    {this.state.getIp && this.state.getIp.length > 0 && this.state.getIp?.map((data, i) => <>
                                                                                                        <tr>
                                                                                                            <td>


                                                                                                                <div className="p-0 table_det">
                                                                                                                    <span className="table_text">{data.ipAddress}</span>

                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="d-flex justify-content-end"><a className="link_text" onClick={() => {
                                                                                                                this.setState({ deleteIpModal: true, deleteId: data._id });
                                                                                                            }
                                                                                                            }>Delete</a></td>

                                                                                                        </tr>
                                                                                                    </>
                                                                                                    )}

                                                                                                </table> : <p className='no_data_text'>No Data Found</p>}
                                                                                        </div>

                                                                                        {!this.state.settings.length == 0 &&
                                                                                            <div className="mt-2 d-flex justify-content-center align-items-center btn_pagin_wrap">
                                                                                                <button className="pagination_btn"
                                                                                                    onClick={this.handleprevpage}
                                                                                                    disabled={this.state.currentpage == 1}
                                                                                                ><i class="fa fa-angle-left" aria-hidden="true"

                                                                                                ></i></button>


                                                                                                {[...Array(this.state.stepcount)].map((val, i) => {
                                                                                                    if ((this.state.button + i + 1) <= this.state.pagecount)
                                                                                                        return (
                                                                                                            <button className={this.state.currentpage == (this.state.button + i + 1) ? "pagination_btn active" : "pagination_btn"} value={this.state.button + i + 1} onClick={
                                                                                                                (e) => {
                                                                                                                    console.log("e", e?.target?.value);
                                                                                                                    this.handlenumpage(e?.target?.value)
                                                                                                                }
                                                                                                            }>{this.state.button + i + 1}</button>
                                                                                                        )
                                                                                                }

                                                                                                )}
                                                                                                <button className="pagination_btn"
                                                                                                    onClick={this.handlenextpage}
                                                                                                    disabled={this.state.currentpage == this.state.pagecount}
                                                                                                ><i class="fa fa-angle-right" aria-hidden="true"

                                                                                                ></i></button>
                                                                                            </div>}
                                                                                    </Tab.Pane>
                                                                                </Tab.Content>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Tab.Container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <PremiumLaunch/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {addIpModal && <AddrestrictIpmodal onDismiss={() => this.onDismissAdd()} getIP={() => this.getIpaddress()} />}
                    {deleteIpModal && <DeleteIpmodal onDismiss={() => this.onDismissDelete()} OnDelete={() => { this.deleteIpaddress(this.state.deleteId) }} />}


                    <Footer />
                </div>
            </div>
        )
    }
}

export default AdminrestrictIp