import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import Trendingslider from '../trendingslider';
import { GetTokenInfo } from '../../hooks/useContract'
import videoIntro from "../../images/video_intro.png";
import playButton from "../../images/icon-container.png";
import videosquare from "../../images/square.png";
import videosquaretwo from "../../images/squaretwo.png";
import iconstop from "../../images/icon_stop.png"
import Videomodal from "./Videomodal";
import "../../css/styles.css";
import { getallsalehook, gethomecalculationhook, gettrendingdisplayhook, getCmsList, getFrontsettinghook } from "../../hooks/usebackend";
import { gethomelaunchpaddetail, getTotalSalesInfo, getlaunchpaddatalimit } from "../../hooks/useProjects";
import { Userdollar, getAccount } from "../../hooks/useAccount";
import { getSaleInfoCard } from "../../hooks/useContract";



class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      setUpdateCms: false,
      trending: [],
      projects: 0,
      bnbindollar: 0,
      users: 0,
      loadingText: false,
      totallockedvalue: 0,
      auditkyc: [],
      singleaudit: {},
      ProxyCount: 0,
      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      mycontribution: [],
      accountInfo: getAccount(),
      interval: 0,
      loadinterval: 3,
      original: [],
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      trending: [],
      dummylaunchpad: [],
      trending: [],
      filterSales: [],
      viewMore: true,
      arrayofWeek: 20,
      filteredSales: [],
      sumofEarned: 0,
      successSale: 0,
      participants: 0,
      tokeninfo: [],
      trendingSale: [],
      videoModal: false,
      launchval: true,
      getCms: [],
      setting: [],
      consulting:'',
      videoIntro:''

    };
  }

  componentDidMount() {
    document.getElementById("home_grad_aprent").classList.add("active");
    this.handlegetkyc()
    this.gettrending();
    this.loadData();
    this.Proxydata();
    this.tokenInfo();
    this.getCmsSettings()
    this.getsetting()
  }


  handlegetkyc = async () => {
    let auditkycinfo = await getallsalehook();
    if (auditkycinfo?.data?.data?.length > 0) {
      this.setState({ auditkyc: auditkycinfo?.data?.data })
    }
    let singleaudit = auditkycinfo?.data?.data?.find(e => e.saleaddress == window.location.pathname.split('/')[2])
    console.log("auditkuycinfo", auditkycinfo?.data?.data, singleaudit);

    if (singleaudit)
      this.setState({ singleaudit: singleaudit })
  }

  async gettrending() {
    let data = await gettrendingdisplayhook();
    console.log("dataa trending", data?.data?.data);
    if (data?.data?.data?.length > 0) {
      let trenddata = await gethomelaunchpaddetail(data?.data?.data)
      console.log("trenddata", trenddata);
      this.setState({ trending: trenddata })
      const Proxydata = await getTotalSalesInfo();
      if (Proxydata?.length > 0) {
        let totallock = 0;
        this.setState({ projects: Proxydata?.length });
        Proxydata?.map(async (data) => {
          console.log("mapdata", data);
          let saledata = await getSaleInfoCard(data?._sale);
          let value = saledata.presaleRate * saledata.hardCap / 10 ** 18;
          console.log("calculated value", value, totallock);
          totallock = totallock + value;
          this.setState({ totallockedvalue: Math.round(totallock * 100) / 100 })
        })

      }
      let calculation = await gethomecalculationhook();
      console.log("calculation", calculation);
      let dollar = await Userdollar(calculation?.data?.totalbnb);
      console.log("dollar in bnb", Math.round(dollar * 100) / 100);
      this.setState({ bnbindollar: Math.round(dollar * 100) / 100 })
      this.setState({ users: calculation?.data?.user });
    }
  }


  async loadData() {
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    let trenddata = await gettrendingdisplayhook();
    console.log("dataa trending", trenddata?.data?.data);
    if (trenddata?.data?.data?.length > 0)
      this.setState({ trending: trenddata?.data?.data })
    var trending = trenddata?.data?.data
    console.log("trendingdataaaaaaaaaaaaaa", trending);
    let newtotalproxy = [];
    trending?.map((val, i) => {
      let singledata = Proxydata.find(e => e?._sale == val.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata)
      }
    })
    Proxydata?.map((datas, i) => {
      let singlesale = newtotalproxy.find(e => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas)
      }
    })
    this.setState({ proxy: newtotalproxy })
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", Proxydata);
    this.setState({ original: newtotalproxy })
    const total = await getlaunchpaddatalimit(newtotalproxy, 0, newtotalproxy.length);
    console.log("total>>>", total);
    this.setState({ totalSales: total });
    var trending = []
    this.state.trending?.map((val, i) => {
      console.log("trenddataval", val)
      let singledata = total.find(e => {
        console.log("e?._sale == val.saleaddress", e?.saleaddress == val.saleaddress)
        return e?.saleaddress === val.saleaddress
      });
      console.log("singledata", singledata)
      if (singledata) {
        trending.push(singledata)
      }

    })
    this.setState({ trendingSale: trending })
    let sumofEarned = total?.reduce((total, currentValue) => {
      return total = parseFloat(total) + parseFloat(currentValue.earnedCap);

    }, 0)
    this.setState({ sumofEarned: (sumofEarned) / 10 ** 18 })

    let participants = total?.reduce((total, currentValue) => {
      return total = parseFloat(total) + parseFloat(currentValue.participants);

    }, 0)
    this.setState({ participants: participants })

    this.setState({ interval: this.state.loadinterval });

  }

  async loadMore() {
    console.log("Lad moreee", this.state.totalSales?.length < this.state.proxy?.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(this.state.proxy, this.state.interval, this.state.interval + this.state.loadInterval);
    console.log("newly ", newly);
    const total = this.state.totalSales?.concat(newly);
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }

  async Proxydata() {
    try {
      console.log("Proxydata");

      const Proxydata = await getTotalSalesInfo();
      console.log("Proxydata", Proxydata?.length);
      this.setState({ ProxyCount: Proxydata?.length })
    }
    catch (err) {
      console.log("err", err)
    }
  }

  async tokenInfo() {
    const token = await GetTokenInfo();
    console.log("tokentokentoken", token);
    this.setState({ tokeninfo: token })
  }

  getCmsSettings = async () => {

    let get = await getCmsList();
    console.log("getCms", get, get.status);
    if (get?.status == true) {
      this.setState({ "getCms": get?.result });
      console.log("getCms_getCms", get?.result)
    }
  }


  async getsetting() {
    let data = await getFrontsettinghook();
    console.log("data setting", data, data?.data?.data);
    console.log("Array", typeof data?.data?.data == 'Array', typeof data?.data?.data);
    this.setState({ setting: data?.data?.data })
    typeof data?.data?.data == 'object' && data?.data?.data?.length > 0 && data?.data?.data?.map((val, i) => {
        console.log("val", val)
        if (val.settingname == "consulting" || val.settingname == "Consulting") {
            this.setState({ consulting: val.settingvalue });
        }
        if (val.settingname == "videoIntro" || val.settingname == "VideoIntro") {
          this.setState({ videoIntro: val.settingvalue });
         }


    })
}


  render() {

    const { getCms } = this.state

    return (
      <div id="loader_main">

        <div className="logo_overlay" id="logo_overlay" style={{ opacity: 1 }}>
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className="flex_side_right">
                <Sidebar />
                <div className="right_side_sec">

                  <Trendingslider />
                  <div className="right_side_spacing pb-0 pt-4">
                    <div className="text-white mt-0 mt-md-5 pb-4 take_section">

                      <div className="text-white mt-2 mt-md-5 pb-4">
                        <div className="row">
                          {getCms[12]?.identifier == 'Linea’s First Native Launchpad' &&
                            <div className="col-12 col-md-7 mt-5">
                              <h3 className="h3_res">{getCms[12]?.title}</h3>
                              <span className="title_span">{getCms[12]?.content}</span>
                            </div>
                          }
                          <div className="col-12 col-md-5">
                            <div className="position-relative">
                              <div>
                                <div>
                                  <img src={videoIntro} alt="intro" className="img-fluid video_bg_img" />
                                </div>
                                <div
                                  className="videoPlay position-absolute d-flex flex-column align-items-center"
                                >
                                  <button
                                    className="videoPlayButton"
                                    onClick={() => {this.setState({ videoModal: true , videoIntro:this.state.videoIntro })}}
                                  >
                                    <img
                                      src={playButton}
                                      alt="play"
                                      className="img-fluid"
                                    />
                                    <img src={videosquare} className="squareone" />
                                    <img src={videosquaretwo} className="squaretwo" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <br />
                      </center>
                    </div>
                  </div>

                  <div className="mt-0 pt-4 pb-4 conta_toppols">
                    <div className="bg_layer">
                      <div className="right_side_spacing py-5">

                        <div className="row align-items-center">
                          <div className="col-12 col-md-6 col-lg-5">
                            <div className="tab_div">

                              <div className="card card_tab">
                                <div className="card_tab_header">
                                  <div className="d-flex align-items-center justify-content-between">
                                    {getCms[9]?.identifier == 'PremiumLaunchSlide' &&
                                      <div className={this.state.launchval ? "px-3 card_tab_val active val_first" : "px-3 card_tab_val inactive val_second"} onClick={() => this.setState({ launchval: true })}>
                                        <p className="mb-0 text-center">{getCms[9]?.title} {this.state.launchval} </p>
                                      </div>
                                    }

                                    <div className="d-flex align-items-center justify-content-center btn_stop">
                                      <img src={iconstop} className="icon_stop" />
                                    </div>

                                    {getCms[10]?.identifier == 'TokenLaunchSlide' &&
                                      <div className={this.state.launchval ? "px-3 card_tab_val inactive val_first" : "px-3 card_tab_val active val_second"} onClick={() => this.setState({ launchval: false })}>
                                        <p className="mb-0 text-center">{getCms[10]?.title}</p>
                                      </div>
                                    }

                                  </div>
                                </div>
                                <div className="card_tab_content py-5 px-3">
                                  {
                                    this.state.launchval == 1 ?
                                      getCms[9]?.identifier == 'PremiumLaunchSlide' &&
                                      <p className="mb-0 px-3 card_desc_content">
                                        {getCms[9]?.content}
                                      </p>

                                      :
                                      getCms[10]?.identifier == 'TokenLaunchSlide' &&
                                      <p className="mb-0 px-3 card_desc_content">
                                        {getCms[10]?.content}
                                      </p>


                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          {getCms[11]?.identifier == 'Launch Your Project Now' &&
                            <div className="col-12 col-md-6 offset-lg-1 mt-4 mt-md-0">
                              <p className="yellow_text_sml text-md-right">Type of launch</p>
                              <h3 className="h3_res text-uppercase text-md-right">{getCms[11]?.title}</h3>
                              <p className="title_span text-md-right">{getCms[11]?.content}</p>
                              <div className="text-md-right mt-4">
                                {console.log("consulting",this.state.consulting)}
                                {/* <button  onClick={()=>{this.props.history.push(this.state.consulting)}} */}
                                  <a target="_blank" href={this.state.consulting}
                                  className="notchedButton position-relative">
                                  <span className="notchedBg"></span>
                                  <div className="content">Consulting</div>
                                  </a>
                                {/* </button> */}
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="right_side_spacing pb-5">
                      <div className="mt-5 text-white">
                        <center>
                          <h3 className="h3_res">Sale on Flashpad</h3>
                        </center>

                        <div className="mt-5 row">
                          {console.log("getCms_identifier", getCms)}

                          {getCms[0]?.identifier == 'Standard' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/standard_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[0]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[0]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[1]?.identifier == 'Premium Launch' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/premium_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[1]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[1]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[2]?.identifier == 'Deflationary' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/deflationary_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[2]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[2]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[3]?.identifier == 'Customization' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/customization_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[3]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[3]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[4]?.identifier == 'Launchpad' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/launchpad_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[4]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[4]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[5]?.identifier == 'Branding' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/branding_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[5]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[5]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[6]?.identifier == 'Management' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/management_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[6]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[6]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }


                          {getCms[7]?.identifier == 'Community' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/community_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[7]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[7]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }



                          {getCms[8]?.identifier == 'Locking' &&

                            <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                                <div className="d-flex align-items-center">
                                  <div className="card_img_pare_div">
                                    <img className="card-img-top mx-auto" src={require('../../images/locking_icon.png').default} /></div>
                                  <div className="card-title h5 mb-0 ml-4 orbitron_font">{getCms[8]?.title}</div>

                                </div>

                                <div className="text-left  card-body pb-1 px-0 mt-3">
                                  <p className="card-text">{getCms[8]?.content}</p>
                                </div>
                              </div>
                            </div>
                          }



                          {/*                           
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img className="card-img-top mx-auto" src={require('../../images/standard_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Standard</div>

                              </div>

                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Mint standard tokens on Linea.</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="card_bg  card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img className="card-img-top mx-auto" src={require('../../images/premium_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Premium Launch</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Direct support from Flashpad team with a full batch of all services.</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/deflationary_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Deflationary</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Generate deflationary tokens with tax and/or charity functions.</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                            <div className="card_bg  card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/customization_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Customization</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Create a token sale for your own custom token easily.</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">

                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/launchpad_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Launchpad</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Use the token you mint to create a launchpad with just a few clicks</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">


                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">

                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/branding_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Branding</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Adding logo, social links, description, listing on Flashpad</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">

                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/management_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Management</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">The portal to help you easily update content for your launchpad.</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">

                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/community_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Community</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Promote your launchpad to thousands of buyers on Flashpad.</p>
                              </div>
                            </div>
                          </div>
                          <div xxl="4" className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">

                                <div className="card_img_pare_div"><img className="card-img-top mx-auto" src={require('../../images/locking_icon.png').default} /></div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">Locking</div>

                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">Lock your liquidity to Flashpad Swap after presale.</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />

        </div>
        {this.state.videoModal && <Videomodal onDismiss={() => this.setState({ videoModal: false })} getSetting={() => this.getsetting()} videoIntro={this.state.videoIntro}/>}

      </div>
    );
  }
}

export default Landing;
