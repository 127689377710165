import React, { Component } from 'react';
import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import Walletmodal from "../Walletmodal";
import CreatePrivatesaletab from "./CreatePrivatesaletab";
import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import PremiumLaunch from "../premiumLaunch"



class CreatePrivatesaleadmin extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  showLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("loader_div_connect").classList.remove("d-none");
    document.getElementById("loader_div_connect").classList.add("d-block");
  }

  hideLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("loader_div_connect").classList.remove("d-block");
    document.getElementById("loader_div_connect").classList.add("d-none");
  }
  async fetchdata1() {

    if (!localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")) {
      this.showLoaderConnect()
    }
    else {
      this.hideLoaderConnect();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.hideLoader()
    this.fetchdata1()
  }
  onDismiss() {
    this.setState({ walletModal: false });
    if (sessionStorage.getItem("accountInfo")) {
      this.hideLoaderConnect();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: '',

      loaders: false
    };
  }

  render() {
    return (
      <div id="loader_main">
        <div id="loader_div">
          <div className={!this.state.loaders ? 'loader_only' : "d-none"}>
            <span className="spin_round">

            </span>
            <img src={favicon} className="logo_load" />
          </div>
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Adminheader />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec pb-5'>
                  <div id="loader_div_connect">

                    <div className='load_connect_text'>
                      <button className="get-started-btn btn_connect_loader" onClick={() => this.setState({ walletModal: true })}>
                        Connect Wallet to Continue
                      </button>
                    </div>

                  </div>
                  <div className="right_side_spacing">
                    <CreatePrivatesaletab />
                  </div>
                  <PremiumLaunch/>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {this.state.walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}
      </div>
    )
  }
}

export default CreatePrivatesaleadmin