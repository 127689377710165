import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import Kycmodal from "./Kycmodal";

import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import { Tab, Row, Col } from 'react-bootstrap';
import { getallkychook } from '../../hooks/usebackend';

import PremiumLaunch from "../premiumLaunch"


const cookies = new Cookies();

class Adminkyc extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.restrict();
    this.hideLoader();
    this.handlegetallkyc()
  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (!email) {
      this.props.history.push("/login")
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      kycModal: false,
      kycdetails: [],
      singlekyc: {},
      skip: 0,
      limit: 10,
      pagecount: 0,
      button: 0,
      currentpage: 1,
      stepcount: 5
    };
    console.log("props", props)
  }

  handlegetallkyc = async () => {
    let payload = {
      limit: this?.state?.limit,
      skip: this.state.skip
    }
    let data = await getallkychook(payload);
    console.log("data", data?.data?.data?.record);
    this.setState({ "kycdetails": data?.data?.data?.record })
    let counts = Math.ceil(data?.data?.data?.count / 10);
    this.setState({ "pagecount": counts })
  }

  handlenextpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip + this.state.limit
    }
    let data = await getallkychook(payload);
    console.log("data", data?.data?.data?.record);
    this.setState({ "kycdetails": data?.data?.data?.record })
    if (this.state.currentpage % 5 == 0) {
      this.setState({ "button": this.state.button + 5 })
    }
    this.setState({ "skip": this.state.skip + this.state.limit })
    this.setState({ "currentpage": this.state.currentpage + 1 })
  }

  handleprevpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip - this.state.limit
    }
    let data = await getallkychook(payload);
    console.log("data", data?.data?.data?.record);
    this.setState({ "kycdetails": data?.data?.data?.record })
    if ((this.state.currentpage - 1) % 5 == 0) {
      this.setState({ "button": this.state.button - 5 })
    }
    this.setState({ "skip": this.state.skip - this.state.limit });
    this.setState({ "currentpage": this.state.currentpage - 1 });
  }

  handlenumpage = async (val) => {
    this.setState({ "currentpage": parseInt(val) })
    let payload = {
      limit: this.state.limit,
      skip: (parseInt(val) * 10) - 10
    }
    let data = await getallkychook(payload);
    console.log("data", data?.data?.data?.record);
    this.setState({ "kycdetails": data?.data?.data?.record })
    this.setState({ "skip": (parseInt(val) * 10) - 10 })
  }

  onDismiss() {
    this.setState({ kycModal: false });
    this.handlegetallkyc()
  }


  render() {


    const location = this.props.location.pathname.split('/')[1];

    const { kycModal } = this.state

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">

          <div className="whole_bg">

            <Adminheader />
            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec pb-5'>
                  <div className="right_side_spacing">
                    <div className='row my-5 row_ppos'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='bg_layer'>
                            <div className='card-body'>
                              <p className='overlay_card_head mb-0 mt-2'>KYC List</p>
                              <div className="tab_par px-0 py-3">
                                <Tab.Container defaultActiveKey="second">
                                  <Row className="container-fluid w-100 mx-0 px-0">
                                    <Col lg={12} className="img_center_lg px-0">
                                      <div className='container container_custom px-0'>
                                        <Tab.Content className='min_heighttab_cont'>
                                          <Tab.Pane eventKey="second">
                                            <div class="table-responsive">
                                              {!this?.state?.kycdetails.length == 0 &&
                                                <table className="table recepients_table">
                                                  <thead>
                                                    <tr>
                                                      <th>User Wallet Address</th>
                                                      <th>Status</th>
                                                    </tr>
                                                  </thead>
                                                  {this?.state?.kycdetails && this?.state?.kycdetails?.map((item, i) => <>
                                                    <tr>
                                                      <td>


                                                        <div className="p-0 table_det">
                                                          <span className="table_text">{item?.walletaddress}</span>

                                                        </div>
                                                      </td>
                                                      <td>{item?.proof[0]?.status ? item?.proof[0]?.status : "No KYC"}</td>
                                                      <td className="d-flex justify-content-end">{item?.proof[0] && <a className="link_text" onClick={() => {
                                                        this.setState({ kycModal: true });
                                                        this.setState({ "singlekyc": item })
                                                      }}>View</a>}</td>

                                                    </tr>
                                                  </>)}

                                                </table>}
                                            </div>
                                            {!this?.state?.kycdetails.length == 0 ?
                                              <div className="mt-2 d-flex justify-content-center align-items-center btn_pagin_wrap">
                                                <button className="pagination_btn"
                                                  onClick={this.handleprevpage}
                                                  disabled={this.state.currentpage == 1}
                                                ><i class="fa fa-angle-left" aria-hidden="true"
                                                ></i></button>

                                                {[...Array(this.state.stepcount)].map((val, i) => {
                                                  if ((this.state.button + i + 1) <= this.state.pagecount)
                                                    return (
                                                      <button className={this.state.currentpage == (this.state.button + i + 1) ? "pagination_btn active" : "pagination_btn"} value={this.state.button + i + 1} onClick={
                                                        (e) => {
                                                          console.log("e", e?.target?.value);
                                                          this.handlenumpage(e?.target?.value)
                                                        }
                                                      }>{this.state.button + i + 1}</button>
                                                    )
                                                }
                                                )}

                                                <button className="pagination_btn"
                                                  onClick={this.handlenextpage}
                                                  disabled={this.state.currentpage == this.state.pagecount}
                                                ><i class="fa fa-angle-right" aria-hidden="true"

                                                ></i></button>
                                              </div> : <p className='no_data_text'>No Data Found</p>}
                                          </Tab.Pane>


                                        </Tab.Content>
                                      </div>
                                    </Col>
                                  </Row>
                                </Tab.Container>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <PremiumLaunch/>
                </div>
              </div>
            </div>
          </div>
          <Footer />

          {kycModal && <Kycmodal detail={this.state.singlekyc} onDismiss={() => this.onDismiss()} />}
        </div>
      </div>
    )
  }
}

export default Adminkyc