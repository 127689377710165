import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import linea from "../../images/linea.png";
import Settingsmodal from "../Settingsmodal";
import { NavLink } from "react-router-dom";
import { logouthook } from '../../hooks/usebackend';
import Walletmodal from '../Walletmodal';
import { getProxyOwner } from '../../hooks/useContract';
import { UseProvider } from '../../hooks/useWeb3';
import { getChainId } from '../../hooks/useAccount';
import { CHAINS } from '../../config/env';


class Adminheader extends Component {

    showMobile() {
        document.getElementById("mobile_nav_item").classList.toggle("left_stye");
        document.getElementById("mobileLayer").classList.toggle('active');
        document.getElementById("burger").classList.toggle('clicked');
        document.getElementById("burger").classList.toggle("burger_stye")

    }

    constructor(props) {
        super(props);
        this.state = {
            location: false,

            currentChain: 0,
            settingsModal: false,
            accountInfo: localStorage.getItem("accountInfo"),
            walletModal: false
        }
    }
    onDismiss() {
        this.setState({ walletModal: false });
    }
    componentDidMount() {
        console.log("chains", localStorage.getItem("CHAIN"), CHAINS.length);

        if (!localStorage.getItem("CHAIN") || localStorage.getItem("CHAIN") > CHAINS.length - 1) {
            localStorage.setItem("CHAIN", 0)
        }
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 1) {
                document.getElementById("header").classList.add('header-scrolled');

            }
            else {
                document.getElementById("header").classList.remove('header-scrolled');

            }
        });
        this.setState({ currentChain: getChainId() });
        this.getOwner();
    }

    getOwner = async () => {
        const owner = await getProxyOwner();
        this.setState({ proxyOwner: owner });
        console.log("owner : ", owner)
    }

    handlelogout = async () => {
        await logouthook();
        window.location.href = window.location.origin + "/login";
        localStorage.removeItem("accountInfo")
        if (localStorage.getItem("walletconnect") != null) {
            const provider = await UseProvider();
            await provider.disconnect()
        }
    }


    render() {
        const { location } = this.props;
        const { walletModal, settingsModal } = this.state



        return (


            <div>


                <div id="mobileLayer">
                    <div id="header" className="fixed-top-navbar d-none d-md-block">
                        <div className="container-fluid" id="mobileshow">
                            <header className={`${location} py-2`}>
                                <div className='d-flex headerleft'>
                                    <NavLink to="/adminlaunchpad" className='mr-lg-5'><span className='logo_img_sm' /></NavLink>
                                </div>


                                <div className='d-block d-lg-block web_menu'>
                                    <div className="d-flex align-items-center justify-content-between py-0 header_flex header_flex_new_ad">
                                        <a className="mr-3 d-none d-lg-block"
                                        >
                                            <img src={linea} className='img-fluid img_linea' />
                                        </a>


                                        <div className='d-flex align-items-center nav_parnt'>

                                            <div className='nav_parnt_2'>
                                                <div className="nav-menu">
                                                    <ul className="pl-0 mb-0">

                                                        <Dropdown className='filter_dropdown mr-3'>
                                                            <Dropdown.Toggle id="dropdown-basic" className='table_filter_icon'>
                                                                <button className="get-started-btn rounded_btn_wal">
                                                                    <i class="fa fa-user-o" aria-hidden="true"></i>
                                                                </button>

                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='table_dropdown_menu'>
                                                                <Dropdown.Item className='link_text_gren' onClick={this.handlelogout}>



                                                                    <Link to="/login" className='link_text' onClick={this.handlelogout}>Logout</Link>
                                                                </Dropdown.Item>

                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                        {(this.state.accountInfo == "" || this.state.accountInfo == null) ?

                                                            <button
                                                                className="notchedButton position-relative" onClick={() => this.setState({ walletModal: true })}
                                                            >
                                                                <span className="notchedBg"></span>
                                                                <div className="content">Connect Wallet</div>
                                                            </button>

                                                            :

                                                            <button
                                                                className="notchedButton position-relative" onClick={() => {
                                                                    this.setState({ accountInfo: "" });
                                                                    localStorage.clear();
                                                                    sessionStorage.clear();
                                                                }}
                                                            >
                                                                <span className="notchedBg"></span>
                                                                <div className="content">{`${this.state.accountInfo.substring(0, 5)}...${this.state.accountInfo.substring(38, 42)}`}</div>
                                                            </button>

                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </header>
                        </div>
                    </div>

                    <div id="header1" className="fixed-top-navbar d-block d-md-none">
                        <div className="container-fluid  h-100" id="mobileshow1">
                            <header className="h-100">
                                <Link to="/" className='mr-3 logo_mob_mar'><span className='logo_img_sm' /></Link>




                                <div className='d-flex align-items-center'>
                                    <a className="mr-3 link_wallet_new d-none d-lg-block" href="javascript:void(0)">
                                        <img src={linea} className='img-fluid img_linea ' />
                                    </a>

                                    <div className=''>
                                        <div className="nav-menu">
                                            <ul className="pl-0 mb-0">

                                                <Dropdown className='filter_dropdown mr-2'>
                                                    <Dropdown.Toggle id="dropdown-basic" className='table_filter_icon'>
                                                        <button className="get-started-btn rounded_btn_wal">
                                                            <i class="fa fa-user-o" aria-hidden="true"></i>
                                                        </button>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='table_dropdown_menu'>
                                                        <Dropdown.Item className='link_text_gren'>



                                                            <Link to="/login" className='link_text' >Logout</Link>
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                {(this.state.accountInfo == "" || this.state.accountInfo == null) ?

                                                    <button
                                                        className="notchedButton position-relative" onClick={() => this.setState({ walletModal: true })}
                                                    >
                                                        <span className="notchedBg"></span>
                                                        <div className="content">Connect Wallet</div>
                                                    </button>


                                                    :

                                                    <button
                                                        className="notchedButton position-relative" onClick={() => {
                                                            this.setState({ accountInfo: "" });
                                                            localStorage.clear();
                                                            sessionStorage.clear();
                                                        }}
                                                    >
                                                        <span className="notchedBg"></span>
                                                        <div className="content">{`${this.state.accountInfo.substring(0, 5)}...${this.state.accountInfo.substring(38, 42)}`}</div>
                                                    </button>



                                                }

                                            </ul>
                                        </div>

                                    </div>
                                    {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}
                                    {settingsModal && <Settingsmodal onSet={() => this.setState({ currentChain: getChainId() })} onDismiss={() => this.setState({ settingsModal: false })} connect={"string"} />}

                                </div>



                            </header>
                        </div>
                    </div>

                </div>


            </div>
        );
    }
}



export default Adminheader