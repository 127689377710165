import React, { Component } from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Trendingslider from '../trendingslider';
import toast from 'react-hot-toast';
import { getAccount, Userdollar, getChainId } from '../../hooks/useAccount'
import { FormControl, InputGroup, Nav, Tab, Row, Col } from 'react-bootstrap';
import '../../css/styles.css';
import logo_curve from "../../images/Bitdeal/logo_curve.png"
import { CURRENT_CHAIN_ID, UseProvider } from '../../hooks/useWeb3';
import { Getbalance } from '../../hooks/useContract';
import { getuserdatahook } from '../../hooks/usebackend';
import { getsaledatalimit } from '../../hooks/useProjects';
import { iconTheme, position, style } from '../../hooks/useToast';
import { CHAINS } from "../../config/env";
import PremiumLaunch from "../premiumLaunch"

class Wallethome extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


  componentDidMount() {
    var account = getAccount();
    if (!account) {
      this.props.history.push("/");
    }
    this.hideLoader();
    this.getbalance();
    this.getuserdata();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  copyText(a, b) {
    toast.success("Address Copied", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    }
    )
  }

  copyUrl(a, b) {
    toast.success("Profile Url Copied!", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    }
    )
  }


  makeTimercopyaddress() {
    setInterval(() => {
      this.setState({ copyaddress: "Copy" })
    }, 5000)
  }
  makeTimercopyurl() {
    setInterval(() => {
      this.setState({ copyurl: "Copy" })
    }, 5000)
  }

  getbalance = async () => {
    const balance = await Getbalance();
    this.setState({ "balance": balance })
  }

  getuserdata = async () => {
    let userdata = await getuserdatahook(this.state.accountInfo.toLowerCase());
    console.log("userdata", userdata?.data?.data);
    const chainid = CURRENT_CHAIN_ID();
    let investedpools = userdata?.data?.data?.investedpools
   let viewlist = userdata?.data?.data?.viewlist.filter((val) => {
    console.log(val,'viewlist')
      return chainid == val.chainid
    })
    console.log("viewlist",viewlist, userdata?.data?.data,viewlist,chainid);
        // let investedpools = userdata?.data?.data?.investedpools.filter((val) => {
    //   return chainid == val.chainid
    // })
    // console.log("userdata", userdata?.data?.data,investedpools);
    this.setState({ investedpools: investedpools.reverse() })
    this.setState({ wishlist: userdata?.data?.data?.wishlist.reverse() })
    this.setState({ viewlist:viewlist.reverse() })
    this.setState({ investedamount: userdata?.data?.data?.investedamount })
    let dollar = await Userdollar(userdata?.data?.data?.investedamount);
    this.setState({ dollar: Math.round(dollar * 100) / 100 });
    this.handleactivities();
  }

  logOut = async () => {
    this.setState({ accountInfo: "" })
    localStorage.removeItem("accountInfo")
    if (localStorage.getItem("walletconnect") != null) {
      const provider = await UseProvider();
      await provider.disconnect()
    }
    this.props.history.push("/");
    window.location.reload()
    console.log("logout")
  }

  handleactivities = async () => {
    this.setState({ totalsales: [] });
    this.loadData(this.state.investedpools);
  }

  handlefav = async () => {
    this.setState({ totalsales: [] });
    this.loadData(this.state.wishlist);

  }

  handleview = async () => {
    console.log("recently view");
    this.setState({ totalsales: [] });

    this.loadData(this.state.viewlist);
  }

  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: getAccount() ? getAccount() : "",
      logined: true,
      balance: "",
      showTooltip: false,
      showTooltipOne: false,
      copyaddress: "Copy",
      copyurl: "Copy",

      wishlist: [],
      viewlist: [],
      investedpools: [],
      investedamount: "",
      interval: 0,
      loadinterval: 3,
      totalsales: [],
      loading: false,
      dollar: 0
    };
  }

  async loadData(Proxydata) {
    this.showLoader();
    if (Proxydata.length > 0) {
      const newly = await getsaledatalimit(Proxydata, 0, this.state.loadinterval);
      console.log("newly", newly, Proxydata);
      this.setState({ totalsales: newly });
      this.setState({ interval: this.state.loadinterval })
    }
    else {
      this.setState({ totalsales: [] });
    }
    this.hideLoader();
  }

  async viewmore(Proxydata) {
    this.setState({ loading: true });
    if (Proxydata.length > 0) {
      const newly = await getsaledatalimit(Proxydata, this.state.interval, this.state.interval + this.state.loadinterval);
      await this?.sleep(1000)
      console.log("newly", newly);
      const total = this.state.totalsales.concat(newly);
      console.log("total", total);
      this.setState({ totalsales: total });
      this.setState({ interval: this.state.interval + this.state.loadinterval })
      this.setState({ loading: false });
    }
    else {
      this.setState({ totalsales: [] });
      this.setState({ loading: false });
    }
  }

  render() {




    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={logo_curve} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec pb-5'>

                  <Trendingslider />
                  <div className="right_side_spacing">
                    <div className='container'>
                      <div className='row my-5'>
                        <div className='col-12 mx-auto px-0'>
                          <div className="tab_img">
                            <div className='bg_layer'>
                              <div className='card-body pos_z_body_car'>
                                <p className='overlay_card_head'>My Wallet</p>

                                <p className='wallet_address_text mt-4'>Connected as
                                  <span>{localStorage.getItem("accountInfo")}</span>
                                  <CopyToClipboard text={`${localStorage.getItem("accountInfo")}`} onCopy={() => this.copyText('invite link', `${localStorage.getItem("accountInfo")}`)}>
                                    <button variant='link' className='fa fa-copy' style={{ backgroundColor: "#04030e", border: 0 }}></button>
                                  </CopyToClipboard>

                                </p>
                                <p className='text-white'>{this.state.balance ? this.state.balance + CHAINS[getChainId()].SYMBOL : ""}</p>


                                <p className='input_desc_sm'>Profile URL</p>
                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                  <InputGroup className="datepicker_input">
                                    {this.state.accountInfo && <FormControl id="profile_url" placeholder="https://www.demo.com/user-achievement/u33CedE"
                                      aria-describedby="basic-addon2"
                                      value={window.location.origin + "/home/" + this.state.accountInfo.toString().toLowerCase()}
                                    />}

                                    <InputGroup.Append className='cur_pointer'>
                                      <CopyToClipboard text={`${window.location.origin + "/home/" + this.state.accountInfo.toString().toLowerCase()}`} onCopy={() => this.copyUrl('invite link', `${window.location.origin + "/achievement/" + this.state.accountInfo.toString().toLowerCase()}`)}>
                                        <button variant='link' className='fa fa-copy' style={{ backgroundColor: "#04030e", border: 0, color: "#848484" }}></button>
                                      </CopyToClipboard>
                                    </InputGroup.Append>



                                  </InputGroup>
                                </div>
                                <div className='text-center'>
                                  <button className="get-started-btn orbitron_font btn_min_wid my-3" onClick={this.logOut}>
                                    Logout
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>
                    <div className='bg_layer'>
                      <div className='card-body pos_z_body_car'>
                        <p className='overlay_card_head'>My History</p>
                        <div>
                          <div className="inner_bg mt-4 pb-0">
                            <div className="container px-1">
                              <Tab.Container defaultActiveKey="first">
                                <Row className="container-fluid w-100 mx-0 px-0">
                                  <Col lg={12} className="px-0">
                                    <Nav variant="pills" className="nav_green">
                                      <Nav.Item>
                                        <Nav.Link eventKey="first" id="first">
                                          <p className='mb-0' onClick={() => this.handleactivities()}>Activities</p>

                                        </Nav.Link>
                                      </Nav.Item>

                                      {/* <Nav.Item>
                                        <Nav.Link eventKey="second" id="second">
                                          <p className='mb-0' onClick={() => this.handlefav()}>Favorited</p>

                                        </Nav.Link>

                                      </Nav.Item> */}

                                      <Nav.Item>
                                        <Nav.Link eventKey="third" id="third">
                                          <p className='mb-0' onClick={() => this.handleview()}>Recently Viewed</p>

                                        </Nav.Link>

                                      </Nav.Item>

                                    </Nav>
                                  </Col>

                                  <Col lg={12} className="img_center_lg px-0">
                                    <div className='container container_custom px-0'>
                                      <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                          {this.state.logined ?
                                            <div>
                                              <div className='card_bg_steps mt-4'>
                                                <div className='card-body'>
                                                  <div className='row row_invested_sec'>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total Pool Invested</p>
                                                      <p className='text-white font_18 mb-0'>{this?.state?.investedpools?.length}</p>

                                                    </div>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total {CHAINS[getChainId()].SYMBOL} Invested</p>
                                                      <p className='text-white font_18 mb-0'>{parseFloat(this?.state?.investedamount).toFixed(2)}</p>

                                                    </div>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total Invested</p>
                                                      <p className='text-white font_18 mb-0'>${parseFloat(this?.state?.dollar).toFixed(2)}</p>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="table-responsive table_repso_with_dropdwon mt-3">
                                                <table className="table">
                                                  <tr>
                                                    <th>
                                                      <p>Name</p>
                                                    </th>
                                                    <th>
                                                      <p>Sale type</p>
                                                    </th>
                                                    <th>
                                                      <p>Sale Address</p>
                                                    </th>
                                                    <th>
                                                      <p>Action</p>
                                                    </th>



                                                  </tr>
                                                  {this.state.totalsales?.length > 0 ?

                                                    this.state.totalsales?.map((data, i) => <>
                                                      <tr>
                                                        <td><span className="d-flex align-items-center">
                                                          <div className="relative_token_div  mr-2">
                                                            <img className="token_image_sm " src={data?.logo} />
                                                          </div> {data?.name}
                                                        </span></td>
                                                        <td>{data?.isWhitelisted ? "Whitelist sale" : "public sale"}</td>
                                                        <td>{data?.saleAddress}</td>
                                                        <td><a className="link_text" href={data?.isWhitelisted ? `/privatesaledetail/${data?.saleAddress}` : `/launchpaddetail/${data?.saleAddress}`}>View</a></td>
                                                      </tr></>) :
                                                    <tr>
                                                      <td colSpan={4}>
                                                        <div className='row'>
                                                          <div className='col-12'>
                                                            <p className='text-center mt-3 tetx_yellow_no'>No data found</p>

                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  }

                                                </table>
                                              </div>
                                              <div className='text-center mt-3'>
                                                {this.state.totalsales?.length < this.state.investedpools?.length ?
                                                  <button className="get-started-btn" id="load_more_btn_1"
                                                    onClick={() => this.viewmore(this.state.investedpools)}
                                                  >{this.state.loading ? "Loading..." : "View More"}</button>
                                                  : <></>
                                                }

                                              </div>
                                            </div>
                                            :
                                            <div className='text-center'>
                                              <p className='text-white'>We are protecting your activity. You need to sign to see it.</p>
                                              <button className="get-started-btn">
                                                Sign in
                                              </button>
                                            </div>
                                          }

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">

                                          {this.state.logined ?
                                            <div>
                                              <div className='card_bg_steps mt-4'>
                                                <div className='card-body'>
                                                  <div className='row row_invested_sec'>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total Pool Invested</p>
                                                      <p className='text-white font_18 mb-0'>{this?.state?.investedpools?.length}</p>
                                                      {console.log("this?.state?.investedpools", this?.state?.investedpools)}

                                                    </div>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total {CHAINS[getChainId()].SYMBOL} Invested</p>
                                                      <p className='text-white font_18 mb-0'>{parseFloat(this?.state?.investedamount).toFixed(2)}</p>

                                                    </div>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total Invested</p>
                                                      <p className='text-white font_18 mb-0'>${parseFloat(this?.state?.dollar).toFixed(2)}</p>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="table-responsive table_repso_with_dropdwon mt-3">
                                                <table className="table">
                                                  <tr>
                                                    <th>
                                                      <p>Name</p>
                                                    </th>
                                                    <th>
                                                      <p>Saletype</p>
                                                    </th>
                                                    <th>
                                                      <p>Sale Address</p>
                                                    </th>

                                                    <th>
                                                      <p>Action</p>
                                                    </th>

                                                  </tr>
                                                  {this.state.totalsales.length > 0 ?

                                                    this.state.totalsales && this.state.totalsales.map((data, i) => <>
                                                      {console.log("total sales", this.state.totalsales)}
                                                      <tr>
                                                        <td><span className="d-flex align-items-center">
                                                          <div className="relative_token_div  mr-2">
                                                            <img className="token_image_sm " src={data?.logo} />
                                                          </div> {data?.name}
                                                        </span></td>
                                                        <td>{data?.isWhitelisted ? "Whitelist sale" : "public sale"}</td>
                                                        <td>{data?.saleAddress}</td>
                                                        <td><a className="link_text" href={data?.isWhitelisted ? `/privatesaledetail/${data?.saleAddress}` : `/launchpaddetail/${data?.saleAddress}`}>View</a></td>
                                                      </tr>
                                                    </>) :
                                                    <tr>
                                                      <td colSpan={4}>
                                                        <div className='row'>
                                                          <div className='col-12'>
                                                            <p className='text-center mt-3 tetx_yellow_no'>No data found</p>

                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  }
                                                </table>
                                              </div>
                                              <div className='text-center mt-3'>
                                                {this.state.totalsales?.length < this.state.wishlist?.length ?
                                                  <button className="get-started-btn" id="load_more_btn_1"
                                                    onClick={() => this.viewmore(this.state.wishlist)}
                                                  >{this.state.loading ? "Loading..." : "View More"}</button>
                                                  : <></>
                                                }

                                              </div>
                                            </div>
                                            :
                                            <div className='text-center'>
                                              <p className='text-white'>We are protecting your activity. You need to sign to see it.</p>
                                              <button className="get-started-btn">
                                                Sign in
                                              </button>
                                            </div>
                                          }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                          {this.state.logined ?
                                            <div>
                                              <div className='card_bg_steps mt-4'>
                                                <div className='card-body'>
                                                  <div className='row row_invested_sec'>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total Pool Invested</p>
                                                      <p className='text-white font_18 mb-0'>{this?.state?.investedpools?.length}</p>

                                                    </div>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total {CHAINS[getChainId()].SYMBOL} Invested</p>
                                                      <p className='text-white font_18 mb-0'>{parseFloat(this?.state?.investedamount).toFixed(2)}</p>

                                                    </div>
                                                    <div className='col-12 col-md-4 text-center'>
                                                      <p className='pool_detail_title'>Total Invested</p>
                                                      <p className='text-white font_18 mb-0'>${parseFloat(this?.state?.dollar).toFixed(2)}</p>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="table-responsive table_repso_with_dropdwon mt-3">
                                                <table className="table">
                                                  <tr>
                                                    <th>
                                                      <p>Name</p>
                                                    </th>
                                                    <th>
                                                      <p>Saletype</p>
                                                    </th>
                                                    <th>
                                                      <p>Sale Address</p>
                                                    </th>

                                                    <th>
                                                      <p>Action</p>
                                                    </th>

                                                  </tr>
                                                  {this.state.totalsales.length > 0 ?

                                                    this.state.totalsales.map((data, i) => <>
                                                      <tr>
                                                        <td><span className="d-flex align-items-center">
                                                          <div className="relative_token_div  mr-2">
                                                            <img className="token_image_sm " src={data?.logo} />
                                                          </div> {data?.name}
                                                        </span></td>
                                                        <td>{data?.isWhitelisted ? "Whitelist sale" : "public sale"}</td>
                                                        <td>{data?.saleAddress}</td>
                                                        <td><a className="link_text" href={data?.isWhitelisted ? `/privatesaledetail/${data?.saleAddress}` : `/launchpaddetail/${data?.saleAddress}`}>View</a></td>
                                                      </tr>
                                                    </>) :
                                                    <tr>
                                                      <td colSpan={4}>
                                                        <div className='row'>
                                                          <div className='col-12'>
                                                            <p className='text-center mt-3 tetx_yellow_no'>No data found</p>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  }
                                                </table>
                                              </div>
                                              <div className='text-center mt-3'>
                                                {this.state.totalsales?.length < this.state.viewlist?.length ?
                                                  <button className="get-started-btn" id="load_more_btn_1"
                                                    onClick={() => this.viewmore(this.state.viewlist)}
                                                  >{this.state.loading ? "Loading..." : "View More"}</button>
                                                  : <></>
                                                }
                                              </div>
                                            </div>
                                            :
                                            <div className='text-center'>
                                              <p className='text-white'>We are protecting your activity. You need to sign to see it.</p>
                                              <button className="get-started-btn">
                                                Sign in
                                              </button>
                                            </div>
                                          }
                                        </Tab.Pane>

                                      </Tab.Content>
                                    </div>
                                  </Col>
                                </Row>
                              </Tab.Container>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <PremiumLaunch />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Wallethome