import React, { Component } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Trendingslider from '../trendingslider';
import { getAccount } from '../../hooks/useAccount'
import { Container, FormControl, InputGroup } from 'react-bootstrap';
import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import { Link } from 'react-router-dom';
import { getSaleInfoCard } from '../../hooks/useContract';
import { updateTokenInfo } from '../../hooks/useAdmin';
import isEmpty from 'is-empty';
import { urlvalidation } from '../../hooks/kycvalidation';
import { useHistory } from "react-router-dom";
import PremiumLaunch from "../premiumLaunch"


class LaunchpadEdit extends Component {


    showLoader() {
        document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
        document.getElementById("logo_overlay").style.opacity = 0.05;
        document.getElementById("loader_div").classList.remove("d-none");
        document.getElementById("loader_div").classList.add("d-block");
    }

    hideLoader() {
        document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
        document.getElementById("logo_overlay").style.opacity = 1;
        document.getElementById("loader_div").classList.remove("d-block");
        document.getElementById("loader_div").classList.add("d-none");

    }



    constructor(props) {
        super(props);
        this.state = {
            accountInfo: '',
            saleAddress: window.location.pathname.split('/')[2],
            saleInfo: {},
            logo: '',
            description: '',
            website: '',
            twitter: '',
            telegram: '',
            githup: '',
            instagram: '',
            discord: '',
            reddit: '',
            youtube: '',
            banner: '',
            errors: {},

        };
    }


    async Getdata() {
        const saleDetail = await getSaleInfoCard(this.state.saleAddress);
        console.log("Getdata_edit", saleDetail, window.location.pathname.split('/')[2])
        this.setState({ saleInfo: saleDetail });
        this.setState({ logo: saleDetail?.logo })
        this.setState({ description: saleDetail?.description })
        this.setState({ banner: saleDetail?.banner })
        this.setState({ website: saleDetail?.social[0] })
        this.setState({ twitter: saleDetail?.social[1] })
        this.setState({ telegram: saleDetail?.social[2] })
        this.setState({ githup: saleDetail?.social[5] })
        this.setState({ instagram: saleDetail?.social[6] })
        this.setState({ discord: saleDetail?.social[7] })
        this.setState({ reddit: saleDetail?.social[8] })
        this.setState({ youtube: saleDetail?.social[9] })
        this.hideLoader();

    }


    setSocialInfo = async () => {

        const account = getAccount();
        var data = {
            description: this.state.description,
            banner: this.state.banner,
            website: this.state.website,
            twitter: this.state.twitter,
            logo: this.state.logo,
            telegram: this.state.telegram,
            githup: this.state.githup,
            instagram: this.state.instagram,
            discord: this.state.discord,
            reddit: this.state.reddit,
            youtube: this.state.youtube
        }
        console.log("data")
        var update = await updateTokenInfo(data, this.state?.saleAddress, account)
        console.log("update", update)
        this.props.history.push(`/launchpaddetail/${this.state.saleAddress}`)

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.getElementById("launchpad_parent").classList.add("active");
        this.Getdata()
    }


    socialMediaValidations = () => {
        var errors = {}

        if (isEmpty(this.state.description)) {
            errors.description = "Description field is required"
        }

        if (isEmpty(this.state.banner)) {
            errors.banner = "Banner field is required"
        }

        // if (isEmpty(this.state.facebook)) {
        //     errors.facebook = "Facebook field is required"
        // }

        if (isEmpty(this.state.twitter)) {
            errors.twitter = "Twitter field is required"
        }

        if (isEmpty(this.state.githup)) {
            errors.githup = "Github field is required"
        }

        if (isEmpty(this.state.telegram)) {
            errors.telegram = "Telegram field is required"
        }

        if (isEmpty(this.state.instagram)) {
            errors.instagram = "Instagram field is required"
        }

        if (isEmpty(this.state.discord)) {
            errors.discord = "Discord field is required"
        }

        if (isEmpty(this.state.reddit)) {
            errors.reddit = "Reddit field is required"
        }

        if (isEmpty(this.state.youtube)) {
            errors.youtube = "Youtube field is required"
        }

        if (isEmpty(this.state.website)) {
            errors.website = "Website field is required"
        }

        if (isEmpty(this.state.logo)) {
            errors.logo = "Logo field is required"
        }

        this.setState({ errors: errors })
        return errors
    }


    render() {

        const location = this.props.location.pathname.split('/')[1];

        const { walletModal, saleInfo } = this.state



        return (
            <div id="loader_main">
                <div id="loader_div">
                    <span className="spin_round">

                    </span>
                    <img src={favicon} className="logo_load" />
                </div>
                <div className='logo_overlay' id="logo_overlay">
                    <div className="whole_bg">
                        <Header />

                        <div className="whole_sec pb-5">
                            <div className='flex_side_right'>
                                <Sidebar />
                                <div className='right_side_sec'>

                                    <Trendingslider />
                                    <div className="right_side_spacing">
                                        <Container className="mt-5 pb-3 px-1">
                                            <div className='col-12  col-md-12 col-lg-12 mx-auto px-0'>

                                                <div className="tab_img">
                                                    <div className='card_grad_new card'>
                                                        <div className='card-body'>

                                                            <p className='overlay_card_head text-blak pb-3'>Edit Launchpad ({saleInfo?.symbol})</p>


                                                            <div>
                                                                <div className='row'>


                                                                    <div className='col-12 col-md-12 mb-0'>

                                                                        <p className='input_desc_sm'>Logo URL</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="file" placeholder="Enter logo Url"
                                                                                    value={this.state.logo}
                                                                                    aria-describedby="basic-addon2"
                                                                                    onChange={(e) => {
                                                                                        this.setState({ logo: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["logo"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["logo"]: "Invalid Logo Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.logo}</span>
                                                                        </div>
                                                                        <div className='note_desc mt-1 mb-0'>
                                                                            <p>URL must be end with a supported image extension .png, .jpg,</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-0'>

                                                                        <p className='input_desc_sm'>Banner URL</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="file1" placeholder="Enter Banner Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.banner}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ banner: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["banner"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["banner"]: "Invalid Banner Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.banner}</span>
                                                                        </div>
                                                                        <div className='note_desc mt-1 mb-0'>
                                                                            <p>URL must be end with a supported image extension .png, .jpg,</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Website</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">

                                                                                <FormControl placeholder="Enter Website Url" id="logolink1"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.website}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ website: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["website"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["website"]: "Invalid Website Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.website}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Twitter</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl placeholder="Enter Twitter Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.twitter}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ twitter: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["twitter"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["twitter"]: "Invalid Twitter Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.twitter}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Telegram</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl placeholder="Enter Telegram Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.telegram}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ telegram: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["telegram"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["telegram"]: "Invalid Telegram Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.telegram}</span>
                                                                        </div>
                                                                    </div>




                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Github</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="logolinkgit" placeholder="Enter Github Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.githup}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ "githup": e.target.value })
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["githup"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["githup"]: "Invalid Github Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.githup}</span>
                                                                        </div>
                                                                    </div>



                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Instagram</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="logolinkinsta" placeholder="Enter Instagram Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.instagram}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ instagram: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["instagram"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["instagram"]: "Invalid Instagram Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.instagram}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Discord</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="logolinkdiscord" placeholder="Enter Discord Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.discord}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ discord: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["discord"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["discord"]: "Invalid Discord Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.discord}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Reddit</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="logolinkreddit" placeholder="Enter Reddit Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.reddit}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ reddit: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["reddit"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["reddit"]: "Invalid Reddit Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.reddit}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Youtube Video</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="logolinkyoutube" placeholder="Enter Youtube Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.youtube}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ youtube: e.target.value });
                                                                                        if (urlvalidation(e.target.value)) {
                                                                                            const formvalue = { ...this.state.errors, ["youtube"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["youtube"]: "Invalid Youtube Video Url!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.youtube}</span>
                                                                        </div>

                                                                        {/* <div className='note_desc mt-1 mb-0'>
                                                                            <p>Input your youtube URL</p>
                                                                        </div> */}
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Description</p>
                                                                        <div className="inputs input-groups text_are_grp">
                                                                            <InputGroup className="">
                                                                                {console.log("sal", saleInfo)}
                                                                                <textarea placeholder="Enter Description" id="description" rows="3"
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={this.state.description}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ description: e.target.value });
                                                                                        if (!e.target.value) {
                                                                                            const formvalue = { ...this.state.errors, ["description"]: "Invalid Description!" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["description"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.description}</span>
                                                                        </div>
                                                                    </div>




                                                                    <div className='col-12 col-md-12 text-center'>
                                                                        <Link to="/launchpaddetail" className="btn_black_outline mt-2 mr-2">
                                                                            Back
                                                                        </Link>
                                                                        <button onClick={() => {
                                                                            let isvalid = this.socialMediaValidations()
                                                                            console.log("socialMediaValidations", isvalid)
                                                                            if (isEmpty(isvalid)) {
                                                                                this.setSocialInfo();
                                                                            }
                                                                        }} className="btn_black mt-2">
                                                                            Update
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                    <PremiumLaunch/>

                                </div>


                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>


        )
    }
}

export default LaunchpadEdit