import React, { Component } from 'react';
import {getCmsList, getFrontsettinghook } from "../hooks/usebackend";

class PremiumLaunch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getCms: [],
            setting: [],
            contactAgent:'',
            LearnPremium:''
        }
    }


    componentDidMount() {
        this.getCmsSettings()
        this.getsetting()
      }


    getCmsSettings = async () => {

        let get = await getCmsList();
        console.log("getCms", get, get.status);
        if (get?.status == true) {
          this.setState({ "getCms": get?.result });
          console.log("getCms_getCms", get?.result)
        }
      }



  async getsetting() {
    let data = await getFrontsettinghook();
    console.log("data setting", data, data?.data?.data);
    console.log("Array", typeof data?.data?.data == 'Array', typeof data?.data?.data);
    this.setState({ setting: data?.data?.data })
    typeof data?.data?.data == 'object' && data?.data?.data?.length > 0 && data?.data?.data?.map((val, i) => {
        console.log("val", val)
        if (val.settingname == "contactAgent" || val.settingname == "ContactAgent") {
            this.setState({ contactAgent: val.settingvalue });
        }
        if (val.settingname == "learnPremium" || val.settingname == "LearnPremium") {
            this.setState({ LearnPremium: val.settingvalue });
        }

    })
}
    

    render() {



        return (

            <div className="bg_layer mb-5">
            <div className="right_side_spacing py-5">
            {this.state.getCms[13]?.identifier == "Premium Launch Order" &&
              <div className="row">

                <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                  <h3 className="h3_res">{this.state.getCms[13]?.title}</h3>
                  <h5 className="h5_res">All in one package </h5>
                  <div className='mt-4 pb-4 div_btn_ful_width'>
                    <a target="_blank" href={this.state.contactAgent} className='get-started-btn-border orbitron_font ml-3' >
                      Contact Agent
                    </a>
                    <a target="_blank" href={this.state.LearnPremium}  className='get-started-btn-border orbitron_font ml-3'>
                      Learn Premium
                    </a>
                  </div>

                </div>
                
                <div className="col-12 col-md-5 col-xl-6">
                  <p className="title_span mt-4">{this.state.getCms[13]?.content}</p>

                </div>
              </div>
    }
            </div>
          </div>

        )
    }

}


export default PremiumLaunch