import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';


import medium from "../images/medium_footer.svg";
import telegram from "../images/telegram_footer.svg";
import twitter from "../images/twitter_footer.svg";
import footerCenter from "../images/launchpadHead.png";
import footerBg from "../images/footer_bg.png";
import audit from "../images/audited.png";
// import logo from "../assests/images/headerlogo.png";
import logo from "../images/logo.svg";
import { getAccount, getChainId } from '../hooks/useAccount'
import { getFrontsettinghook } from '../hooks/usebackend';
import { getProxyOwner } from '../hooks/useContract';


class Footer extends Component {
  constructor(props) {
    super(props);
    console.log("props", this.props)
    this.state = {
      location: false,
      walletModal: false,
      accountInfo: "",
      walletConnect: "",
      proxyOwner: "",
      currentChain: 0,
      settingsModal: false,
      sidebarShrink: false,
      setActive: 0,
      setting: [],
      telegram: '',
      Skype: '',
      twitter: '',
      discord: '',
      Docs: '',
      dex: '',
      support: '',
      medium: "",
      features: "",
      trading: "",
      earning: "",
      tokenomics: '',
      roadmap: '',
      privacypolicy: '',
      termsofservice: ''
    }
  }

  getOwner = async () => {
    const owner = await getProxyOwner();
    this.setState({ proxyOwner: owner });

  }

  componentDidMount() {
    document.body.classList.remove('sidebar_shr');
    console.log("history", this.props)
    this.setState({ accountInfo: getAccount() });
    this.setState({ currentChain: getChainId() });
    this.getOwner();
    this.getsetting();
  }

  async getsetting() {
    let data = await getFrontsettinghook();
    console.log("data setting", data, data?.data?.data);
    console.log("Array", typeof data?.data?.data == 'Array', typeof data?.data?.data);
    this.setState({ setting: data?.data?.data })
    typeof data?.data?.data == 'object' && data?.data?.data?.length > 0 && data?.data?.data?.map((val, i) => {
      console.log("val", val)
      if (val.settingname == "Docs" || val.settingname == "docs") {
        this.setState({ Docs: val.settingvalue });
      }
      if (val.settingname == "Twitter" || val.settingname == "twitter") {
        this.setState({ twitter: val.settingvalue });

      }
      if (val.settingname == "Telegram" || val.settingname == "telegram") {
        this.setState({ telegram: val.settingvalue });
      }
      if (val.settingname == "Support" || val.settingname == "support") {
        this.setState({ support: val.settingvalue });
      }
      if (val.settingname == "Dex" || val.settingname == "dex") {
        this.setState({ dex: val.settingvalue });
      }

      if (val.settingname == "Medium" || val.settingname == "medium") {
        this.setState({ medium: val.settingvalue });
      }

      if (val.settingname == "Features" || val.settingname == "features") {
        this.setState({ features: val.settingvalue });
      }

      if (val.settingname == "Trading" || val.settingname == "trading") {
        this.setState({ trading: val.settingvalue });
      }

      if (val.settingname == "Earning" || val.settingname == "earning") {
        this.setState({ earning: val.settingvalue });
      }

      if (val.settingname == "Tokenomics" || val.settingname == "tokenomics") {
        this.setState({ tokenomics: val.settingvalue });
      }

      if (val.settingname == "Roadmap" || val.settingname == "roadmap") {
        this.setState({ roadmap: val.settingvalue });
      }

      if (val.settingname == "Privacypolicy" || val.settingname == "privacypolicy") {
        this.setState({ privacypolicy: val.settingvalue });
      }

      if (val.settingname == "Termsofservice" || val.settingname == "termsofservice") {
        this.setState({ termsofservice: val.settingvalue });
      }

    })
  }

  render() {
    return (
      <div>
        <div className="newfooter position-relative">
          <img
            src={footerBg}
            alt="footer"
            className="footerBg position-absolute img-fluid"
          />
          <img
            src={audit}
            alt="footer"
            className="auditBg position-absolute img-fluid"
          />
          <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-lg-6">
                <div className="left">
                  <div
                    className="options optionlist d-flex flex-wrap justify-content-start"
                  >

                    <button>
                      <a href={this.state.features} target="_blank">
                        Features
                      </a></button>

                    <button>
                      <a href={this.state.trading} target="_blank">
                        Trading
                      </a>
                    </button>

                    <button>
                      <a href={this.state.earning} target="_blank">
                        Earning
                      </a>
                    </button>

                    <button>
                      <a href={this.state.tokenomics} target="_blank">
                        Tokenomics
                      </a>
                    </button>

                    <button>
                      <a href={this.state.roadmap} target="_blank">
                        Roadmap
                      </a>
                    </button>
                  </div>
                  <div className="copyright">
                    <div className="text">© Copyright Flashpad</div>
                    <div className="links">
                      {/* <Link to={this.state.privacypolicy}>Privacy policy</Link>
                      <Link to={this.state.termsofservice}>Terms of service</Link> */}
                      <a href={this.state.privacypolicy} target="_blank">Privacy policy</a>
                      <a href={this.state.termsofservice} target="_blank">Terms of service</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="overlay col-lg-6 d-flex align-items-center position-relative"
              >
                <img
                  src={footerCenter}
                  alt="logo"
                  className="footerCenter position-absolute d-none d-lg-block"
                />
                <div className="right d-flex flex-column">
                  <Link to="/home">
                    <div className="logo">
                      <img src={logo} alt="logo" className="img-fluid" />
                    </div>
                  </Link>

                  <h1 className="textOrbiXxl  mb-0">Flashy World</h1>

                  <div className="social d-flex align-items-center">
                    <button>
                      <a href={this.state.twitter} target="_blank">
                        <img src={twitter} alt="twitter" className="img-fluid" />
                      </a>
                    </button>{" "}
                    <button>
                      <a href={this.state.medium} target="_blank">
                        <img src={medium} alt="medium" className="img-fluid" />
                      </a>
                    </button>{" "}
                    <button>
                      <a href={this.state.telegram} target="_blank">
                        <img src={telegram} alt="telegram" className="img-fluid" />
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer