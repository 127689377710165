import React, { Component } from 'react';
import { Modal, FormControl, InputGroup } from 'react-bootstrap';
import { addIpvalidation } from '../../hooks/kycvalidation';
import { addIpAddress } from '../../hooks/usebackend';
import toast from 'react-hot-toast';


class AddIpmodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addIpModal: true,
            ipAddress: "",
            errors: {}
        };
    }

    handleadd = async () => {
        console.log("handleadd");
        let payload = {
            ipAddress: this.state.ipAddress
        };
        console.log("payload", payload);
        let validate = await addIpvalidation(payload);
        if (validate.isValid) {
            let IPresult = await addIpAddress(payload);
            console.log("IP result", IPresult);
            toast.success(IPresult?.data?.data?.message)
            this.props.onDismiss();
            this.props.getIP();
        }
        else {
            this.setState({ "errors": validate.errors })
        }

    }

    render() {


        const { addIpModal } = this.state


        return (



            <Modal className="wallet-modal" show={addIpModal} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Add IP</h3>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

                    <div className="wallet-lists px-2">

                        <div className='mod_pad_space pt-0'>
                            <div className='row'>
                                <div className='col-12 col-md-12 px-1 mb-3'>



                                </div>
                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>IP Address</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="ipAddress" placeholder="Enter IP Address"
                                                aria-describedby="basic-addon2"
                                                value={this?.state?.ipAddress}
                                                onChange={(e) => {
                                                    this.setState({ "ipAddress": e?.target?.value })
                                                }}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.ipAddress}</span>
                                    </div>
                                </div>


                                <div className='col-12 col-md-12 px-1 text-center pb-4'>
                                    <button className="btn_black mt-2" onClick={() => { this.handleadd() }}>
                                        Add
                                    </button>

                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        )
    }

}


export default AddIpmodal