import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from 'react-hot-toast';

import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Trendingslider from '../trendingslider';

import "../../css/styles.css";
import { getChainId } from '../../hooks/useAccount';

import favicon from "../../images/Bitdeal/logo_curve.png";
import PremiumLaunch from "../premiumLaunch"

import {
  Container,
  Row,
  Col,
  Tab,

} from "react-bootstrap";
import { validstandardtoken } from "../../hooks/kycvalidation";
import { gettokendata } from "../../hooks/useContract";
import { CHAINS } from "../../config/env";
import { iconTheme, position, style } from "../../hooks/useToast";
import { UserTokenList } from "../../hooks/usebackend";
import { getAccount } from "../../hooks/useAccount";


class TokenSuccess extends Component {




  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    document.getElementById("launchpad_parent").classList.add("active");
    this.hideLoader();
    this.gettokensData()
    console.log("address", this.state.tokenaddress, this.state.hash, this.props.location.state);
    if (this?.props?.location?.state?.state != "token") {
      this.props.history.push("/")
    }
  }


  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      tokenList: [{}],
      tokenListLength: 0,
      currentChain: 0,
      setUpdateCms: false,
      name: JSON.parse(sessionStorage.getItem("TKNDATA"))._name ? JSON.parse(sessionStorage.getItem("TKNDATA"))._name
        : JSON.parse(sessionStorage.getItem("TKNDATA")).name,
      symbol: JSON.parse(sessionStorage.getItem("TKNDATA"))._symbol ? JSON.parse(sessionStorage.getItem("TKNDATA"))._symbol
        : JSON.parse(sessionStorage.getItem("TKNDATA")).symbol,
      supply: JSON.parse(sessionStorage.getItem("TKNDATA"))._totalSupply ? JSON.parse(sessionStorage.getItem("TKNDATA"))._totalSupply
        : JSON.parse(sessionStorage.getItem("TKNDATA")).supply,

      tokenaddress: JSON.parse(sessionStorage.getItem("TKNDATA")).tokenAddres ? JSON.parse(sessionStorage.getItem("TKNDATA")).tokenAddres
        : JSON.parse(sessionStorage.getItem("TKNDATA")).tokenAddress,


      decimal: JSON.parse(sessionStorage.getItem("TKNDATA"))._decimals ? JSON.parse(sessionStorage.getItem("TKNDATA"))._decimals
        : JSON.parse(sessionStorage.getItem("TKNDATA")).decimals,
      errors: {},
      hashlink: CHAINS[getChainId()].TRANSACTION + JSON.parse(sessionStorage.getItem("HASH")).transactionHash,
      hash: JSON.parse(sessionStorage.getItem("HASH")).transactionHash,
      copy: "Copy Address"
    };
  }

  handlecreatetoken = async () => {
    if (this.state.tokentype == "Standard Token") {
      let payload = {
        name: this.state.name,
        symbol: this.state.symbol,
        decimal: this.state.decimal,
        totalsupply: this.state.totalsupply
      }
      let validate = await validstandardtoken(payload);
      if (validate?.isValid) {

      }
      else {
        this.setState({ errors: validate?.errors })
      }
    }
  }

  copyText(a, b) {
    toast.success("Address Copied", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    }
    )

  }


  gettokensData = async () => {

    var accountInfo = await getAccount();
    var Tokensinfo = await UserTokenList(accountInfo)
    console.log("Tokensinfo", Tokensinfo?.data?.length);
    this.setState({ tokenList: Tokensinfo?.data })
    this.setState({ tokenListLength: Tokensinfo?.data?.length })




  };






  render() {
    const location = this.props.location.pathname.split("/")[1];
    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round"></span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className="logo_overlay" id="logo_overlay">
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className="flex_side_right">
                <Sidebar />
                <div className="right_side_sec">

                  <Trendingslider />
                  <div className="right_side_spacing">
                    <div className="row mt-3 mx-0">
                      <div className='col-12  col-md-12 col-lg-12 mx-auto px-0'>
                        <div className="tab_img">
                          <div className='bg_layer mt-4'>
                            <div className='card-body pos_z_body_car'>
                              <div>
                                <div className='success_div pb-4'>

                                  <p className="overlay_card_head text-center">Your token was created!</p>

                                  <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                                    <span className='desc_grey_txt'>Name :</span>
                                    <span className='desc_grey_txt font_12'>{this.state.name}</span>
                                  </p>

                                  <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                                    <span className='desc_grey_txt'>Symbol :</span>
                                    <span className='desc_grey_txt font_12'>{this.state.symbol}</span>
                                  </p>
                                  <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                                    <span className='desc_grey_txt'>Decimal :</span>
                                    <span className='desc_grey_txt font_12'>{this.state.decimal}</span>
                                  </p>

                                  <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                                    <span className='desc_grey_txt'>Total Supply :</span>
                                    <span className='desc_grey_txt font_12'>{JSON.parse(sessionStorage.getItem("TKNDATA"))._decimals ? parseFloat(this.state.supply / 10 ** this.state.decimal)?.toFixed(0) : parseFloat(this.state.supply)?.toFixed(0)}</span>
                                  </p>

                                  <p className='white_txt_sm d-flex justify-content-between mt-0 mb-3'>
                                    <span className='desc_grey_txt'>Address :</span>
                                    <span className='desc_grey_txt font_12 brk_tetx_vla'>{this.state.tokenaddress}</span>
                                  </p>

                                  <div className='mt-5 btn4_div'>
                                    <a href={this.state.hashlink} target="_blank">
                                      <button className="get-started-btn orbitron_font text-white mt-2 mx-1"

                                      >
                                        View Transaction
                                      </button></a>

                                    <CopyToClipboard text={`${this.state.tokenaddress}`} onCopy={() => this.copyText('invite link', `${this.state.tokenaddress}`)}>
                                      <button variant='link' className="get-started-btn-border orbitron_font mt-2 mx-1" >Copy Address</button>
                                    </CopyToClipboard>
                                    <button className="get-started-btn orbitron_font text-white mt-2 mx-1"
                                      onClick={() => { this.props.history.push("/create") }}
                                    >
                                      Create Launchpad
                                    </button>
                                    <button className="get-started-btn-border orbitron_font mt-2 mx-1"
                                      onClick={() => { this.props.history.push("/createfairlaunch") }}
                                    >
                                      Create Fairlaunch
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.tokenListLength > 0 ?

                      <div className='row mt-5 mx-0'>
                        <div className='col-12 col-md-12 col-lg-12 mx-auto px-0'>
                          <div className="tab_img">
                            <div className='bg_layer'>
                              <div className='card-body pos_z_body_car'>
                                <div className="tab_par px-0 py-3">

                                  <Tab.Container defaultActiveKey="first">
                                    <Row className="container-fluid mt-0 w-100 mx-0 px-0">


                                      <Col lg={12} className="img_center_lg px-0">
                                        <div className='container container_custom px-0'>

                                          <p className="overlay_card_head text-left">Your created token list</p>



                                          <div class="table-responsive scrolllist">

                                            <table className="table recepients_table" id="myTable">
                                              <thead>
                                                <tr>
                                                  <th>Token Symbol</th>
                                                  <th>Token Name</th>
                                                  <th>Token Decimal</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>


                                              {this.state.tokenList.map((data, i) => <>
                                                <tr>
                                                  <td>{data?.symbol} </td>
                                                  <td>{data?.name} </td>
                                                  <td>{data?.decimal} </td>
                                                  <td><a target="blank" className="fa fa-external-link text-light mr-2" href={CHAINS[getChainId()].TokenTracker + data?.tokenaddress}></a>
                                                    <CopyToClipboard text={data?.tokenaddress} onCopy={() => this.copyText('invite link', `${data?.tokenaddress}`)}>

                                                      <span className="fa fa-copy "></span>
                                                    </CopyToClipboard>
                                                  </td>


                                                </tr> </>)}






                                            </table>
                                          </div>

                                          <Container>

                                          </Container>


                                        </div>
                                      </Col>
                                    </Row>
                                  </Tab.Container>




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      : <></>}

                  </div>
                  <PremiumLaunch/>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default TokenSuccess;
