import React, { Component } from "react";
import '../css/styles.css';
import { getSaleInfoCard } from "../hooks/useContract";
import { getTotalSalesInfo } from "../hooks/useProjects";

class Trendingslider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      trending: [],
      loadtrending: false
    };
  }

  componentDidMount() {
    this.gettrending()
  }

  async gettrending() {
    this.setState({ loadtrending: true })
    const totalsale = await getTotalSalesInfo();
    console.log("totalsale  proxy", totalsale, totalsale?.length);
    var totalproxy = [];

    for (var i = 0; i < totalsale?.length; i++) {
      console.log("totalproxy for");
      let data = await getSaleInfoCard(totalsale[i]?._sale);
      totalproxy.push(data);
    }

    console.log("totalproxy bf", totalproxy);
    var sortedtotalproxy = await totalproxy.sort((a, b) => (parseFloat(a.participants) > parseFloat(b.participants)) ? 1 : -1);
    console.log("totalproxy af", sortedtotalproxy);
    this.setState({ trending: sortedtotalproxy });
    this.setState({ loadtrending: false })

  }

  render() {
    return (
      <div className="text-white topBar d-flex justify-content-start trendss">
        <small className="trending_red mr-2 orbitron_font">Trending</small>
        {console.log("totalproxy", this?.state?.trending)}

        {
          !this.state.loadtrending ?
            this?.state?.trending && this?.state?.trending?.map((data, i) => i < 10 && data?.isWhitelisted ?
              <>
                <small>
                  <a href={`/privatesaledetail/${data.saleAddress}`}>
                    #{i + 1}&nbsp;<span>{data.name} </span>
                  </a>
                </small>

              </>
              : i < 10 && <>

                <small>
                  <a href={`/launchpaddetail/${data?.saleAddress}`}>
                    #{i + 1}&nbsp;<span>{data?.name} </span>
                  </a>
                </small>
              </>
            )
            :
            <div className="d-flex align-items-center">
              <div className="load_trend_skelton mr-3" />
              <div className="load_trend_skelton mr-3" />
              <div className="load_trend_skelton mr-3" />
              <div className="load_trend_skelton" />
            </div>
        }
      </div>
    )
  }
}

export default Trendingslider;