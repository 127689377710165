import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { InputGroup, FormControl } from 'react-bootstrap';
import isEmpty from 'is-empty';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ONEDAYINSECONDS, CHAINS, ZEROTH_ADDRESS } from '../../config/env'
import { UseTokenInfo, IsValidAddress, createPresale, toFixedNumber, depositTokens, isFloat, Checkaddress, Approvetoken, GetAdminfee } from '../../hooks/useContract'
import { getAccount } from '../../hooks/useAccount'
import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from '../../hooks/useWeb3'

import TokenModal from "../Launchpad/TokenModal"
import { getFee } from '../../hooks/usebackend';
import { urlvalidation } from '../../hooks/kycvalidation';
import { getkychook } from '../../hooks/usebackend';
import { getChainId } from '../../hooks/useAccount';
import { ObjectIsempty } from '../../helper/ObjectIsempty';

const formvalue = {
    logo: '',
    name: '',
    symbol: '',
    address: '',
    decimal: '',
    presaleRate: 10000,
    softCap: 0,
    hardCap: 50,
    min: 0.000001,
    max: 50,
    isVested: false,
    isPancake: false,
    pancakeRate: '',
    liquidityPercent: 75,
    unlockOn: 30,
    startDate: '',
    endDate: '',
    vestPercent: 0,
    vestInterval: 0,
    isWithoutToken: false,
    description: '',
    website: 'https://flashpad.org/',
    twitter: '',
    telegram: '',
    deposit: 0,
    decimals: 0,
    createdSale: '',
    isDeposited: false,
    isWhitelisted: true,
    currentStep: 1,
    tokenModal: false,
    currency: CHAINS[getChainId()].SYMBOL,
    tokenaddress: "",
    tokeninfo: {},
    errors: {},
    fee: 5,
    facebook: "",
    githup: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    button1: true,
    button2: true,
    button3: true,
    isToken: false,
    userFee: 0,
    WithTokenaddress: "",
    LaunchpadType: true,
    kyc: "",
    approvebutton: false,
    poolfee: 0,
    affliateFee: "",
    minAff: "",
    maxAff: "",
    isAffiliate: true,
    TokenDecimal: 0,
    getFee: [],
    CheckedInd: 0,
    tokenFee: 0,
    bnbFee: 0,

};
class CreatePrivatesaletab extends Component {

    constructor(props) {
        super(props);
        this.state = formvalue
    }

    componentDidUpdate() {
        console.log("update");

    }

    async useWithtokenInfo() {
        console.log("address", this.state.WithTokenaddress, this.state.address, this.state.WithTokenaddress != ZEROTH_ADDRESS)
        if (this.state.WithTokenaddress != ZEROTH_ADDRESS) {
            var usewithToken = await UseTokenInfo(this.state.WithTokenaddress)
            console.log("usewithToken", usewithToken)
            this.setState({ TokenDecimal: usewithToken?.decimals });
        }
        else {
            this.setState({ TokenDecimal: 18 })
        }
    }


    handledisable = () => {
        this.setState({ button2: false })
        var isvalid = true;
        var i = 1;
        if (ObjectIsempty(this.state.errors) && this.state.presaleRate
            && this.state.hardCap && this.state.min && this.state.max && this.state.startDate &&
            this.state.endDate) {
            isvalid = false
            console.log("vest1", isvalid);
            if (this.state.isPancake) {
                isvalid = true;
                console.log("vest2", isvalid);
                if (this.state.unlockOn &&
                    this.state.liquidityPercent) {
                    console.log("vest3", isvalid);
                    isvalid = false;
                    console.log("vest3", isvalid);
                }else{
                    return isvalid
                }
            }

            if (this.state.isVested) {
                isvalid = true;
                console.log("vest4", isvalid);
                if (this.state.vestInterval && this.state.vestPercent) {
                    isvalid = false
                    console.log("vest5", isvalid);
                }else{
                    return isvalid
                }
            }

            console.log("this.state.tokeninfo.totalSupply",this.state.tokeninfo.totalSupply)
            if(!isEmpty(this.state.hardCap) && !isEmpty(this.state.presaleRate)){
                isvalid = false
                console.log("TotalValue",this.state.hardCap,this.state.presaleRate, parseFloat(this.state.tokeninfo.totalSupply)/10 ** 18, this.state.hardCap * this.state.presaleRate,(parseFloat(this.state.tokeninfo.totalSupply)/10 ** 18) < (this.state.hardCap * this.state.presaleRate))
                if((parseFloat(this.state.tokeninfo.totalSupply)/10 ** 18) < (this.state.hardCap * this.state.presaleRate)){
                    // toast.error("Hardcap and Presale Rate should not be greater than totalSupply!")
                    isvalid = true
                }else{
                    return isvalid
                }
            }


        }
        this.setState({ button2: isvalid });
        console.log("isvalid", isvalid);
        return isvalid;
    }

    handledisableurlbutton = ({
        description,
        logo,
        banner,
        website,
        twitter,
        // facebook,
        telegram,
        githup,
        instagram,
        discord,
        reddit,
        youtube
    }) => {
        var isvalid = true;
        if (logo && banner && website  && twitter && githup && telegram && instagram && discord
            && reddit && youtube && description) {
            isvalid = false;
        }
        this.setState({ button3: isvalid })
    }

    socialMediaValidations = () => {
        var errors = {}

        if (isEmpty(this.state.description)) {
            errors.description = "Description field is required"
        }

        if (isEmpty(this.state.banner)) {
            errors.banner = "Banner field is required"
        }

        // if (isEmpty(this.state.facebook)) {
        //     errors.facebook = "Facebook field is required"
        // }

        if (isEmpty(this.state.twitter)) {
            errors.twitter = "Twitter field is required"
        }

        if (isEmpty(this.state.githup)) {
            errors.githup = "Github field is required"
        }

        if (isEmpty(this.state.telegram)) {
            errors.telegram = "Telegram field is required"
        }

        if (isEmpty(this.state.instagram)) {
            errors.instagram = "Instagram field is required"
        }

        if (isEmpty(this.state.discord)) {
            errors.discord = "Discord field is required"
        }

        if (isEmpty(this.state.reddit)) {
            errors.reddit = "Reddit field is required"
        }

        if (isEmpty(this.state.youtube)) {
            errors.youtube = "Youtube field is required"
        }

        if (isEmpty(this.state.website)) {
            errors.website = "Website field is required"
        }

        if (isEmpty(this.state.logo)) {
            errors.logo = "Logo field is required"
        }

        this.setState({ errors: errors })
        return errors
    }


    validations() {
        var errors = {}
        console.log("validations")
        var rx = new RegExp(/^[-+]?[0-9]+\.[0-9]+$/)
        var rd = new RegExp(/^\d+$/);
        var wholeNumbers = new RegExp(/^\d+$/);
        if (!this.state.presaleRate || isNaN(this.state.presaleRate) || this.state.presaleRate == 0) {
            errors.presale = "Invalid Presale Rate"
        }
        else if (rx.test(this.state.presaleRate) || rd.test(this.state.presaleRate) || !isNaN(this.state.presaleRate)) {
            errors.presale = ""
        }
        else {
            errors.presale = "Invalid PresaleRate"
        }
        // if (this.state.isAffiliate === true && !this.state.affliateFee || isNaN(this.state.affliateFee)) {
        //     errors.affliateFee = "Invalid Affiliate percentage!!"
        // }
        // else {
        //     errors.affliateFee = ""
        // }

        if (isNaN(this.state.softCap)|| isEmpty(this.state.softCap)) {
            errors.softCap = "Invalid softcap !"
        }
        else if (this.state.hardCap) {
            if (this.state.softCap > (parseFloat(this.state.hardCap))) {
                console.log("Softcap must");
                errors.softCap = "Softcap must be less than Hardcap"

            }
            else {
                errors.softCap = ""

            }
        }

      

        if (!this.state.hardCap || isNaN(parseFloat(this.state.hardCap))) {
            console.log("invalid");
            errors.hardcap = "Invalid hardcap !"
        }
        else if (this.state.hardCap < parseFloat(this.state.softCap)) {

            errors.hardcap = "Hardcap must be greater than softcap !"

        }

        else {
            errors.hardcap = ""
        }

        if (this.state.softCap > (parseFloat(this.state.hardCap))) {
            errors.softCap = "Softcap must be less than Hardcap"

        }

        console.log("this.state.tokeninfo.totalSupply",this.state.tokeninfo.totalSupply)
        if(!isEmpty(this.state.hardCap) && !isEmpty(this.state.presaleRate)){
            console.log("TotalValue",this.state.hardCap,this.state.presaleRate, parseFloat(this.state.tokeninfo.totalSupply)/10 ** 18, this.state.hardCap * this.state.presaleRate,(parseFloat(this.state.tokeninfo.totalSupply)/10 ** 18) < (this.state.hardCap * this.state.presaleRate))
            if((parseFloat(this.state.tokeninfo.totalSupply)/10 ** 18) < (this.state.hardCap * this.state.presaleRate)){
                // toast.error("Hardcap and Presale Rate should not be greater than totalSupply!")
                errors.hardcap = "Hardcap and Presale Rate should not be greater than totalSupply!"
                errors.presale = "Hardcap and Presale Rate should not be greater than totalSupply!"
            }
            else {
                errors.hardcap = ""
                errors.presale = ""

            }
        }


        if (isNaN(this.state.min)) {
            errors.min = "Invalid Minimum Buy "
        }
        if (!this.state.min) {
            errors.min = "Minimum Buy field is required"
        }
        if (this.state.max) {
            if (this.state.min > this.state.max) {
                errors.min = "Minimum Buy  must be < Maximum Buy"
            }
            else {
                errors.min = ""
            }
        }
        if (isNaN(this.state.max)) {
            errors.max = "Invalid Maximum Buy "
        }
        if (!this.state.max) {
            errors.max = "Maximum Buy field is required"
        }
        if (this.state.min) {
            if (this.state.max < this.state.min) {
                errors.max = "Maximum Buy  must be > Minimum Buy"
            }
            else {
                errors.max = ""
            }
        }

        if (this.state.isPancake) {
            var rx = new RegExp(/^\d+$/);
            if (!rx.test(this.state.unlockOn)) {
                errors.lockingday = "Invalid Locking Days !"
            }
            else if (isEmpty(this.state.unlockOn)) {
                errors.lockingday = "Locking Days field is required"
            }
            else if (this.state.unlockOn < 30) {
                errors.lockingday = "Locking Days should be greater than 30 days"
            }
            else {
                errors.lockingday = ""
            }
            var rx = new RegExp(/^\d+$/);
            if (isNaN(this.state.liquidityPercent) || this.state.liquidityPercent < 51 || this.state.liquidityPercent > 100) {
                errors.liquidity = "Invalid Flashpad Liquidity !"
            }
            else {
                errors.liquidity = ""
            }


            if (isNaN(this.state.pancakeRate)) {
                errors.listingrate = "Invalid Listing rate !"

            }
            else if(isEmpty(this.state.pancakeRate)){
                errors.listingrate = "Listing rate field is required!"
            }
            else {
                errors.listingrate = ""

            }


        }
        if (new Date(this.state.startDate).getTime() >= new Date().getTime()) {
            errors.startdate = ""

        }
        else if (isEmpty(this.state.startDate)) {
            errors.startdate = "Start Date field is required!"
        }
        else {
            errors.startdate = "Start Time needs to be after now"
        }
        if (this.state.endDate) {
            if (Date.parse(this.state.startDate) > this.state.endDate) {
                errors.startdate = "Start Time must be < End Time"
            }
            if (this.state.endDate > Date.parse(this.state.startDate)) {
                errors.enddate = ""
            }
        }
        if (Date.parse(this.state.endDate) > Date.now()) {
            errors.enddate = ""
        }
        else if (isEmpty(this.state.endDate)) {
            errors.enddate = "End Date field is required!"
        }
        else {
            errors.enddate = "End Time needs to be after now"
        }
        if (this.state.startDate) {
            if (Date.parse(this.state.endDate) <= this.state.startDate) {
                console.log("invalideee");
                errors.enddate = "End Time must be > Start Time"
                this.forceUpdate()
            }
            if (this.state.startDate < Date.parse(this.state.endDate)) {
                errors.startdate = ""
                errors.enddate = ""
            }
        }
        if (this.state.isVested) {
            var rx = new RegExp(/^\d+$/);
            if (!rx.test(this.state.vestInterval)) {
                errors.vestinterval = "Invalid Vesting Period Days !"
            }
            else if (isEmpty(this.state.vestInterval)) {
                errors.vestinterval = "Vesting Period Days field is required!"

            }
            else {
                errors.vestinterval = ""
            }

            if (isNaN(this.state.vestPercent) || this.state.vestPercent < 0 || this.state.vestPercent > 100) {
                errors.vestpercent = "Invalid Rewards % per Vesting Period !"
            }
            else if (isEmpty(this.state.vestPercent)) {
                errors.vestpercent = "Vesting Period field is required!"

            }
            else {
                errors.vestpercent = ""
            }
        }
        console.log("validation_errors", errors)
        this.setState({ errors: errors });
    }

    // Presale Creation
    CreateSale = async () => {

        await this.useWithtokenInfo()

        window.onbeforeunload = () => window.confirm("You have'nt deposited tokens to start presale !")
        window.onpopstate = () => window.confirm("You have'nt deposited tokens to start presale !")
        console.log("call", this?.state?.startDate);

        let Addresses = [];
        let Values = [];
        let Details = [];
        let isSetter = [];

        const account = getAccount()
        const startTime = this?.state?.startDate;
        const endTime = this?.state?.endDate;

        const CHAIN_ID = CURRENT_CHAIN_ID();
        const CURRENT_RPC = CURRENT_RPC_URL();
        let ROUTER;
        CHAINS.map((data) => {
            if ((((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER) != false)
                ROUTER = ((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER;
            console.log("router inside map :", ROUTER);
        })
        console.log("router address:", ROUTER);
        const startDiff = parseInt(parseInt((new Date(startTime).getTime())) / 1000);
        const endDiff = parseInt(parseInt((new Date(endTime).getTime())) / 1000);

        // _token 0
        //_router 1
        Addresses.push(this?.state?.tokenaddress);
        Addresses.push(ROUTER);
        Addresses.push(account);
        Addresses.push(this.state.WithTokenaddress)
        //_min 0 
        //_max 1
        //_rate 2this.state.decimals
        // _soft  3
        // _hard 4
        //_pancakeRate  5
        //_unlockon  6
        // _percent 7
        // _start 8
        //_end 9
        //_vestPercent 10
        //_vestInterval 11
        Values.push(toFixedNumber(this?.state?.min * 10 ** 18).toString());
        Values.push(toFixedNumber(this?.state?.max * 10 ** 18).toString());
        Values.push(toFixedNumber(this?.state?.presaleRate * 10 ** 18).toString());
        Values.push(toFixedNumber(this?.state?.softCap * 10 ** 18).toString());
        Values.push(toFixedNumber(this?.state?.hardCap * 10 ** 18).toString());
        Values.push((isEmpty(this.state.pancakeRate * 10 ** 18) ? toFixedNumber(this?.state?.presaleRate * 10 ** 18) : toFixedNumber(this.state.pancakeRate * 10 ** 18)).toString());
        Values.push(this?.state?.unlockOn.toString());
        Values.push(this?.state?.liquidityPercent.toString());
        Values.push(startDiff.toString());
        Values.push(endDiff.toString());
        Values.push((this?.state?.vestPercent * 100).toString());
        Values.push((this?.state?.vestInterval * ONEDAYINSECONDS).toString());
        Values.push((this?.state?.userFee)?.toString())
        Values.push((this.state.affliateFee * 10 ** 18).toString())
        Values.push((0).toString())
        Values.push("115792089237316195423570985008687907853269984665640564039457584007913129639935")
        Values.push((this.state.tokenFee * 10 ** 18).toString())
        Values.push((this.state.bnbFee * 10 ** 18).toString())

        // isAuto 0
        //_isvested 1
        // isWithoutToken 2
        isSetter.push(this?.state?.isPancake);
        isSetter.push(this?.state?.isVested);
        isSetter.push(this?.state?.isWithoutToken);
        isSetter.push(this?.state?.isWhitelisted);
        isSetter.push(this?.state?.currency == CHAINS[getChainId()].SYMBOL ? true : false)
        isSetter.push(this?.state?.isToken)
        isSetter.push(this.state.LaunchpadType)
        // description 0 
        // website,twitter,telegram 1,2,3
        //logo 4
        //name 5
        //symbol 6
        Details.push(this?.state?.description);
        Details.push(this?.state?.website);
        Details.push(this?.state?.twitter);
        Details.push(this?.state?.telegram);
        Details.push(this?.state?.logo);
        Details.push(this?.state?.tokeninfo?.name);
        Details.push(this?.state?.tokeninfo?.symbol);
        Details.push(this?.state?.githup);
        Details.push(this?.state?.instagram);
        Details.push(this?.state?.discord);
        Details.push(this?.state?.reddit);
        Details.push(this?.state?.youtube);
        Details.push(this.state.banner);

        // const data = this.validate()
        // if(data)
        //     return false;


        console.log("DATA : ", Addresses, Values, isSetter, Details)
        const sale = await createPresale(Addresses, Values, isSetter, Details, account)
        this.setState({ createdSale: sale });
        console.log("createdSale : ", sale);
    }
    componentDidMount() {
        this.GetTokenAddress(CHAINS[getChainId()].SYMBOL)
        this.handlegetkyc();
        this.getfee();
        this.getFeeSettings()
    }


    getFeeSettings = async () => {

        let get = await getFee();
        console.log("getFee", get);
        if (get?.data?.data?.status == true) {
            let data = get?.data?.data?.data
            console.log("DATA", data)
            this.setState({ bnbFee: data[0].BNBvalue, tokenFee: data[0].tokenvalue })
            this.setState({ "getFee": get?.data?.data?.data });
            console.log("getFee_getFee", get?.data?.data?.data)
        }
    }


    getfee = async () => {
        let fee = await GetAdminfee();
        this.setState({ poolfee: fee.deploymentfee / 10 ** 18 })
    }

    GetTokenAddress(value) {

        let Withtokenaddress;
        if (value === "DAI") {
            Withtokenaddress = CHAINS[getChainId()].DAI
            this.setState({ WithTokenaddress: CHAINS[getChainId()].DAI });
        } else if (value === "USDT") {
            Withtokenaddress = CHAINS[getChainId()].USDT
            this.setState({ WithTokenaddress: CHAINS[getChainId()].USDT });
        } else if (value === "USDC") {
            Withtokenaddress = CHAINS[getChainId()].USDC
            this.setState({ WithTokenaddress: CHAINS[getChainId()].USDC });
        } else if (value === CHAINS[getChainId()].SYMBOL) {
            Withtokenaddress =CHAINS[getChainId()][`${CHAINS[getChainId()].SYMBOL}`]
            this.setState({ WithTokenaddress: CHAINS[getChainId()][`${CHAINS[getChainId()].SYMBOL}`]});
        }

    }

    async approvetoken() {
        await Approvetoken(this.state.tokenaddress);
        this.setState({ approvebutton: true })
    }

    handlegetkyc = async () => {
        if (isEmpty(this.state.kyc)) {
            let walletaddress = localStorage.getItem("accountInfo");
            let result = await getkychook(walletaddress && walletaddress?.toLowerCase());
            console.log("result", result?.data?.data);
            if (result?.data?.data?.record?.status == 'Approved' || result?.data?.data?.record?.status == "Pending") {
                this.setState({ kyc: result?.data?.data?.status })
            }
        }
    }


    goToSale = () => {
        window.onbeforeunload = undefined
        window.onpopstate = undefined
        window.location.href = `${window.location.origin}/privatesaledetail/${this.state.createdSale}`;
    }

    async getTokenInfo(tokenaddress) {
        const isValid = await IsValidAddress(tokenaddress);
        if (tokenaddress.length == 42 && !isValid) {
            this.setState({ errors: "tokendata.errors" });
            this.setState({ tokeninfo: {} })
        }
        console.log("IS valid ", isValid)
        if (tokenaddress.length == 42 && isValid) {
            const token = await UseTokenInfo(tokenaddress);
            this.setState({ name: token.name });
            this.setState({ symbol: token.symbol });
            this.setState({ decimals: token.decimals });
        }
    }

    calculateDepositTokens() {
        this.forceUpdate()
        let topancakeTokens = 0;
        let pancakeTokens = 0;
        const presalePrice = 1 / parseFloat(this.state.presaleRate);
        if (this.state.isPancake) {
            const pancakePrice = 1 / parseFloat(this.state.pancakeRate);
            topancakeTokens = parseFloat(this.state.hardCap) * parseFloat(this.state.liquidityPercent) / 100;
            pancakeTokens = topancakeTokens / pancakePrice;
            console.log("pancake tokens : ", pancakeTokens)
        }
        const netTokens = parseFloat(this.state.hardCap) / presalePrice;
        console.log("net Token : ", pancakeTokens + netTokens)
        this.setState({ deposit: pancakeTokens + netTokens });

    }

    async DepositTokens() {
        const account = getAccount()
        const tokenAmount = toFixedNumber(this.state.deposit * 10 ** this.state.tokeninfo.decimals).toString()
        const isDeposit = await depositTokens(this.state.tokenaddress, this.state.createdSale, tokenAmount, account)
        this.setState({ isDeposited: isDeposit });
        console.log("isDeposit", isDeposit)
    }

    renderDeposit() {
        return (this.state.deposit && this.state.deposit > 0 ?
            <button onClick={this.DepositTokens.bind(this)} className="get-started-btn">Deposit {parseFloat(this.state.deposit)?.toFixed(2)} {this.state.symbol}</button> :
            <button onClick={this.calculateDepositTokens.bind(this)} className="get-started-btn">Calculate</button>)
    }

    validate() {
        console.log("this.state.hardCap", typeof this.state.hardCap, "this.state.softCap", typeof this.state.softCap, (this.state.hardCap) < (this.state.softCap), "10<8", 10 < 8);
        if (parseFloat(this.state.hardCap) < parseFloat(this.state.softCap)) {
            toast.error("Hard Cap must be Higher than Soft Cap !",
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
        }
        if ((parseFloat(this.state.presaleRate) < 0)) {
            toast.error("Price must be greater than 0",
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
        }
        if ((parseFloat(this.state.pancakeRate) < 0)) {
            toast.error("Pancake price must be greater than 0",
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
        }

        return (parseFloat(this.state.hardCap) < parseFloat(this.state.softCap)) || (parseFloat(this.state.presaleRate) < 0) || (parseFloat(this.state.pancakeRate) < 0);
    }

    filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    onDismiss() {
        this.setState({ tokenModal: false });
    }

    async settokenaddress(value) {
        this.setState({ tokenaddress: value });
        const tokendata = await Checkaddress(value);
        console.log("tokendata", tokendata);
        if (tokendata.isValid) {
            this.setState({ tokeninfo: tokendata.tokeninfo })
            this.setState({ errors: {} });
            this.setState({ approvebutton: tokendata.allowance })
            this.setState({ button1: false })
        }
        else {
            let formData = { ...this.state.errors, ["tokenaddress"]: "Invalid token address !" };
            this.setState({ errors: formData });
            this.setState({ tokeninfo: {} })
        }
    }
    render() {

        const { tokenModal } = this.state



        return (
            <div>
                <div className='row py-4 row_rever_md'>
                    <div className='col-12 col-md-8 col-lg-8'>
                        <div className='ongoing_sec form_sec h-100'>
                            <div className="inner_bg h-100">
                                <div className='row h-100'>
                                    <div className='col-12 h-100'>
                                        <div className="tab_img h-100">
                                            <div className='card_overlay h-100'>
                                                <div className='card-body pb-5'>
                                                    <div className='row align-items-center pb-4'>
                                                        <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                                            <p className='overlay_card_head'>Create Private Sale</p>

                                                            <p className='text-white font_12 mb-0'>(*) is required field.</p>

                                                        </div>
                                                        <div className={this.state.currentStep == 1 ? "col-12 col-md-6 text-md-right d-block" : "col-12 col-md-6 text-md-right d-none"}>
                                                            <button className="get-started-btn font_20 btn_icon_grad" onClick={() => this.setState({ tokenModal: true })}>+</button>
                                                        </div>
                                                    </div>
                                                    <div id="firststep" className={this.state.currentStep == 1 ? "d-block" : "d-none"}>
                                                        <div className='row'>
                                                            <div className='col-12 col-md-12'>
                                                                <p className='input_desc_sm'>Token address*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl value={this.state.tokenaddress} onChange={(e) => this.settokenaddress(e.target.value)
                                                                        } id="tokenaddress" placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tokenaddress}</span>
                                                                </div>
                                                                <div className='note_desc mt-1 mb-1'>
                                                                    <p>Pool creation fee: {this.state.poolfee} {CHAINS[getChainId()].SYMBOL}</p>
                                                                </div>
                                                            </div>

                                                            <div className={isEmpty(this.state.tokeninfo) ? "d-none" : 'col-12 col-md-12 mb-3'}>
                                                                <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                    <span className='desc_grey_txt'>Name :</span>
                                                                    <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.name}</span>
                                                                </p>


                                                                <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                    <span className='desc_grey_txt'>Symbol :</span>
                                                                    <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.symbol}</span>
                                                                </p>

                                                                <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                    <span className='desc_grey_txt'>Decimals :</span>
                                                                    <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.decimals}</span>
                                                                </p>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-1'>
                                    <p className='input_desc_sm'>Currency</p>
                                    <div className='d-flex'>
                                                                    <div className={this.state.currency == CHAINS[getChainId()].SYMBOL ? 'currency_badge active mr-2' : "currency_badge mr-2"} value={CHAINS[getChainId()].SYMBOL}
                                                                        onClick={(e) => {
                                                                            console.log(e.target.getAttribute("value"), 'Currency1');
                                                                            this.setState({ "currency":CHAINS[getChainId()].SYMBOL })
                                                                            this.GetTokenAddress(CHAINS[getChainId()].SYMBOL)
                                                                        }}
                                                                    ><span>{CHAINS[getChainId()].SYMBOL}</span></div>
                                                                    <div className={this.state.currency == "DAI" ? 'currency_badge active mr-2' : "currency_badge mr-2"} value="DAI"
                                                                        onClick={(e) => {
                                                                            console.log(e.target.getAttribute("value"), 'Currency2');
                                                                            this.setState({ "currency": "DAI" });
                                                                            this.GetTokenAddress("DAI");

                                                                        }}><span>DAI</span></div>
                                                                    <div className={this.state.currency == "USDT" ? 'currency_badge active mr-2' : "currency_badge mr-2"} value="USDT"
                                                                        onClick={(e) => {
                                                                            console.log(e.target.getAttribute("value"), 'Currency3');
                                                                            this.setState({ "currency": "USDT" });
                                                                            this.GetTokenAddress("USDT");

                                                                        }}
                                                                    ><span>USDT</span></div>
                                                                    <div className={this.state.currency == "USDC" ? 'currency_badge active mr-2' : "currency_badge mr-2"} value="USDC"
                                                                        onClick={(e) => {
                                                                            console.log(e.target.getAttribute("value"), 'Currency4');
                                                                            this.setState({ "currency":"USDC"});
                                                                            this.GetTokenAddress("USDC")
                                                                        }}
                                                                    ><span>USDC</span></div>

                                                                </div>
                                    <div className='note_desc mt-1 mb-1'>
                                    <p>Users will pay with {this.state.currency && this.state.currency} for your token</p>
                                    </div>
 
                                </div>




                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Fee Options</p>
                                                                {this.state.getFee.map((data, index) => {
                                                                    console.log("map", data, index)
                                                                    let isBNB = data.BNBvalue > 0 ? true : false
                                                                    let isToken = data.tokenvalue > 0 ? true : false
                                                                    let i = index
                                                                    return (
                                                                        <>
                                                                            <div className="custom-control custom-radio mb-2"

                                                                            >
                                                                                <div onClick={() => {
                                                                                    console.log("valsdgfsdsdgsd", data, 5, i);
                                                                                    this.setState({ CheckedInd: i })
                                                                                }}>
                                                                                    <input type="checkbox" id="customRadio" name={`customRadio${i}`} className="custom-control-input"
                                                                                        checked={this.state.CheckedInd == i ? true : false}
                                                                                    />
                                                                                    {console.log("tokenFee+bnbFee", this.state.tokenFee, this.state.bnbFee)}

                                                                                    <label className="custom-control-label" for={`customRadio${i}`}>{isBNB ? `${data.BNBvalue}% LineaETH raised` : ""} {isBNB && isToken ? "+" : ''} {isToken ? `${data.tokenvalue}% token sold` : ''} <span>{isBNB && !isToken ? "(recommended)" : ''}</span></label>

                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                                {console.log('kjcsfikjulksfss', this.state.CheckedInd)}
                                                            </div>

                                                            {this.state.currency == "USDC" ?
                                                                <div className='col-12 col-md-12 mb-4 mt-4'>
                                                                    <div className='card_footer_form'>
                                                                        <div className='d-flex align-items-center justify-content-center'>
                                                                            <i class="fa fa-exclamation-circle text-danger-war" aria-hidden="true"></i>
                                                                            <p className='mb-0 pl-3'>Do not use this currency for auto liquidity tokens, or tokens that depend on WETH pair. It will lead to error when finalizing the pool or transferring the tokens (for example Liquidity Generator Token, BabyToken, BuyBack Baby Token) <br />
                                                                                Contact Space Launch for more information.</p>
                                                                        </div>
                                                                    </div>
                                                                </div> : <></>}
                                                            <div className='col-12 col-md-12 text-center'>
                                                                {this.state.approvebutton &&
                                                                    <button className="get-started-btn-border orbitron_font btn_min_wid" onClick={() => { this.setState({ currentStep: 2 }) }}
                                                                        disabled={this.state.button1}
                                                                    >
                                                                        Next
                                                                    </button>
                                                                }

                                                                {!this.state.approvebutton &&
                                                                    <button className="get-started-btn btn_fullwidth_grad" onClick={() => { this.approvetoken() }}
                                                                        disabled={this.state.button1}
                                                                    >
                                                                        <p className='btn_text_head mb-3'>Approve</p>
                                                                        <p className='mb-0 btn_text_desc'>(For auto listing, after you finalize the pool your token will be auto listed on DEX)</p>
                                                                    </button>
                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div id="secondstep" className={this.state.currentStep == 2 ? "d-block" : "d-none"}>
                                                        <div className='row'>
                                                            <div className='col-12 col-md-12 mb-3'>

                                                                <p className='input_desc_sm'>Presale Rate*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl pattern="^[1-9]+[0-9]*$" id="presalerate" value={this.state.presaleRate} onChange={(e) => {
                                                                            console.log("presale", e.target.value);
                                                                            this.setState({ presaleRate: e.target.value });
                                                                            var rx = new RegExp(/^[-+]?[0-9]+\.[0-9]+$/)
                                                                            var rd = new RegExp(/^\d+$/);
                                                                            if (!e.target.value || isNaN(e.target.value) || e.target.value == 0) {
                                                                                const formvalue = { ...this.state.errors, ["presale"]: "Invalid Presale Rate" }
                                                                                this.setState({ errors: formvalue })
                                                                            }
                                                                            else if (rx.test(e.target.value) || rd.test(e?.target?.value) || !isNaN(e.target.value)) {
                                                                                const formvalue = { ...this.state.errors, ["presale"]: "" }
                                                                                this.setState({ errors: formvalue })
                                                                            }
                                                                            else {
                                                                                const formvalue = { ...this.state.errors, ["presale"]: "Invalid PresaleRate" }
                                                                                this.setState({ errors: formvalue })
                                                                            }
                                                                            this.handledisable();
                                                                        }} placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.presale}</span>
                                                                </div>
                                                                <div className='note_desc mt-1 mb-1'>
                                                                    <p className='mb-0'>If I spend 1 {this.state.currency} how many tokens will I receive?</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-md-12 mb-0'>
                                                                <p className='input_desc_sm'>Softcap*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl value={this.state.softCap} onChange={(e) => {
                                                                            this.setState({ softCap: e.target.value });
                                                                            const formvalue = { ...this.state.errors, ["softcap"]: "" }
                                                                            let Errors = this.state.errors
                                                                            this.setState({ errors: formvalue });
                                                                            var rd = new RegExp(/^\d+$/);
                                                                            var rx = new RegExp(/^\d+((.)|(.\d{0,4})?)$/)
                                                                            if (!rx.test(parseFloat(e.target.value))) {
                                                                                Errors = { ...Errors, ["softCap"]: "Invalid softcap !" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (isNaN(e.target.value)) {
                                                                                Errors = { ...Errors, ["softCap"]: "Invalid softcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (!e.target.value) {
                                                                                Errors = { ...Errors, ["softCap"]: "Softcap field is required" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (parseFloat(e.target.value) > (parseFloat(this.state.hardCap))) {
                                                                                Errors = { ...Errors, ["softCap"]: "Softcap must be less than Hardcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else {
                                                                                Errors = { ...Errors, ["softCap"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }

                                                                            if (parseFloat(this.state.hardCap) < (parseFloat(e.target.value))) {
                                                                                Errors = { ...Errors, ["hardcap"]: "Hardcap must be greater than the softcap" }
                                                                                console.log("Errors", Errors, this.state.hardCap, e.target.value)
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (parseFloat(this.state.hardCap) > (parseFloat(e.target.value))) {
                                                                                Errors = { ...Errors, ["hardcap"]: "" }
                                                                                console.log("Errors", Errors, this.state.hardCap, e.target.value)
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            this.handledisable();

                                                                        }} id="softcap" placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.softCap}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3 mt-3'>
                                                                <p className='input_desc_sm'>HardCap*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="hardcap" value={this.state.hardCap} onChange={(e) => {
                                                                            const formvalue = { ...this.state.errors, ["hardcap"]: "" }

                                                                            let Errors = this.state.errors
                                                                            this.setState({ errors: formvalue });
                                                                            this.setState({ hardCap: e.target.value });
                                                                            var rd = new RegExp(/^\d+((.)|(.\d{0,4})?)$/);
                                                                            console.log("!rd.test(parseFloat(e.target.value))", !rd.test(parseFloat(e.target.value)))
                                                                            if (!rd.test(parseFloat(e.target.value))) {

                                                                                Errors = { ...Errors, ["hardcap"]: "Invalid Hardcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (isNaN(e.target.value)) {
                                                                                Errors = { ...Errors, ["hardcap"]: "Invalid Hardcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (!e.target.value || e.target.value == 0) {
                                                                                Errors = { ...Errors, ["hardcap"]: "Hardcap field is required" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (parseFloat(e.target.value) < parseFloat(this.state.softCap)) {
                                                                                Errors = { ...Errors, ["hardcap"]: "Hardcap must be greater than the softcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else {
                                                                                Errors = { ...Errors, ["hardcap"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }

                                                                            if (parseFloat(this.state.softCap) > (parseFloat(e.target.value))) {
                                                                                Errors = { ...Errors, ["softCap"]: "Softcap must be less than Hardcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (parseFloat(this.state.softCap) < (parseFloat(e.target.value))) {
                                                                                Errors = { ...Errors, ["softCap"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            this.handledisable();
                                                                            console.log("condition", e.target.value < this.state.softcap)

                                                                        }} placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.hardcap}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Minimum Buy *</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="mincontribution" value={this.state.min} onChange={(e) => {
                                                                            const formvalue = { ...this.state.errors, ["min"]: "" }
                                                                            let Errors = this.state.errors

                                                                            this.setState({ errors: formvalue });
                                                                            this.setState({ min: e.target.value });
                                                                            if (isNaN(e.target.value) || e.target.value === '0') {
                                                                                Errors = { ...Errors, ["min"]: "Invalid Minimum Buy " }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (!e.target.value) {
                                                                                Errors = { ...Errors, ["min"]: "Minimum Buy field is required" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else {
                                                                                Errors = { ...Errors, ["min"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }

                                                                            if (this.state.max) {
                                                                                if (e.target.value >= this.state.max) {
                                                                                    Errors = { ...Errors, ["min"]: "Minimum Buy must be < Maximum Buy" }
                                                                                    this.setState({ errors: Errors });
                                                                                }
                                                                                if (e.target.value < this.state.max) {
                                                                                    Errors = { ...Errors, ["max"]: "" }
                                                                                    this.setState({ errors: Errors });
                                                                                }

                                                                            }
                                                                            else {
                                                                                Errors = { ...Errors, ["min"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }

                                                                            this.handledisable();
                                                                        }} placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.min}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Maximum Buy ({this.state.currency})*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="maxcontribution" value={this.state.max} onChange={(e) => {
                                                                            const formvalue = { ...this.state.errors, ["max"]: "" }
                                                                            let Errors = this.state.errors
                                                                            this.setState({ errors: formvalue });
                                                                            this.setState({ max: e.target.value });
                                                                            { console.log("minmax", e.target.value, this.state.hardCap) }
                                                                            if (isNaN(e.target.value)) {
                                                                                Errors = { ...Errors, ["max"]: "Invalid Maximum Buy " }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else if (!e.target.value) {
                                                                                Errors = { ...Errors, ["max"]: "Maximum Buy field is required" }
                                                                                this.setState({ errors: Errors });
                                                                            }


                                                                            else if (e.target.value > this.state.hardCap) {
                                                                                { console.log("minmax") }
                                                                                Errors = { ...Errors, ["max"]: "Maximum Buy should not be greater than Hardcap" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            else {
                                                                                Errors = { ...Errors, ["max"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }
                                                                            if (this.state.min) {
                                                                                if (e.target.value < this.state.min) {
                                                                                    Errors = { ...Errors, ["max"]: "Maximum Buy must be > Minimum Buy" }
                                                                                    this.setState({ errors: Errors });
                                                                                }
                                                                                if (e.target.value > this.state.min) {
                                                                                    Errors = { ...Errors, ["min"]: "" }
                                                                                    this.setState({ errors: Errors });
                                                                                }
                                                                            }
                                                                            else {
                                                                                Errors = { ...Errors, ["max"]: "" }
                                                                                this.setState({ errors: Errors });
                                                                            }

                                                                            this.handledisable();
                                                                        }} placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.max}</span>
                                                                </div>
                                                            </div>


                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Listing Options</p>
                                                                <div className='d-flex'>
                                                                    <div className={`currency_badge  mr-2 ${this.state.isPancake === true ? "active" : ""} `} onClick={(e) => {
                                                                        this.setState({ isPancake: true });
                                                                        this.handledisable();
                                                                    }}><span>Auto</span></div>
                                                                    <div className={`currency_badge  mr-2 ${this.state.isPancake === false ? "active" : ""} `} onClick={(e) => {
                                                                        this.setState({ isPancake: false });
                                                                        this.handledisable();
                                                                    }}><span>Manual</span></div>
                                                                </div>
                                                            </div>


                                                            {this.state.isPancake ?
                                                                <>
                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Locking Days</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquidity" value={this.state.unlockOn} onChange={(e) => {
                                                                                    this.setState({ unlockOn: e.target.value });
                                                                                    var rx = new RegExp(/^\d+$/);
                                                                                    if (!rx.test(e.target.value)) {
                                                                                        const formvalue = { ...this.state.errors, ["lockingday"]: "Invalid Locking Days !" };
                                                                                        this.setState({ errors: formvalue });
                                                                                    }
                                                                                    else if (e.target.value < 30) {
                                                                                        const formvalue = { ...this.state.errors, ["lockingday"]: "Locking Days should be greater than 30 days" };
                                                                                        this.setState({ errors: formvalue });
                                                                                    }
                                                                                    else {
                                                                                        const formvalue = { ...this.state.errors, ["lockingday"]: "" };
                                                                                        this.setState({ errors: formvalue });
                                                                                    }
                                                                                    this.handledisable();
                                                                                }} placeholder="0" aria-describedby="basic-addon2" />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.lockingday}</span>
                                                                        </div>
                                                                    </div>



                                                                    <div className='col-12 col-md-12 mb-0'>
                                                                        <p className='input_desc_sm'>Listing Rate per {this.state.currency}</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="maxcontribution" value={this.state.pancakeRate} onChange={(e) => {
                                                                                    this.setState({ pancakeRate: e.target.value })
                                                                                    this.handledisable();
                                                                                }} placeholder="20000 (2 * Presale Rate)" aria-describedby="basic-addon2" />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.listingrate}</span>
                                                                        </div>
                                                                        <div className='note_desc mt-1 mb-1'>
                                                                            <p>1 {this.state.currency} = {this.state.presaleRate} {this.state.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Flashpad Liquidity (51% minimum)</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquidity" value={this.state.liquidityPercent} onChange={(e) => {
                                                                                    if (!isFloat(e.target.value)) {
                                                                                        this.setState({ liquidityPercent: e.target.value });
                                                                                        if (isNaN(e.target.value) || e.target.value < 51 || e.target.value > 100) {
                                                                                            const formvalue = { ...this.state.errors, ["liquidity"]: "Invalid Flashpad Liquidity !" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["liquidity"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                    }
                                                                                    this.handledisable();
                                                                                }} placeholder="0" aria-describedby="basic-addon2" />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.liquidity}</span>
                                                                        </div>
                                                                    </div>
                                                                </> : <></>}
                                                            {this.state.isPancake ?
                                                                <div className='col-12 col-md-12 mb-2'>
                                                                    <div className='note_desc mt-1 mb-0'>
                                                                        <p>Enter the percentage of raised funds that sholud be allocated to Liquidity on (Min 51%, Max 100%)</p>
                                                                        <p>If I spend 1 {CHAINS[getChainId()].SYMBOL } on how many tokens will I receive? Usually the amount is lower than presale rate to allow for a higher listing price on</p>

                                                                    </div>
                                                                </div> : <></>}

                                                            <div className='col-12 col-md-12 mb-0'>
                                                                <p className='input_desc_sm'>Select Start time & End time (UTC)*</p>
                                                            </div>
                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Start Time (UTC)*</p>
                                                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                                                    <InputGroup className="datepicker_input">
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            filterTime={this.filterPassedTime.bind(this)}
                                                                            showTimeSelect
                                                                            selected={this.state.startDate}
                                                                            onChange={(date) => {
                                                                                this.setState({ startDate: date })
                                                                                if (new Date(date).getTime() >= new Date().getTime()) {
                                                                                    let formData = { ...this.state.errors, ["startdate"]: "" };
                                                                                    this.setState({ errors: formData });
                                                                                }
                                                                                else {
                                                                                    let formData = { ...this.state.errors, ["startdate"]: "Start Time needs to be after now" };
                                                                                    this.setState({ errors: formData });
                                                                                }
                                                                                if (this.state.endDate) {
                                                                                    if (Date.parse(date) > this.state.endDate) {
                                                                                        let formData = { ...this.state.errors, ["startdate"]: "Start Time must be < End Time" };
                                                                                        this.setState({ errors: formData });
                                                                                    }
                                                                                    if (this.state.endDate > Date.parse(date)) {
                                                                                        let formData = { ...this.state.errors, ["enddate"]: "" };
                                                                                        this.setState({ errors: formData });
                                                                                    }
                                                                                }
                                                                                this.handledisable();
                                                                            }

                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                                        />

                                                                    </InputGroup>
                                                                    <InputGroup.Append className='cur_pointer'>
                                                                        <button variant="outline-secondary" className="trans_cal_btn">
                                                                            <i class="far fa-calendar-alt"></i>
                                                                        </button>
                                                                    </InputGroup.Append>

                                                                </div>
                                                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.startdate}</span>
                                                            </div>
                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>End Time (UTC)*</p>
                                                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                                                    <InputGroup className="datepicker_input">
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            filterTime={this.filterPassedTime.bind(this)}
                                                                            showTimeSelect
                                                                            selected={this.state.endDate}
                                                                            onChange={(date) => {
                                                                                this.setState({ endDate: date });

                                                                                if (Date.parse(date) > Date.now()) {
                                                                                    let formData = { ...this.state.errors, ["enddate"]: "" };
                                                                                    this.setState({ errors: formData });
                                                                                }
                                                                                else {
                                                                                    let formData = { ...this.state.errors, ["enddate"]: "End Time needs to be after now" };
                                                                                    this.setState({ errors: formData });
                                                                                }
                                                                                if (this.state.startDate) {
                                                                                    if (Date.parse(date) <= this.state.startDate) {
                                                                                        console.log("invalideee");
                                                                                        let formData = { ...this.state.errors, ["enddate"]: "End Time must be > Start Time" };
                                                                                        this.setState({ errors: formData });
                                                                                        console.log("invalideee", formData);
                                                                                        this.forceUpdate()
                                                                                    }
                                                                                    if (this.state.startDate < Date.parse(date)) {
                                                                                        let formData = { ...this.state.errors, ["startdate"]: "" };
                                                                                        this.setState({ errors: formData });
                                                                                        let formdata = { ...this.state.errors, ["enddate"]: "" };
                                                                                        this.setState({ errors: formdata });
                                                                                    }
                                                                                }
                                                                                this.handledisable();

                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                                        />

                                                                    </InputGroup>

                                                                    <InputGroup.Append className='cur_pointer'>
                                                                        <button variant="outline-secondary" className="trans_cal_btn">
                                                                            <i class="far fa-calendar-alt"></i>
                                                                        </button>
                                                                    </InputGroup.Append>

                                                                </div>
                                                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.enddate}</span>
                                                            </div>

                                                            <div className='col-12 col-md-12'>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" checked={this.state.isVested} onChange={(e) => { this.setState({ isVested: e.target.checked }); this.handledisable() }} className="custom-control-input" id="customCheck1" />
                                                                    <label className="custom-control-label" for="customCheck1">Using Vesting Contributor?</label>
                                                                </div></div>

                                                            {this.state.isVested ?
                                                                <>
                                                                    <div className='col-12 col-md-12 mb-3 mt-4'>
                                                                        <p className='input_desc_sm'>Vesting Period Days</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquiditylockingdays" value={this.state.vestInterval} onChange={(e) => {
                                                                                    this.setState({ vestInterval: e.target.value });
                                                                                    var rx = new RegExp(/^\d+$/);
                                                                                    if (!rx.test(e.target.value)) {
                                                                                        const formvalue = { ...this.state.errors, ["vestinterval"]: "Invalid Vesting Period Days !" };
                                                                                        this.setState({ errors: formvalue });
                                                                                    }
                                                                                    else {
                                                                                        const formvalue = { ...this.state.errors, ["vestinterval"]: "" };
                                                                                        this.setState({ errors: formvalue });
                                                                                    }
                                                                                    this.handledisable();
                                                                                }} placeholder=""
                                                                                    aria-describedby="basic-addon2"
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.vestinterval}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3 mt-4'>
                                                                        <p className='input_desc_sm'>Rewards % per Vesting Period</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquiditylockingdays" value={this.state.vestPercent} onChange={(e) => {
                                                                                    if (!isFloat(e.target.value)) {
                                                                                        this.setState({ vestPercent: e.target.value });
                                                                                        if (isNaN(e.target.value) || e.target.value < 0 || e.target.value > 100) {
                                                                                            const formvalue = { ...this.state.errors, ["vestpercent"]: "Invalid Rewards % per Vesting Period !" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...this.state.errors, ["vestpercent"]: "" };
                                                                                            this.setState({ errors: formvalue });
                                                                                        }
                                                                                        this.handledisable();
                                                                                    }
                                                                                }} placeholder=""
                                                                                    aria-describedby="basic-addon2"
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.vestpercent}</span>
                                                                        </div>
                                                                    </div>
                                                                </> : <></>}
                                                            <div className='col-12'>
                                                                <div className='note_desc mt-1 mb-1 text-center'>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-md-12 text-center'>
                                                                <button className="get-started-btn mr-2 orbitron_font text-white btn_min_wid" onClick={() => { this.setState({ currentStep: 1 }) }}>
                                                                    Back
                                                                </button>
                                                                <button className="get-started-btn-border orbitron_font btn_min_wid" onClick={() => {
                                                                    console.log("disable", this.handledisable());
                                                                    this.validations();
                                                                    if (this.handledisable()) {

                                                                    }
                                                                    else {
                                                                        this.setState({ currentStep: 3 })
                                                                    }

                                                                }}
                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="thirdstep" className={this.state.currentStep == 3 ? "d-block" : "d-none"}>
                                                        <div className='row'>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Description</p>
                                                                <div className="inputs input-groups text_are_grp">
                                                                    <InputGroup className="" >
                                                                        <textarea value={this.state.description} onChange={(e) => {
                                                                            this.setState({ description: e.target.value });
                                                                            if (!e.target.value) {
                                                                                const formvalue = { ...this.state.errors, ["description"]: "Invalid Description!" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            else {
                                                                                const formvalue = { ...this.state.errors, ["description"]: "" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            // this.handledisableurlbutton({
                                                                            //     description: e.target.value,
                                                                            //     logo: this.state.logo,
                                                                            //     twitter: this.state.twitter,
                                                                            //     telegram: this.state.telegram
                                                                            // });
                                                                        }} id="description" rows="3"
                                                                            aria-describedby="basic-addon2"

                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.description}</span>
                                                                </div>
                                                            </div>



                                                            <div className='col-12 col-md-12 mb-0'>

                                                                <p className='input_desc_sm'>Logo URL*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="file" value={this.state.logo} onChange={(e) => {
                                                                            this.setState({ logo: e.target.value });
                                                                            if (urlvalidation(e.target.value)) {
                                                                                const formvalue = { ...this.state.errors, ["logo"]: "" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            else {
                                                                                const formvalue = { ...this.state.errors, ["logo"]: "Invalid Logo Url!" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            // this.handledisableurlbutton({
                                                                            //     description: this.state.description,
                                                                            //     logo: e.target.value,
                                                                            //     twitter: this.state.twitter,
                                                                            //     telegram: this.state.telegram
                                                                            // });
                                                                        }} placeholder="https://flashpad.org/logo.png"
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.logo}</span>
                                                                </div>
                                                                <div className='note_desc mt-1 mb-0'>
                                                                    <p>URL must be end with a supported image extension .png, .jpg,</p>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-0'>

                                                                <p className='input_desc_sm'>Banner URL*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="file1"
                                                                            placeholder="https://flashpad.org/banner.png"
                                                                            aria-describedby="basic-addon2" value={this.state.banner} onChange={(e) => {
                                                                                this.setState({ banner: e.target.value });
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["banner"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["banner"]: "Invalid Banner Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.banner}</span>
                                                                </div>
                                                                <div className='note_desc mt-1 mb-0'>
                                                                    <p>URL must be end with a supported image extension .png, .jpg,</p>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Website*</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl value={this.state.website} onChange={(e) => {
                                                                            this.setState({ website: e.target.value });
                                                                            if (urlvalidation(e.target.value)) {
                                                                                const formvalue = { ...this.state.errors, ["website"]: "" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            else {
                                                                                const formvalue = { ...this.state.errors, ["website"]: "Invalid Website Url!" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            // this.handledisableurlbutton();
                                                                        }} id="logolink1" placeholder=""
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.website}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-0'>
                                                                <p className='input_desc_sm'>Youtube Video</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="logolinkyoutube" placeholder="https://youtube.com"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={(e) => {
                                                                                this.setState({ youtube: e.target.value });
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["youtube"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["youtube"]: "Invalid Youtube Video Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                // this.handledisableurlbutton();
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.youtube}</span>
                                                                </div>

                                                                <div className='note_desc mt-1 mb-0'>
                                                                    <p>Input your youtube URL</p>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Twitter</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl value={this.state.twitter} onChange={(e) => {
                                                                            this.setState({ twitter: e.target.value });
                                                                            if (urlvalidation(e.target.value)) {
                                                                                const formvalue = { ...this.state.errors, ["twitter"]: "" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            else {
                                                                                const formvalue = { ...this.state.errors, ["twitter"]: "Invalid Twitter Url!" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            // this.handledisableurlbutton({
                                                                            //     description: this.state.description,
                                                                            //     logo: this.state.logo,
                                                                            //     twitter: e.target.value,
                                                                            //     telegram: this.state.telegram
                                                                            // });
                                                                        }} id="logolink2" placeholder="https://twitter.com"
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.twitter}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Telegram</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl value={this.state.telegram} onChange={(e) => {
                                                                            this.setState({ telegram: e.target.value });
                                                                            if (urlvalidation(e.target.value)) {
                                                                                const formvalue = { ...this.state.errors, ["telegram"]: "" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            else {
                                                                                const formvalue = { ...this.state.errors, ["telegram"]: "Invalid Telegram Url!" };
                                                                                this.setState({ errors: formvalue });
                                                                            }
                                                                            // this.handledisableurlbutton({
                                                                            //     description: this.state.description,
                                                                            //     logo: this.state.logo,
                                                                            //     twitter: this.state.twitter,
                                                                            //     telegram: e.target.value
                                                                            // });
                                                                        }} id="logolink3" placeholder="https://telegram.com"
                                                                            aria-describedby="basic-addon2"
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.telegram}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Discord</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="logolinkdiscord" placeholder="https://discord.com"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={(e) => {
                                                                                this.setState({ discord: e.target.value });
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["discord"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["discord"]: "Invalid Discord Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                // this.handledisableurlbutton();
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.discord}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Github</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="logolinkgit" placeholder="https://github.com"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={(e) => {
                                                                                this.setState({ "githup": e.target.value })
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["githup"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["githup"]: "Invalid Github Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                // this.handledisableurlbutton();
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.githup}</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Instagram</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="logolinkinsta" placeholder="https://instagram.com"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={(e) => {
                                                                                this.setState({ instagram: e.target.value });
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["instagram"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["instagram"]: "Invalid Instagram Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                // this.handledisableurlbutton();
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.instagram}</span>
                                                                </div>
                                                            </div>

                                                            {/* <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Facebook</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="logolinkfb" placeholder="https://facebook.com"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={(e) => {
                                                                                this.setState({ "facebook": e.target.value });
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["facebook"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["facebook"]: "Invalid Facebook Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                // this.handledisableurlbutton();
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.facebook}</span>
                                                                </div>
                                                            </div> */}

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Reddit</p>
                                                                <div className="inputs input-groups">
                                                                    <InputGroup className="">
                                                                        <FormControl id="logolinkreddit" placeholder="https://reddit.com"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={(e) => {
                                                                                this.setState({ reddit: e.target.value });
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...this.state.errors, ["reddit"]: "" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...this.state.errors, ["reddit"]: "Invalid Reddit Url!" };
                                                                                    this.setState({ errors: formvalue });
                                                                                }
                                                                                // this.handledisableurlbutton();
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.reddit}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-md-12 text-center'>
                                                                <button className="get-started-btn mr-2 orbitron_font text-white btn_min_wid" onClick={() => { this.setState({ currentStep: 2 }) }}>
                                                                    Back
                                                                </button>
                                                                <button className="get-started-btn-border orbitron_font btn_min_wid" onClick={() => {
                                                                    let isvalid = this.socialMediaValidations()
                                                                    console.log("socialMediaValidations", isvalid)
                                                                    if (isEmpty(isvalid)) {
                                                                        this.setState({ currentStep: 4 });
                                                                        this.calculateDepositTokens();
                                                                    }

                                                                }}
                                                                // disabled={this.state.button3}
                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="fourthstep" className={this.state.currentStep == 4 ? "d-block" : "d-none"}>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Token Name :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.tokeninfo ? this.state.tokeninfo.name : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Token Symbol :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.tokeninfo ? this.state.tokeninfo.symbol : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Token Decimals :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.tokeninfo ? this.state.tokeninfo.decimals : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Presale Rate :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.presaleRate ? this.state.presaleRate : ""} {this.state.tokeninfo ? this.state.tokeninfo.symbol : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Listing Rate :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.pancakeRate ? this.state.pancakeRate : this.state.presaleRate} {this.state.tokeninfo ? this.state.tokeninfo.name : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Sale Method :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.isWhitelisted === false ? "Public" : "Private"}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Softcap :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.softCap ? this.state.softCap : " "} {this.state.currency ? this.state.currency : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Hardcap :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.hardCap ? this.state.hardCap : " "} {this.state.currency ? this.state.currency : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Minimum Buy :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.min} {this.state.currency}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Maximum Buy :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.max} {this.state.currency}
                                                            </span>
                                                        </p>
                                                        {this.state.isPancake ?
                                                            <>
                                                                <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                    <span className='desc_grey_txt'>Liquidity Percentage:</span>
                                                                    <span className='desc_grey_txt font_12'>{this.state.liquidityPercent}%
                                                                    </span>
                                                                </p>

                                                                <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                    <span className='desc_grey_txt'>Liquidity Lockup Time :</span>
                                                                    <span className='desc_grey_txt font_12'>{this.state.unlockOn}
                                                                    </span>
                                                                </p>
                                                            </> : <></>}

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Start Time :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() + "  "
                                                                + new Date(this.state.startDate).getHours() + ":" + new Date(this.state.startDate).getMinutes() + ":"
                                                                + new Date(this.state.startDate).getSeconds() + "(GMT)" : ""}
                                                            </span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>End Time :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() + "  "
                                                                + new Date(this.state.endDate).getHours() + ":" + new Date(this.state.endDate).getMinutes() + ":"
                                                                + new Date(this.state.endDate).getSeconds() + "(GMT)" : ""}
                                                            </span>
                                                        </p>



                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Website :</span>
                                                            <span className='desc_grey_txt font_12'><a href={this.state.website} target="_blank" className='link_grn_new link_brk_word'>{this.state.website ? this.state.website : ""}</a>
                                                            </span>
                                                        </p>

                                                        <div className='mb-4 mt-4'>
                                                            <div className='card_footer_form'>
                                                                <div className='d-flex align-items-center justify-content-center'>
                                                                    <i class="fa fa-exclamation-circle text-danger-war" aria-hidden="true"></i>
                                                                    <p className='mb-0 pl-3'>For tokens with burns, rebase or other special transfers please ensure that you have a way to whitelist multiple addresses or turn off the special transfer events (By setting fees to 0 for example for the duration of the presale)</p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {this.state.deposit > 0 ?
                                                            <div className='col-12'>
                                                                <div className='note_desc mt-1 mb-1 text-center'>
                                                                    <p>Need {parseFloat(this.state.deposit)?.toFixed(2)} {this.state.name} to create launchpad.</p>
                                                                </div>
                                                            </div> : <></>}


                                                        <div className='col-12 col-md-12 text-center'>
                                                            <button className="get-started-btn mr-2 orbitron_font text-white btn_min_wid" onClick={() => { this.setState({ currentStep: 3 }) }}>
                                                                Back
                                                            </button>
                                                            {!this.state.isDeposited ?
                                                                (this.state.createdSale == '' || this.state.createdSale == ZEROTH_ADDRESS?
                                                                    <a onClick={this.CreateSale} className="get-started-btn-border orbitron_font text-white btn_min_wid">
                                                                        Create
                                                                    </a> :
                                                                    this.renderDeposit()) : <a onClick={this.goToSale} className="get-started-btn-border orbitron_font text-white btn_min_wid">Proceed</a>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 col-lg-4'>
                        <div className='card_bg_steps h-100'>
                            <div>
                                <div className='card_step'>
                                    <div className={this.state.currentStep == 1 ? "card_bg_wizard active" : "card_bg_wizard"} >
                                        <div className='card-body'>
                                            <div className='d-flex align-items-start h-100'>
                                                <div className='wizard_badge_num'>

                                                </div>{console.log("strt time", this.state.startDate)}
                                                <div className='pl-3 create_wiz_desc'>
                                                    <h5>Before you start</h5>
                                                    <p className='mb-0'>Input your awesome title and choose the currency</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card_step'>
                                    <div className={this.state.currentStep == 2 ? "card_bg_wizard active" : "card_bg_wizard"} >
                                        <div className='card-body'>
                                            <div className='d-flex align-items-start h-100'>
                                                <div className='wizard_badge_num'>

                                                </div>
                                                <div className='pl-3 create_wiz_desc'>
                                                    <h5>Defi Launchpad Info</h5>
                                                    <p className='mb-0'>Enter the launchpad information that you want to raise, that should be enter all details about your presale</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card_step'>
                                    <div className={this.state.currentStep == 3 ? "card_bg_wizard active" : "card_bg_wizard"} >
                                        <div className='card-body'>
                                            <div className='d-flex align-items-start h-100'>
                                                <div className='wizard_badge_num'>

                                                </div>
                                                <div className='pl-3 create_wiz_desc'>
                                                    <h5>Add Additional Info</h5>
                                                    <p className='mb-0'>Let people know who you are</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card_step'>
                                    <div className={this.state.currentStep == 4 ? "card_bg_wizard active" : "card_bg_wizard"} >
                                        <div className='card-body'>
                                            <div className='d-flex align-items-start h-100'>
                                                <div className='wizard_badge_num'>

                                                </div>
                                                <div className='pl-3 create_wiz_desc'>
                                                    <h5>Finish</h5>
                                                    <p className='mb-0'>Review your information</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-3 bottom_text px-4 pb-4">
                                Disclaimer: The information provided shall not in any way
                                constitute a recomendation as to whether you should invest
                                in any product discussed. We accept no liability for any
                                loss occasioned to any person acting or refraining from
                                action as a result of any material provided or published.
                            </p>
                        </div>
                    </div>
                </div>
                {tokenModal && <TokenModal connect={"string"} address={(val) => this.settokenaddress(val)} onDismiss={() => this.onDismiss()} />}
            </div>
        )
    }

}


export default CreatePrivatesaletab