import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import Editcreatemodal from "./Editcreatemodal";
import Addcreatemodal from "./Addcreatemodal";

import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

import { Tab, Row, Col } from 'react-bootstrap';
import { getalldummylaunchhook, getsettinghook, getallsalehook, getuserdatahook } from '../../hooks/usebackend';
import { Link } from 'react-router-dom';
import { getlaunchpaddatalimit, getSaleCardsLimit, getTotalSalesInfo, searchCardsadmin, getlaunchpadPremiumdata } from '../../hooks/useProjects';
import { DeletePresale, getProxyOwner } from '../../hooks/useContract';
import { getAccount } from '../../hooks/useAccount';
import { admintokenfilterlaunchpad } from '../../hooks/filter';
import isEmpty from 'is-empty';
import PremiumLaunch from "../premiumLaunch"


const cookies = new Cookies();
class Admincreatelaunchpad extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.hideLoader();
    this.getcreatedlaunchpad();
    this.getOwner()

  }

  getcreatedlaunchpad = async () => {
    let payload = {
      skip: this.state.skip,
      limit: this.state.limit
    }
    let result = await getalldummylaunchhook(payload);
    console.log("result", result);
    if (result.data.success == true || result.data.success == "true") {
      this.setState({ createlaunchpad: result.data.data })
    }
    let count = Math.ceil(result?.data?.count / this.state.limit)
    this.setState({ "pagecount": count });
  }

  constructor(props) {
    super(props);
    this.state = {
      editcreateModal: false,
      addcreateModal: false,
      createlaunchpad: [],
      singlelaunchpad: {},
      skip: 0,
      limit: 10,
      pagecount: 0,
      button: 0,
      currentpage: 1,
      stepcount: 5,
      original: [],
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: '',
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      loadinterval: 3,
      mycontribution: [],
      accountInfo: getAccount(),
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      wishlist: [],
      trending: [],
      singletrending: {},
      auditkyc: [],
      singleaudit: {},
      proxyOwner: "",
      totalCount: 0,
      premiumSale: []
    };
  }

  onDismiss() {
    this.setState({ editcreateModal: false });
  }

  onDismissAdd() {
    this.setState({ addcreateModal: false });
  }

  handlenextpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip + this.state.limit
    }
    let result = await getalldummylaunchhook(payload);
    this.setState({ "createlaunchpad": result?.data?.data })
    if (this.state.currentpage % 5 == 0) {
      this.setState({ "button": this.state.button + 5 })
    }
    this.setState({ "skip": this.state.skip + this.state.limit })
    this.setState({ "currentpage": this.state.currentpage + 1 })
  }

  handleprevpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip - this.state.limit
    }
    let result = await getalldummylaunchhook(payload);
    this.setState({ "createlaunchpad": result?.data?.data })
    if ((this.state.currentpage - 1) % 5 == 0) {
      this.setState({ "button": this.state.button - 5 })
    }
    this.setState({ "skip": this.state.skip - this.state.limit });
    this.setState({ "currentpage": this.state.currentpage - 1 });
  }

  handlenumpage = async (val) => {
    this.setState({ "currentpage": parseInt(val) })
    let payload = {
      limit: this.state.limit,
      skip: (parseInt(val) * 10) - 10
    }

    let result = await getalldummylaunchhook(payload);
    this.setState({ "createlaunchpad": result?.data?.data })
    this.setState({ "skip": (parseInt(val) * 10) - 10 })

  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.restrict()
    this.hideLoader();
    this.gettotalsalesinfo();
    this.getsetting()
    this.getuserdata();
    this.getOwner()
  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (!email) {
      this.props.history.push("/login")
    }
  }

  gettotalsalesinfo = async () => {
    this.showLoader();
    const totalproxydata = await getTotalSalesInfo();
    console.log("Proxydata", totalproxydata);
    console.log("total proxy data", totalproxydata);
    this.setState({ proxy: totalproxydata })
    this.setState({ original: totalproxydata })
    const Totalsale = await getlaunchpaddatalimit(totalproxydata, 0, totalproxydata.length);
    console.log(Totalsale, 'gettotalsalesinfo')
    let premiumSale = []
    const totallength = Totalsale.filter((val, index) => {
      if (val.owner.toLowerCase() == this.state.proxyOwner.toLowerCase()) {
        premiumSale.push(index)
        return val.owner.toLowerCase() == this.state.proxyOwner.toLowerCase()
      }

    })?.length
    const total = await getlaunchpadPremiumdata(totalproxydata, 0, this.state.loadInterval, premiumSale);
    // const totalSale = total.filter((val) => { return val.owner.toLowerCase() == this.state.proxyOwner.toLowerCase() })
    console.log(total, 'gettotalsalesinfo', premiumSale, totallength, total, this.state.proxyOwner)
    console.log("limittotal", total);
    this.setState({ totalSales: total, totalCount: totallength, premiumSale: premiumSale });
    this.setState({ interval: this.state.loadinterval });
    this.hideLoader();
  }

  viewmore = async () => {
    console.log("Lad moreee", this.state.totalSales?.length < this.state.proxy?.length)
    this.setState({ isLoading: true });
    console.log("getlaunchpad datalimit", this.state.interval, this.state.loadInterval);
    const newly = await getlaunchpadPremiumdata(this.state.proxy, this.state.interval, this.state.interval + this.state.loadInterval, this.state.premiumSale);
    console.log("newly ", newly);
    const totalSale = newly.filter((val) => (val.owner.toLowerCase() == this.state.proxyOwner.toLowerCase()))
    const total = this.state.totalSales.concat(totalSale);
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }




  async loadSearchMore() {
    console.log("Lad more", this.state.totalSales?.length < this.state.proxy?.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpadPremiumdata(this.state.searchProxy, this.state.searchInterval, this.state.searchInterval + this.state.loadInterval, this.state.premiumSale);
    const totalSale = newly.filter((val) => (val.owner.toLowerCase() == this.state.proxyOwner.toLowerCase()))
    const total = this.state.searchSale.concat(totalSale);
    await this.sleep(1000)
    this.setState({ searchSale: total, searchInterval: this.state.searchInterval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }


  async getsetting() {
    let data = await getsettinghook();
    console.log("data setting", data, data?.data?.data);
    console.log("Array", typeof data?.data?.data == 'Array', typeof data?.data?.data);
    this.setState({ setting: data?.data?.data })
    typeof data?.data?.data == 'object' && data?.data?.data?.length > 0 && data?.data?.data?.map((val, i) => {
      console.log("val", val)
      if (val.settingname == "Docs" || val.settingname == "docs") {
        this.setState({ Docs: val.settingvalue });
      }
      if (val.settingname == "Twitter" || val.settingname == "twitter") {
        this.setState({ twitter: val.settingvalue });

      }
      if (val.settingname == "Telegram" || val.settingname == "telegram") {
        this.setState({ telegram: val.settingvalue });
      }
      if (val.settingname == "Support" || val.settingname == "support") {
        this.setState({ support: val.settingvalue });
      }
      if (val.settingname == "Dex" || val.settingname == "dex") {
        this.setState({ dex: val.settingvalue });
      }
      if (val.settingname == "Medium" || val.settingname == "medium") {
        this.setState({ medium: val.settingvalue });
      }

    })
  }

  getuserdata = async () => {
    try {
      console.log("userdata", getAccount());
      let userdata = await getuserdatahook(this.state.accountInfo.toLowerCase());
      console.log("userdata", userdata?.data?.data);
      this.setState({ wishlist: userdata?.data?.data?.wishlist })

      let auditkycinfo = await getallsalehook();
      console.log("auditkuycinfo", auditkycinfo?.data?.data);
      if (auditkycinfo?.data?.data?.length > 0) {
        this.setState({ auditkyc: auditkycinfo?.data?.data })
      }
      console.log("total_total", this.state.totalSales);

      let saleaddress
      let total = this.state.totalSales?.map((data) => {
        console.log("total_total", total, this.state.totalSales);
        saleaddress = data.saleAddress
      })
      let singleaudit = auditkycinfo?.data?.data?.find(e => e.saleaddress == saleaddress)
      if (singleaudit)
        this.setState({ singleaudit: singleaudit })
    }
    catch (err) {
      console.log("getuserdata", err)
    }
  }


  getOwner = async () => {
    const owner = await getProxyOwner();
    console.log("owner", owner)
    this.setState({ proxyOwner: owner });
  }




  render() {


    const location = this.props.location.pathname.split('/')[1];

    const { editcreateModal, addcreateModal } = this.state

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">

          <div className="whole_bg">
            <Adminheader />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec pb-5'>

                  <div className="right_side_spacing">
                    <div className='row my-5 row_ppos'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='bg_layer'>
                            <div className='card-body'>
                              {/* <div className='d-flex justify-content-end mb-3'><button className="get-started-btn font_20 btn_icon_grad">Detail</button></div> */}
                              <div className="row">
                                <div className="col-6 col-md-6 flex_cen_col">
                                  <p className='overlay_card_head mb-0'>Created Launchpad List</p>

                                </div>
                                {/* <div className="col-6 col-md-6 flex_cen_col">
                                  <div className='text-right'>
                                    <button className="get-started-btn font_20 btn_icon_grad" onClick={() => this.setState({ addcreateModal: true })}>+</button>
                                  </div>
                                </div> */}

                              </div>

                              <div className="tab_par px-0 py-3">

                                <Tab.Container defaultActiveKey="second">
                                  <Row className="container-fluid w-100 mx-0 px-0">

                                    <Col lg={12} className="img_center_lg px-0">
                                      <div className='container container_custom px-0'>
                                        <Tab.Content className='min_heighttab_cont'>
                                          <Tab.Pane eventKey="second">

                                            <div class="table-responsive">

                                              {/* {this.state.proxy?.length == 0 && <p className='no_data_text'>No Data Found</p>} */}
                                              {this.state.proxy?.length > 0 && this.state.totalSales.length > 0 ?
                                                <table className="table recepients_table">
                                                  <thead>
                                                    <tr>
                                                      <th>Sale Address</th>
                                                      <th>Symbol</th>
                                                      <th>Type</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>

                                                  {/*                                                
                                                      <tr>
                                                        <td>sadsadsa</td>
                                                        <td>sadsadsa</td>
                                                        <td><a className="link_text">Edit</a> <Link className='link_text ml-3' to={`/adminlaunchpaddetail`}>Detail</Link></td>
                                                      </tr> */}


                                                  {this.state.totalSales && this?.state?.totalSales?.map((data, index) => {
                                                    // {this?.state?.createlaunchpad ? this?.state?.createlaunchpad?.map((data, i) => <>

                                                    return (
                                                      <>
                                                        {console.log("totalSales", data)}

                                                        <tr>
                                                          <td>
                                                            <div className="p-0 table_det">
                                                              <span className="table_text">{data?.saleAddress}</span>

                                                            </div>
                                                          </td>
                                                          <td>{data?.symbol}</td>
                                                          <td>{data?.isWhitelisted ? "Private" : data?.LaunchpadType ? "Launchpad" : "Fairlaunch"}</td>
                                                          <td className="d-flex justify-content-end">
                                                            {/* <a className="link_text" onClick={() => { this.setState({ singlelaunchpad: data }); this.setState({ editcreateModal: true }) }}>Edit</a> */}

                                                            <Link className='link_text ml-3' to={data?.isWhitelisted ? `/adminprivatesaledetail/${data && data?.saleAddress}` : `/adminlaunchpaddetail/${data && data?.saleAddress}`} onClick={async () => {
                                                            }}>Detail</Link></td>

                                                        </tr>
                                                      </>
                                                    )
                                                  })}
                                                </table> : <p className='no_data_text'>No Data Found</p>
                                              }
                                            </div>
                                            <div className='text-center mt-5'>


                                              {this.state.totalSales && this.state.totalSales?.length < this.state.totalCount ?
                                                <button className="get-started-btn orbitron_font " onClick={this.viewmore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                : <></>
                                              }
                                            </div>



                                            {/* <div className={this.state.totalSales.length ? "mt-2 d-flex justify-content-center align-items-center btn_pagin_wrap" : "d-none"}>
                                              <button className="pagination_btn"
                                                onClick={this.handleprevpage}
                                                disabled={this.state.currentpage == 1}
                                              ><i class="fa fa-angle-left" aria-hidden="true"

                                              ></i></button>


                                              {[...Array(this.state.stepcount)].map((val, i) => {
                                                if ((this.state.button + i + 1) <= this.state.pagecount)
                                                  return (
                                                    <button className={this.state.currentpage == (this.state.button + i + 1) ? "pagination_btn active" : "pagination_btn"} value={this.state.button + i + 1} onClick={
                                                      (e) => {
                                                        console.log("e", e?.target?.value);
                                                        this.handlenumpage(e?.target?.value)
                                                      }
                                                    }>{this.state.button + i + 1}</button>
                                                  )
                                              }

                                              )}

                                              <button className="pagination_btn"
                                                onClick={this.handlenextpage}
                                                disabled={this.state.currentpage == this.state.pagecount}
                                              ><i class="fa fa-angle-right" aria-hidden="true"

                                              ></i></button>
                                            </div> */}



                                          </Tab.Pane>


                                        </Tab.Content>
                                      </div>
                                    </Col>
                                  </Row>
                                </Tab.Container>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PremiumLaunch />
                </div>
              </div>
            </div>
          </div>
          {console.log("this.state.editcreateModal", this.state.editcreateModal)}
          {editcreateModal && <Editcreatemodal
            singledata={this.state.singlelaunchpad}
            get={() => this.getcreatedlaunchpad()}
            onDismiss={() => this.onDismiss()} />}
          {addcreateModal && <Addcreatemodal onDismiss={() => this.onDismissAdd()} get={() => this.getcreatedlaunchpad()} />}
          <Footer />
        </div>
      </div>
    )
  }
}

export default Admincreatelaunchpad