import React, { Component } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from "../Footer";
import Trendingslider from '../trendingslider';
import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import { Getsinglelockerdetail, Getsinglelockerinfo } from '../../hooks/useContract';
import PremiumLaunch from "../premiumLaunch"

class LockDetail extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    document.getElementById("lock_parent").classList.add("active");
    console.log("location datga", this.props.location.state)
    this.hideLoader();
    this.getlockerdetail();
  }

  getlockerdetail = async () => {
    let addressinfo = await Getsinglelockerinfo(this.state.lpaddress);
    this.setState({ "addressdetail": addressinfo })
    let lockdetail = await Getsinglelockerdetail(this.state.lpaddress);
    this.setState({ lockdetail: lockdetail });
    console.log("getlockerdetail", addressinfo, lockdetail);
  }

  constructor(props) {
    super(props);
    this.state = {
      accountInfo: '',
      lpaddress: window.location.pathname.split('/')[2],
      addressdetail: {},
      lockdetail: []
    };
  }

  render() {

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec pb-5'>
                  <Trendingslider />
                  <div className="right_side_spacing">
                    <div className='row my-5'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='card_bg_steps'>
                            <div className='card-body'>
                              <div>
                                <div className='row'>
                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='overlay_card_head text-blak'>Lock Info</p>
                                  </div>
                                  <div className='col-12 col-md-12 mb-3'>

                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                      <span className='desc_grey_txt'>Current Locked Amount</span>
                                      <span className='desc_grey_txt font_12'>{this.state.addressdetail.amount == undefined ? 0 : this.state.addressdetail.amount / 10 ** this.state.addressdetail.decimal}</span>
                                    </p>
                                  </div>
                                  <hr className='hr_border_blk' />
                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                      <span className='desc_grey_txt'>Token Address</span>
                                      <span className='desc_grey_txt font_12 addre_brk_Val'>{this.state.lpaddress}</span>
                                    </p>
                                  </div>

                                  <hr className='hr_border_blk' />

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                      <span className='desc_grey_txt'>Token Name</span>
                                      <span className='desc_grey_txt font_12'>{this.state.addressdetail.name}</span>
                                    </p>
                                  </div>

                                  <hr className='hr_border_blk' />

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                      <span className='desc_grey_txt'>Token Symbol</span>
                                      <span className='desc_grey_txt font_12'>{this.state.addressdetail.symbol}</span>
                                    </p>
                                  </div>

                                  <hr className='hr_border_blk' />

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                      <span className='desc_grey_txt'>Token Decimals</span>
                                      <span className='desc_grey_txt font_12'>{this.state.addressdetail.decimal}</span>
                                    </p>
                                  </div>
                                  <hr className='hr_border_blk' />
                                </div>
                              </div>
                              <p className='overlay_card_head text-blak mt-3'>Lock records</p>
                              <div class="table-responsive">

                                <table className="table lockdetail_table recepients_table">
                                  <thead>
                                    <tr>
                                      <th>Created User</th>
                                      <th>Owner</th>
                                      <th>Amount</th>
                                      <th>Unlock Time(UTC)</th>
                                    </tr>
                                  </thead>
                                  {this.state.lockdetail?.map((data, i) => <>
                                 { console.log("data",data)}
                                    <tr>
                                      <td className="table_text">{data?.Createduser}</td>
                                      <td className="table_text">{data?.to}</td>
                                      <td className="sub_tabletext">{data?.amount / 10 ** this.state.addressdetail.decimal}</td>
                                      <td className="sub_tabletext">{new Date(data?.unlockOn * 1000).toLocaleDateString()}</td>
                                    </tr>
                                  </>)}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PremiumLaunch/>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

export default LockDetail