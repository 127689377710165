import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Walletmodal from "./Walletmodal";
import Settingsmodal from "./Settingsmodal";
import { getAccount, getChainId } from '../hooks/useAccount'
import { getProxyOwner } from '../hooks/useContract';
import { UseProvider } from '../hooks/useWeb3'
import { CHAINS } from '../config/env'
import { NavLink } from "react-router-dom";
import linea from "../images/linea.png";

class Header extends Component {

    showMobile() {
        document.getElementById("mobile_nav_item").classList.toggle("left_stye");
        document.getElementById("mobileLayer").classList.toggle('active');
        document.getElementById("burger").classList.toggle('clicked');
        document.getElementById("burger").classList.toggle("burger_stye")
    }

    constructor(props) {
        super(props);
        this.state = {
            location: false,
            walletModal: false,
            accountInfo: "",
            walletConnect: "",
            proxyOwner: "",
            currentChain: 0,
            settingsModal: false,
        }
    }

    componentDidMount() {
        console.log("chains", localStorage.getItem("CHAIN"), CHAINS.length);
        if (!localStorage.getItem("CHAIN") || localStorage.getItem("CHAIN") > CHAINS.length - 1) {
            localStorage.setItem("CHAIN", 0)
        }
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 1) {
                document.getElementById("header").classList.add('header-scrolled');

            }
            else {
                document.getElementById("header").classList.remove('header-scrolled');

            }
        });
        this.setState({ accountInfo: getAccount() });
        this.setState({ currentChain: getChainId() });
        this.getOwner();
    }

    onDismiss() {
        this.setState({ walletModal: false });
    }

    getOwner = async () => {
        const owner = await getProxyOwner();
        this.setState({ proxyOwner: owner });
        console.log("owner : ", owner)
    }

    logOut = async () => {
        this.setState({ accountInfo: "" })
        localStorage.removeItem("accountInfo")
        if (localStorage.getItem("walletconnect") != null) {
            const provider = await UseProvider();
            await provider.disconnect()
        }
        window.location.reload()
        console.log("logout")
    }


    render() {
        const { location } = this.props;
        const { walletModal, accountInfo, settingsModal } = this.state

        return (


            <div>
                <div id="mobileLayer">
                    <div id="header" className="fixed-top-navbar d-none d-md-block header_bg">
                        <div className="container-fluid" id="mobileshow">
                            <header className={`${location} py-2`}>
                                <div className='d-flex headerleft'>
                                    <NavLink to="/" className='mr-lg-5'><span className='logo_img_sm' /></NavLink>

                                </div>


                                <div className='web_menu w-100 ml-5 ml-lg-2'>
                                    <div className="d-flex align-items-center justify-content-end py-0 header_flex">

                                        {/* <div className="d-flex align-items-center justify-content-center">
                                            <div className="contract position-relative">
                                                <span className="contractbg position-absolute">
                                                </span>
                                                <div className="name position-relative d-flex align-items-center justify-content-center">Search Token</div>
                                            </div>
                                            <div className="borderGradient d-flex align-items-center">
                                                <input className='form-control' />


                                            </div>
                                        </div> */}
                                        <div className='d-flex align-items-center  nav_parnt ms-3'>

                                            <div className='nav_parnt_2'>
                                                <div className="nav-menu">
                                                    <ul className="pl-0 mb-0 d-flex align-items-center">

                                                        <a className="mr-3 link_wallet_new d-none d-lg-block">
                                                            <img src={linea} className='img-fluid img_linea' />
                                                        </a>

                                                        {(localStorage.getItem("accountInfo") == null) ?

                                                            <button
                                                                className="notchedButton position-relative" onClick={() => this.setState({ walletModal: true })}
                                                            >
                                                                <span className="notchedBg"></span>
                                                                <div className="content">Connect Wallet</div>
                                                            </button>

                                                            :


                                                            <NavLink
                                                                className="notchedButton position-relative" to="/wallet"
                                                            >
                                                                <span className="notchedBg"></span>
                                                                <div className="content">{`${(localStorage.getItem("accountInfo")).substring(0, 5)}...${(localStorage.getItem("accountInfo")).substring(38, 42)}`}
                                                                </div>
                                                            </NavLink>
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>

                    <div id="header1" className="fixed-top-navbar d-block d-md-none">
                        <div className="container-fluid  h-100" id="mobileshow1">
                            <header className="h-100">
                                <Link to="/" className='mr-3 logo_mob_mar'><span className='logo_img_sm' /></Link>

                                <div className='d-flex align-items-center'>

                                    <div className=''>
                                        <div className="nav-menu">
                                            <ul className="pl-0 mb-0 mobile_btn_div_flex">


                                                {(accountInfo == "" || localStorage.getItem("accountInfo") == null) ?

                                                    <button
                                                        className="notchedButton position-relative" onClick={() => this.setState({ walletModal: true })}
                                                    >
                                                        <span className="notchedBg"></span>
                                                        <div className="content">Connect Wallet</div>
                                                    </button>
                                                    :
                                                    <NavLink
                                                        className="notchedButton position-relative" to="/wallet"
                                                    >
                                                        <span className="notchedBg"></span>
                                                        <div className="content">{`${(localStorage.getItem("accountInfo")).substring(0, 5)}...${(localStorage.getItem("accountInfo")).substring(38, 42)}`}
                                                        </div>
                                                    </NavLink>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}
                            </header>
                        </div>
                    </div>

                </div>
                {settingsModal && <Settingsmodal onDismiss={() => this.setState({ settingsModal: false })} connect={"string"} />}


            </div>
        );
    }
}



export default Header